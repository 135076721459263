import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import cs from "../media/cs.png";
import empty from "../media/undraw_a_day_at_the_park_re_9kxj.svg";
import emptytable from "../media/undraw_biking_kc-4-f.svg";
import emptycall from "../media/undraw_explore_re_8l4v.svg";
import sssvg from "../media/sssvg.svg";

import addsingle from "../media/undraw_personal_file_re_5joy.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { truncate } from "../helper";
import DropDown from "../components/dropdown";
import CardData from "../components/CardData";
import Sidenav from "../components/Sidenav";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ReactAudioPlayer from 'react-audio-player';
import Popover from 'react-bootstrap/Popover';
import {
  getTasks,
  getScriptAll,
  getUserData,
  getAdminCard,
  getUserCard,
  getToCallAdmin,
  getToCallUser,
  getContactNumber,
  updateTasks,
  deleteContactToCall,
  addNewContact,
  generateNewFile,
  uploadConatctFile,
  addNewTask,
  todayCallAdmin,
  adminCallDues,
  getAllTaskAdmin,
  todayCallAdminsSriptCategory,
  getAllContact,
  getfollowContact,
  uploadCallRecoding,
  getAllTaskAdminFilter,
  pendingTodaysAdmintask,
  adminTaskDues,
  deteleCallRecord,
  getSourceListData,
  dateAssignedCall
} from "../redux/slices/dataSlice";
import Alert from "./alert";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ManageSouce from "./manageSource";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { tocallvalue, progressElement, alterElement, alterCallBack, changeElement, changeFace, generateavatar} from '../helper';

const admin = localStorage.getItem("admin");
const organisation_name = localStorage.getItem("organisation_name");
const user_name = localStorage.getItem("user_name");
const org = localStorage.getItem("org");
const user_id = localStorage.getItem("token");
const Auth = localStorage.getItem("Auth");
const access_token = localStorage.getItem("Auth");

const sourcevalue = (item) => {
  if ( item.Contact_Source != "" && item.Contact_Source != null) {
    var sourcevar = "&callsource=".concat(item.Contact_Source);
    return sourcevar;
  }else{
    var sourcevar = "&callsource=".concat(" ");
    return sourcevar;
  }
};


////////////////////////////Get todays date////////////

const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
const day = String(today.getDate()).padStart(2, '0');

const today_date = `${year}-${month}-${day}`;



const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props}
  </Tooltip>
);

const renderPopover = (props) => (
  <Popover id="popover-basic">
  <Popover.Body>
  {props}
  </Popover.Body>
  </Popover>
);




function sunday1() {
  document.getElementById("sunday1").style.display = "block";
}

function rec() {
  document.getElementById("rec").style.display = "block";
}
function rec_back() {
  document.getElementById("rec").style.display = "none";
}

function sunday1_back() {
  document.getElementById("sunday1").style.display = "none";
}

function convertToMinutes(seconds) {
  var minutes = Math.floor(seconds / 60);
  var remainingSeconds = seconds % 60;

  var roundedMinutes = Math.round(minutes);
  var roundedSeconds = Math.round(remainingSeconds);

  return roundedMinutes + "min " + roundedSeconds + "s";
}


function Data() {
  const dispatch = useDispatch();
  const feed = useSelector((state) => state.Home.feed);
  const [feedOrgCategory, setOrgCategory] = useState('');
  const [feedOrgTask, setOrgTask] = useState();
  const [deleteItem, setdeleteItem] = useState(null);

  const [searchelement, setsearchelement] = useState({
    tocall: ""
  });
  console.log(searchelement);

  const [today, setToday] = useState({
    "data": [
    ],
    "current_page": 0,
    "total_pages": 0,
    "next_page": null,
    "previous_page": null,
    "total_reacords": 0
});


  const [pendingcall, setPendingCall] = useState();
  const [task, setTask] = useState();
  const [tocall, setToCall] = useState();
  const [History, setHistory] = useState();
  const [trackId, setTrackId] = useState();
  const [deleteSelect, setDeleteSelected] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [select, setSelect] = useState("");
  const [selectAssign, setSelectAssign] = useState({
    next_call_user: "",
    call_again_date: "",
  });
  const [taskData, setTaskData] = useState({
    added_by_user: user_id,
    added_by_username: user_name,
    customer_name: "",
    email_address: "",
    taskHeading: "",
    contact_number: "",
    description: "",
    lastDate: "",
    assineTO_userID: "",
    organisation_id: org,
  });
  
  const [personsearchresult, setpersonsearchresult ] = useState([]);

  const [editTask, setEditTask] = useState({
    taskID: "",
    taskStatus: "",
    remark: "",
  });

  const [transcription, settranscription] = useState({
    objection: "",
    summary: "",
    transcription: "",
    sentiment:"",
    suggetion: "",
    call_duration: ""
  });
  const [editcalldata, setEditcalldata] = useState({
    "script_category": "",
    "call_brief": "",
    "call_status": "",
    "clint_mood": "",
    "description": "",
    "docucmet_id": ""
  });
   
  const [filedata, setfile] = useState({
    organisation_id: org,
    added_by_user: user_id,
    added_by_username: user_name,
    file: "",
  });

  const [details, setdetails] = useState(true);

  const [downloadContact, setDownloadContact] = useState({
    staring_date: "",
    ending_date: "",
    filetype: "xlsx",
  });
  const handleChangeDownloadContact = (e) => {
    setDownloadContact({ ...downloadContact, [e.target.name]: e.target.value });
  };
  let formdata = new FormData();
  let audioformdata = new FormData();


  /////Spreed sheet update file 

  function updataFile(e) {
    const file = e.target.files[0];

    formdata.append("file", file);
    formdata.append("organisation_id", org);
    formdata.append("added_by_user", user_id);
    formdata.append("added_by_username", user_name);
    //setfile({...filedata , [e.target.name]:e.target.file[0]})
  }

   /////Audio file update 


  function updataAudioFile(e) {
    const uploaded_file = e.target.files[0];

    audioformdata.append("uploaded_file", uploaded_file);
    //audioformdata.append("Recordid", '644aa9bda435914ea27a2222');
  }

  function getTask() {
    dispatch(
      getTasks({
        access_token,
        org,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setOrgTask(res.payload.data);
      }
    });
  }

  function masknumber(item){
    if( details == true){
      return item;
    
     }else{
      return item.slice(0, -3).concat('*** ');
     }
     } 

  function SearchHome(){
    searchPerson( searchelement.tocall, 'to_search' );
  }

  function searchPerson( ph, itemtype ) {

    let searchcall;

    if(itemtype == 'to_search'){

      searchcall =  {
        "to_search":ph,
        "customer_name":"",
        "contact_number":"",
        "email_address":""
    };

    }

    const headers = {
      "Content-Type": 'application/json',
      Authorization: `Bearer ${Auth}`
    };
    axios
      .post('/search_for_contact',searchcall, { headers })
      .then((data) => {
        if (data.status == "200") {

          setpersonsearchresult(data.data);
         
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
    
    
  }

  function setperson(item,type){

    if(type =='task'){

      
    setTaskData({
      added_by_user: user_id,
      added_by_username: user_name,
      customer_name: item.customer_name,
      email_address: item.customer_name,
      taskHeading: "",
      contact_number: item.contact_number,
      description: "",
      lastDate: "",
      assineTO_userID: "",
      organisation_id: org,
    });

    }else if(type =='call'){


      setaddCallData({
        organisation_id: org,
        added_by_user: user_id,
        added_by_username: user_name,
        customer_name: item.customer_name,
        contact_number: item.contact_number,
        email_address:  item.customer_name,
        description: "",
        next_call_user: "",
        Contact_Source: "",
        next_call_script_category: "",
        call_again_date: "",
    });


    }



  }

  const audioRef = useRef();

  const [call1, setCall1] = useState();
  const [call2, setCall2] = useState();
  const [call1_pre, setCall1_pre] = useState();
  const [call1_next, setCall1_next] = useState();
  const [call1_tp, setCall1_tp] = useState();
  const [call1_cp, setCall1_cp] = useState();
  const [call2_pre, setCall2_pre] = useState();
  const [call2_next, setCall2_next] = useState();
  const [call2_tp, setCall2_tp] = useState();
  const [call2_cp, setCall2_cp] = useState();

  const [call_pre_tocall, setcall_pre_tocall] = useState();
  const [call_next_tocall, setCall_next_tocall] = useState();
    const [call_tocall_tp, setcall_tocall_tp] = useState();
  const [call_tocall_cp, setCall_tocall_cp] = useState();



  const [task_pre, setTask_pre] = useState();
  const [task_next, setTask_next] = useState();
  const [task_tp, setTask_tp] = useState();
  const [task_cp, setTask_cp] = useState();

  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [intreseted, setIntreseted] = useState("0");
  const [notIntreseted, setNotIntreseted] = useState("0");
  const [callBack, setCallBack] = useState("0");
  const [totalCall, setTotalCall] = useState("0");
  const [todayCall, setTodayCall] = useState("0");

  const [RecLink, setRecLink] = useState("");

  const [intreseted1, setIntreseted1] = useState("0");
  const [notIntreseted1, setNotIntreseted1] = useState("0");
  const [callBack1, setCallBack1] = useState("0");
  const [totalCall1, setTotalCall1] = useState("0");
  const [todayCall1, setTodayCall1] = useState("0");

  const [feeduser, setFeedUser] = useState();
  const [disabled, setDisabled] = useState(false);

  const [addCallData, setaddCallData] = useState({
    organisation_id: org,
    added_by_user: user_id,
    added_by_username: user_name,
    customer_name: "",
    contact_number: "",
    email_address: "",
    description: "",
    next_call_user: "",
    Contact_Source: "",
    next_call_script_category: "",
    call_again_date: "",
  });

  const [filterData, setFilterData] = useState({
    organisation_id: org,
    call_brief: "",
    call_status: "",
    arrange: "0",
  });
  const [filterToday, setFilterToday] = useState({
    organisation_id: org,
    script_category: "",
  });
  const [filterTask, setFilterTask] = useState({ task_type: "" });
  const [filterTaskUser, setFilterTaskUser] = useState({
    organisation_id: org,
    UserID: user_id,
    taskHeading: "notselected",
    taskStatus: "",
    arrange: "0",
  });
  const [addsource, setAddscource] = useState("");
  const [sourceList, setSourceList] = useState([]);

  const [activestatus, setactivestatus ] = useState({
    'Active': "0", 'Not_Active': "0"
  })


  const [paginationSize, setpaginationSize] = useState('30');

  //////////////////////////////////////////Ai transcription offcanvas Data///////////////////////////////////////////////////////

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //////////////////////////////////////////Card Data///////////////////////////////////////////////////////
  const getSourceList = () => {
    dispatch(getSourceListData({ access_token: Auth })).then((res) => {
      if (res.payload.status == "200") {
        setSourceList(res.payload.data);
      }
    });
  };
  const handleAddSource = () => {
    if (addsource) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .post(`/Add_contactSource?Contact_Source=${addsource}`, {}, { headers })
        .then((data) => {
          if (data.status == "200") {
            setAddscource("");
            getSourceList();
            toast.success("Source Add Successfully!", {
              position: "bottom-left",
              autoClose: 1000,
            });
          }
        });
    }
  };

  function getScript() {
    dispatch(
      getScriptAll({
        access_token,
        org,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setOrgCategory(res.payload.data);
      }
    });
  }

  function fetchOrgData() {
  
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_organisation?organisation_id=".concat(org), { headers })
      .then((data) => {
        if (data.status == "200") {
          setdetails(data.data[0].Numbermassing);
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function getactive(){
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/client_status",
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          
          setactivestatus({
            'Active': data.data.active_count, 'Not_Active': data.data.not_active_count
          });

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });

  }


  function fetchUserData() {
    dispatch(
      getUserData({
        access_token,
        org,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setFeedUser(res.payload.data);
      }
    });
  }

  function getSupportinDoc(item) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/View_Suppoting_File?".concat(
           "Recordid=",
          item._id
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          
        }
        setErrorMessage2(data.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  function getCardData() {
    dispatch(
      getAdminCard({
        access_token,
        org,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setIntreseted(res.payload.data.Interested);
        setNotIntreseted(res.payload.data.Not_Interested);
        setCallBack(res.payload.data.Follow_Up);
        setTotalCall(res.payload.data.Total_call);
        setTodayCall(res.payload.data.Todays_calls);
      }
    });
  }

  ////////////////////////////CARD DATA FOR USER///////////////////////////////////////////////////

  function getCardData1() {
    dispatch(
      getUserCard({
        access_token,
        org,
        userId: user_id,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setIntreseted1(res.payload.data.Interested);
        setNotIntreseted1(res.payload.data.Not_Interested);
        setCallBack1(res.payload.data.Follow_Up);
        setTotalCall1(res.payload.data.Total_call);
        setTodayCall1(res.payload.data.Todays_calls);
      }
    });
  }
  function getToCallData() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    

    if (admin == "true") {
      dispatch(
        getToCallAdmin({
          access_token,
          org,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          setToCall(res.payload.data.Call_records);
          setcall_pre_tocall(res.payload.data.pagination.previous);
          setCall_next_tocall(res.payload.data.pagination.next);
          setcall_tocall_tp(res.payload.data.total_number_of_pages);
          setCall_tocall_cp(res.payload.data.page_number);
        }
      });
    } else {
      dispatch(
        getToCallUser({
          access_token,
          org,
          userId: user_id,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          setToCall(res.payload.data.Call_records);
          setcall_pre_tocall(res.payload.data.pagination.previous);
          setCall_next_tocall(res.payload.data.pagination.next);
          setcall_tocall_tp(res.payload.data.total_number_of_pages);
          setCall_tocall_cp(res.payload.data.page_number);
        }
      });
    }
  }

  function prePage_tocall(endpoint) {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get("".concat(endpoint), headers)
      .then((data) => {
        if (data.status == "200") {
          setToCall(data.data.Call_records);
          setcall_pre_tocall(data.data.pagination.previous);
          setCall_next_tocall(data.data.pagination.next);
          setcall_tocall_tp(data.data.total_number_of_pages);
          setCall_tocall_cp(data.data.page_number);

        }
        setErrorMessage2(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage2);
      });
  }

  function nextPage_tocall(endpoint) {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get("".concat(endpoint), headers)
      .then((data) => {
        if (data.status == "200") {
          setToCall(data.data.Call_records);
          setcall_pre_tocall(data.data.pagination.previous);
          setCall_next_tocall(data.data.pagination.next);
          setcall_tocall_tp(data.data.total_number_of_pages);
          setCall_tocall_cp(data.data.page_number);
        }
        setErrorMessage2(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage2);
      });
  }
  
  function openNav(item, itemId) {
    setHistory();

    searchContact(item);
    setTrackId(itemId);

    document.getElementById("mySidenav").style.width = "500px";

    document.getElementById("trackbtn").click();
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  function searchContact(item) {
    let phoneno = item;
    dispatch(
      getContactNumber({
        access_token,
        org,
        phoneNo: phoneno,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setHistory(res.payload.data.call_history);
      }
    });
  }

  function updateTask() {
    dispatch(
      updateTasks({
        access_token,
        data: editTask,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        window.location.reload(false);

      }
    });
  }

  function delToCallData(item) {
    dispatch(
      deleteContactToCall({
        access_token,
        id: item._id,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        getToCallData();
      }
    });
  }

 

  function fetchToday(page_number,call_date) {

    const scriptCategory = filterToday.script_category

    

      dispatch(
        dateAssignedCall({
          access_token,call_date,page_number,scriptCategory,paginationSize
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          setToday(res.payload.data);
        }
      });


 
  }

  function tasktoday() {

    if (admin == "true") {

      dispatch(
        pendingTodaysAdmintask({
          access_token,
          org,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          if (filterTask.task_type == "Assigned") {
            setTask(res.payload.data.assigned);
          } else if (filterTask.task_type == "InProgress") {
            setTask(res.payload.data.InProgress);
          } else if (filterTask.task_type == "Canceled") {
            setTask(res.payload.data.Canceled);
          } else if (filterTask.task_type == "") {
            setTask(res.payload.data.assigned);
          }
        }
      });

    }else{

      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/pending_todays_task?organizationID=".concat(
            org,
            "&userID=",
            user_id
          ),
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            if (filterTask.task_type == "Assigned") {
              setTask(data.data.assigned);
            } else if (filterTask.task_type == "InProgress") {
              setTask(data.data.InProgress);
            } else if (filterTask.task_type == "Canceled") {
              setTask(data.data.Canceled);
            } else if (filterTask.task_type == "") {
              setTask(data.data.assigned);
            }
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage);
        });

    }

   
  }

  useEffect(() => {
    if(admin != 'true'){

      fetchOrgData();
      

    }

    if(admin == 'true'){
      getactive();
    }



    getCardData();
    getCardData1();
    getSourceList();
    getScript();
    fetchToday('1', today_date);
    tasktoday();
    fetchUserData();
    getTask();

    

  }, [1]);

  function updataFilterData(e) {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });
  }

  function updataSearchElement(e) {
    setsearchelement({ ...searchelement, [e.target.name]: e.target.value });
  }

  function updateTodayFilter(e) {
    setFilterToday({ ...filterToday, [e.target.name]: e.target.value });
  }

  function updateTaskFilter(e) {
    setFilterTaskUser({ ...filterTaskUser, [e.target.name]: e.target.value });
  }
  function updateTaskType(e) {
    setFilterTask({ ...filterTask, [e.target.name]: e.target.value });
  }

  function changeTaskData(e) {
    setTaskData({ ...taskData, [e.target.name]: e.target.value });
  }
  function editTaskData(e) {
    setEditTask({ ...editTask, [e.target.name]: e.target.value });
  }

  function setDefaultTask(item) {
    setEditTask({ taskID: item._id, taskStatus: item.taskStatus, remark: "" });
  }

  function changeCallData(e) {
    setaddCallData({ ...addCallData, [e.target.name]: e.target.value });
  }

  function updataEditCallData(e) {
    setEditcalldata({ ...editcalldata, [e.target.name]: e.target.value });
  }

  function sendToCallData(e) {
    dispatch(
      addNewContact({
        access_token,
        data: addCallData,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setaddCallData({
          organisation_id: org,
          added_by_user: user_id,
          added_by_username: user_name,
          customer_name: "",
          contact_number: "",
          email_address: "",
          description: "",
          Contact_Source: "",
          next_call_user: "",
          next_call_script_category: "",
          call_again_date: "",
        });
        document.getElementById("close").click();
        getToCallData();
      }
    });
  }

  function getSpreedSheet() {
    const headers = {
      "Content-Type": "application/json",
      responseType: "blob",
    };

    axios
      .get("/GenerateNewFile?".concat("organisation_id=", org, "&Contact_Source=",''), headers)
      .then((data) => {
        if (data.status == "200") {
          const url = window.URL.createObjectURL(new Blob([data.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Tocall.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
        setErrorMessage2(data.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function uploadSpreedSheet() {
    setDisabled(true);

    dispatch(
      uploadConatctFile({
        access_token,
        data: formdata,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        getToCallData();
        setDisabled(false);
        toast.success("Successfully uploaded!", {
          position: "bottom-left",
          autoClose: 1000,
        });
        document.getElementById("close1").click();
      }
    });
  }

  function sendTask() {

    if(taskData.contact_number == ""){

      const min = 100000; // Minimum value (inclusive) - 100,000
      const max = 999999; // Maximum value (inclusive) - 999,999
  
      let digit2 = Math.floor(Math.random() * (max - min + 1)) + min;

    
        let temphone = '####' + digit2.toString();
  

       taskData.contact_number = temphone;

    }



    dispatch(
      addNewTask({
        access_token,
        data: taskData,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setTaskData({
          added_by_user: user_id,
          added_by_username: user_name,
          customer_name: "",
          email_address: "",
          taskHeading: "",
          contact_number: "",
          description: "",
          lastDate: "",
          assineTO_userName: "",
          assineTO_userID: "",
          organisation_id: org,
        });
        document.getElementById("close1").click();
        document.getElementById("task-tab").click();
      }
    });
  }

  function clearFilter() {
    setFilterData({
      organisation_id: org,
      call_brief: "",
      call_status: "",
      arrange: "0",
    });
    setFilterToday({ organisation_id: org, script_category: "" });
    setFilterTask({ task_type: "" });
    setFilterTaskUser({
      organisation_id: org,
      UserID: user_id,
      taskHeading: "",
      taskStatus: "",
      arrange: "0",
    });
  }

  function editCall() {
    const headers = {
      "Content-Type": "text/plain",
    };
    axios
      .post(
        "/FillUp_Call_Details", editcalldata ,
        headers
      )
      .then((data) => {
        if (data.status == "200") {

          
          
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });
  }

  function setupeditdata(item){

    setEditcalldata({
      "script_category": item.script_category,
      "call_brief": item.call_brief,
      "call_status": item.call_status,
      "docucmet_id": item.id
    });

  }


  function returncontact(item){

    return <OverlayTrigger trigger="click" placement="bottom" overlay={renderPopover('Contact No: '.concat(item.contact_number, ', Email: ', item.email_address))}>
    <i className="bi bi-caret-down-fill"></i>
            </OverlayTrigger>

  } 


  function returncontactmem(item){

 if( details == true){
  return <OverlayTrigger trigger="click" placement="bottom" overlay={renderPopover('Contact No: '.concat(item.contact_number, ', Email: ', item.email_address))}>
  <i className="bi bi-caret-down-fill"></i>
          </OverlayTrigger>

 }else{
  return <OverlayTrigger trigger="click" placement="bottom" overlay={renderPopover('Contact No: '.concat(item.contact_number.slice(0, -3) + '*** ', ', Email: ', item.email_address))}>
  <i className="bi bi-caret-down-fill"></i>
          </OverlayTrigger>
 }
  } 

  const [searchQuery, setSearchQuery] = useState('');
  const [highlightedContent, setHighlightedContent] = useState('');

  const performSearch = () => {
    // Get the division containing the text
    const textDivision = document.getElementById('textDivision');

    // Clear any previous highlights
    const clearedContent = textDivision.innerHTML.replace(
      /<span class="highlighted">(.*?)<\/span>/g,
      '$1'
    );
    textDivision.innerHTML = clearedContent;

    // Perform the search and highlight the matches
    const regex = new RegExp(searchQuery, 'gi');
    const matches = textDivision.innerHTML.match(regex);

    if (matches) {
      // Highlight the matching occurrences
      const highlightedContent = textDivision.innerHTML.replace(
        regex,
        '<span class="highlighted">$&</span>'
      );
      textDivision.innerHTML = highlightedContent;
    }
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };



  if (admin == "true") {
    ///////////////////////////////////All Calls ///////////////////////////////////////////////////////////

   

    function fetchPendingCall() {
      dispatch(
        adminCallDues({
          access_token,
          org,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          setPendingCall(res.payload.data);
        }
      });
    }

    function fetchTask() {
      dispatch(
        getAllTaskAdmin({
          access_token,
          org,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          setTask(res.payload.data.Task);
          setTask_pre(res.payload.data.pagination.previous);
          setTask_next(res.payload.data.pagination.next);
          setTask_tp(res.payload.data.total_number_of_pages);
          setTask_cp(res.payload.data.page_number);
        }
      });
    }

    function page_task(endpoint) {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };

      axios
        .get("".concat(endpoint), { headers })
        .then((data) => {
          if (data.status == "200") {
            setTask(data.data.Task);

            setTask_pre(data.data.pagination.previous);
            setTask_next(data.data.pagination.next);
            setTask_tp(data.data.total_number_of_pages);
            setTask_cp(data.data.page_number);
          }
          setErrorMessage2(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage2);
        });
    }

    function todayFilter() {
      dispatch(
        todayCallAdminsSriptCategory({
          access_token,
          org,
          script: filterToday.script_category,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          setToday(res.payload.data);
        }
      });
    }

    function fetchData1() {
      dispatch(
        getAllContact({
          access_token,
          org,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          setCall1(res.payload.data.Call_records);
          setCall1_pre(res.payload.data.pagination.previous);
          setCall1_next(res.payload.data.pagination.next);
          setCall1_tp(res.payload.data.total_number_of_pages);
          setCall1_cp(res.payload.data.page_number);
        }
      });
    }

    ///////////////////////////////////Follow up ///////////////////////////////////////////////////////////

    function fetchData2() {
      dispatch(
        getfollowContact({
          access_token,
          org,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          setCall2(res.payload.data.Call_records);
          setCall2_pre(res.payload.data.pagination.previous);
          setCall2_next(res.payload.data.pagination.next);
          setCall2_tp(res.payload.data.total_number_of_pages);
          setCall2_cp(res.payload.data.page_number);
        }
      });
    }

    //////////////////////////////////////////////Follow up with filter///////////////////////////////////////////////////////

    function followup2(e) {
      e.preventDefault();
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };

      if (filterData.call_brief == "") {
        axios
          .get(
            "/get_follow_contact?organisation_id=".concat(
              org,
              "&call_status=",
              filterData.call_status,
              "&arrange=",
              filterData.arrange
            ),
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              setCall2(data.data.Call_records);
              setCall2_pre(data.data.pagination.previous);
              setCall2_next(data.data.pagination.next);
              setCall2_tp(data.data.total_number_of_pages);
              setCall2_cp(data.data.page_number);
            }
            setErrorMessage2(data.data.message);
          })
          .catch((err) => {
            console.log(err);
            console.log(errorMessage2);
          });
      } else if (filterData.call_status == "") {
        axios
          .get(
            "/get_follow_contact?organisation_id=".concat(
              org,
              "&call_brief=",
              filterData.call_brief,
              "&arrange=",
              filterData.arrange
            ),
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              setCall2(data.data.Call_records);
              setCall2_pre(data.data.pagination.previous);
              setCall2_next(data.data.pagination.next);
              setCall2_tp(data.data.total_number_of_pages);
              setCall2_cp(data.data.page_number);
            }
            setErrorMessage2(data.data.message);
          })
          .catch((err) => {
            console.log(err);
            console.log(errorMessage2);
          });
      } else {
        axios
          .get(
            "/get_follow_contact?organisation_id=".concat(
              org,
              "&call_brief=",
              filterData.call_brief,
              "&call_status=",
              filterData.call_status,
              "&arrange=",
              filterData.arrange
            ),
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              setCall2(data.data.Call_records);
              setCall2_pre(data.data.pagination.previous);
              setCall2_next(data.data.pagination.next);
              setCall2_tp(data.data.total_number_of_pages);
              setCall2_cp(data.data.page_number);
            }
            setErrorMessage2(data.data.message);
          })
          .catch((err) => {
            console.log(err);
            console.log(errorMessage2);
          });
      }
    }
    //////////////////////////////////////Fliter of all calls//////////////////////////////////////////////////////////

    function filter2(e) {
      e.preventDefault();
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };

      if (filterData.call_brief == "") {
        axios
          .get(
            "/get_all_contact?organisation_id=".concat(
              org,
              "&call_status=",
              filterData.call_status,
              "&arrange=",
              filterData.arrange
            ),
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              setCall1(data.data.Call_records);
              setCall1_pre(data.data.pagination.previous);
              setCall1_next(data.data.pagination.next);
              setCall1_tp(data.data.total_number_of_pages);
              setCall1_cp(data.data.page_number);
            }
            setErrorMessage2(data.data.message);
          })
          .catch((err) => {
            console.log(err);
            console.log(errorMessage2);
          });
      } else if (filterData.call_status == "") {
        axios
          .get(
            "/get_all_contact?organisation_id=".concat(
              org,
              "&call_brief=",
              filterData.call_brief,
              "&arrange=",
              filterData.arrange
            ),
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              setCall1(data.data.Call_records);
              setCall1_pre(data.data.pagination.previous);
              setCall1_next(data.data.pagination.next);
              setCall1_tp(data.data.total_number_of_pages);
              setCall1_cp(data.data.page_number);
            }
            setErrorMessage2(data.data.message);
          })
          .catch((err) => {
            console.log(err);
            console.log(errorMessage2);
          });
      } else {
        axios
          .get(
            "/get_all_contact?organisation_id=".concat(
              org,
              "&call_brief=",
              filterData.call_brief,
              "&call_status=",
              filterData.call_status,
              "&arrange=",
              filterData.arrange
            ),
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              setCall1(data.data.Call_records);
              setCall1_pre(data.data.pagination.previous);
              setCall1_next(data.data.pagination.next);
              setCall1_tp(data.data.total_number_of_pages);
              setCall1_cp(data.data.page_number);
            }
            setErrorMessage2(data.data.message);
          })
          .catch((err) => {
            console.log(err);
            console.log(errorMessage2);
          });
      }
    }

    function nextPage_call2(endpoint) {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };

      axios
        .get(`${endpoint}`, { headers })
        .then((data) => {
          if (data.status == "200") {
            setCall2(data.data.Call_records);
            setCall2(data.data.Call_records);
            setCall2_pre(data.data.pagination.previous);
            setCall2_next(data.data.pagination.next);
            setCall2_tp(data.data.total_number_of_pages);
            setCall2_cp(data.data.page_number);
          }
          setErrorMessage2(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage2);
        });
    }

    function prePage_call2(endpoint) {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };

      axios
        .get(`${endpoint}`, { headers })
        .then((data) => {
          if (data.status == "200") {
            setCall2(data.data.Call_records);
            setCall2_pre(data.data.pagination.previous);
            setCall2_next(data.data.pagination.next);
            setCall2_tp(data.data.total_number_of_pages);
            setCall2_cp(data.data.page_number);
          }
          setErrorMessage2(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage2);
        });
    }

    function nextPage_call1(endpoint) {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };

      axios
        .get(`${endpoint}`, { headers })
        .then((data) => {
          if (data.status == "200") {
            setCall1(data.data.Call_records);
            setCall1_pre(data.data.pagination.previous);
            setCall1_next(data.data.pagination.next);
            setCall1_tp(data.data.total_number_of_pages);
            setCall1_cp(data.data.page_number);
          }
          setErrorMessage2(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage2);
        });
    }

    function prePage_call1(endpoint) {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };

      axios
        .get(`${endpoint}`, { headers })
        .then((data) => {
          if (data.status == "200") {
            setCall1(data.data.Call_records);
            setCall1_pre(data.data.pagination.previous);
            setCall1_next(data.data.pagination.next);
            setCall1_tp(data.data.total_number_of_pages);
            setCall1_cp(data.data.page_number);
          }
          setErrorMessage2(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage2);
        });
    }

    
    function AdminSundayTemp() {
      if (todayCall != 0 && todayCall != 1) {
        return (
          <p className="card-text">
            Your team has to complete <b>{todayCall}</b> calls.
          </p>
        );
      } else if (todayCall == 1) {
        return (
          <p className="card-text">
            Your team has to complete <b>{todayCall}</b> call.
          </p>
        );
      } else {
        return (
          <>
            <img
              src="https://salessunday-image.s3.ap-south-1.amazonaws.com/Pages-image/no+calls+available.svg"
              height={250}
            />
            <p className="card-text"> No calls are assigned to anyone today.</p>
          </>
        );
      }
    }

    function uploadAudio() {
      dispatch(
        uploadCallRecoding({
          access_token,
          data: audioformdata,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          getToCallData();
          document.getElementById("close1").click();
        }
      });
    }

    function getRec(item) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };

      axios
        .get(
          "/CallRecoding?".concat(
             "docucmet_id=",
            item.id
          ),
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            updateSong(data.data);
            rec();
          }
          setErrorMessage2(data.data.message);
        })
        .catch((err) => {
          console.log(err);
        });
    }



    function getCallAi(item){

      handleShow();
      settranscription({
    objection: "",
    summary: "",
    transcription: ""
  });

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get("/get_call_transcripts?docucmet_id=".concat(item), { headers })
        .then((data) => {
          if (data.status == "200") {
            settranscription(data.data);
            
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => console.log(err));
        
    }

   

    function taskfilter() {
      let data = {
        arrange: filterTaskUser.arrange,
        status: filterTaskUser.taskStatus,
        heading: filterTaskUser.taskHeading,
      };

      dispatch(
        getAllTaskAdminFilter({
          access_token,
          data,
          org,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          setTask(res.payload.data.Task);
          setTask_pre(res.payload.data.pagination.previous);
          setTask_next(res.payload.data.pagination.next);
          setTask_tp(res.payload.data.total_number_of_pages);
          setTask_cp(res.payload.data.page_number);
        }
      });
    }

    

    function taskpending() {
      dispatch(
        adminTaskDues({
          access_token,
          org,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          if (filterTask.task_type == "Assigned") {
            setTask(res.payload.data.assigned);
          } else if (filterTask.task_type == "InProgress") {
            setTask(res.payload.data.InProgress);
          } else if (filterTask.task_type == "Canceled") {
            setTask(res.payload.data.Canceled);
          } else if (filterTask.task_type == "") {
            setTask(res.payload.data.assigned);
          }
        }
      });
    }

    const updateSong = (source) => {
      setRecLink(source);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.load();
        audioRef.current.play();
      }
    };

    function changeRec(item) {
      if (item.call_recoding == true) {
        return (
          <>
          <button
            onClick={() => getRec(item)}
            className="border-0 bg-transparent"
          >
            <i className="bi bi-play-circle-fill"></i>
          </button>
            <button
            onClick={() => getCallAi(item.id)}
            className="border-0 bg-transparent"
          >
           <i className="bi bi-robot"></i>
          </button></>
        );
      } else if (item.call_recoding == false) {
        return (
          <button
            className="border-0 bg-transparent"
            data-bs-toggle="modal"
            data-bs-target="#audioModal"
            onClick={()=>setAudioId(item.id)}
          >
            <i className="bi bi-cloud-arrow-up"></i>
          </button>
        );
      } else {
        return (
          <button
          className="border-0 bg-transparent"
          data-bs-toggle="modal"
          data-bs-target="#audioModal"
          onClick={()=>setAudioId(item.id)}
        >
          <i className="bi bi-cloud-arrow-up"></i>
        </button>
        );
      }
    }

    function setAudioId(item){

     audioformdata.append("Recordid", item);
      

    }

    let handleDelete = (item) => {
      let data = {
        organisation_id: org,
        contact_number: item.contact_number,
        added_at: item.added_at,
      };

      dispatch(
        deteleCallRecord({
          access_token,
          data: JSON.stringify(data),
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          fetchData1();
        }
      });
    };

    let handleDownload = () => {
      let data = {
        organisation_id: org,
        ...downloadContact,
      };
      const headers = {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${Auth}`,
      };
      // .post("/Download_Leads", JSON.stringify(data)
      axios
        .post("/Download_CallData", JSON.stringify(data), {
          headers,
        })
        .then((data) => {
          if (data.status == "200") {
            const url = window.URL.createObjectURL(new Blob([data.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `CallData.${downloadContact.filetype}`
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage);
        });
    };

    const handleClickCheck = (e) => {
      const { id, checked } = e.target;
      setDeleteSelected([...deleteSelect, id]);
      if (!checked) {
        setDeleteSelected(deleteSelect.filter((item) => item !== id));
      }
    };
    const handleSelectAll = (list) => {
      setIsCheckAll(!isCheckAll);
      setDeleteSelected(list?.map((li) => li._id));
      if (isCheckAll) {
        setDeleteSelected([]);
      }
    };
    const onChangeAssignTo = (e) => {
      setSelectAssign({ ...selectAssign, [e.target.name]: e.target.value });
    };

    const deleteAllselected = (item) => {
      if (item.length > 0) {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth}`,
        };
        axios
          .delete(
            "/Delete_Multiple_Tocall_List",
            { headers, data: item }
            // { data: [...item] }
          )
          .then((data) => {
            if (data.status == "200") {
              getToCallData();
              setDeleteSelected([]);
              setSelect("");
              setSelectAssign(selectAssign);
              toast.success("Deleted Successfully!", {
                position: "bottom-left",
                autoClose: 1000,
              });
            }
          });
      }
    };
    const updateAssignTo = (item) => {
      let payload = {
        id_list: item,
        next_call_user: selectAssign.next_call_user,
        call_again_date: selectAssign.call_again_date,
        next_call_script_category: selectAssign.next_call_script_category
      };
      if (selectAssign.next_call_user) {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth}`,
        };
        axios
          .post("/Multiple_Tocall_List_Update", payload, { headers })
          .then((data) => {
            if (data.status == "200") {
              getToCallData();
              setSelect("");
              setSelectAssign(selectAssign);
              setDeleteSelected([]);
              toast.success("Updated Successfully!", {
                position: "bottom-left",
                autoClose: 1000,
              });
            }
          });
      }
    };

    return (
      <>
        <div className="container">
          {/* <div className="row">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item bg-transparent" id="callback">
                <p
                  className="accordion-header"
                  id="flush-headingOne"
                  onClick={getCardData}
                  style={{ cursor: "pointer" }}
                >
                  <p
                    className="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Data Overview <i className="bi bi-caret-down-fill"></i>
                  </p>
                </p>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="row">
                  <div className="col-12 col-sm-3 p-2" align='center'>
                      <div className="col-10">
                      <div className="text-white bg-dark card1_design border border-secondary p-2">
                        <div className="card-body">
                          <h3 align="left">
                          <i className="bi bi-person-check-fill"></i>
                            <b> {activestatus.Active} | {activestatus.Not_Active}</b>
                          </h3>
                          <p align="left" className="card-text text-white">
                            
                          Active | Not Active
                          </p>
                        </div>
                      </div>
                      </div>
                    </div>
                    <div className="col-6 col-sm-2 p-2">
                      <div className="text-dark card_design border border-secondary p-2">
                        <div className="card-body">
                          <h3 align="left">
                            <i className="bi bi-emoji-sunglasses-fill"></i>
                            <b> {intreseted}</b>
                          </h3>
                          <p align="left" className="card-text text-dark">
                            
                            Interested
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-sm-2 p-2">
                      <div className="text-dark card_design border border-secondary p-2">
                        <div className="card-body">
                          <h3 align="left">
                            <i className="bi bi-emoji-neutral-fill"></i>
                            <b> {notIntreseted}</b>
                          </h3>
                          <p align="left" className="card-text text-dark">
                            Not Interested
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-sm-2 p-2">
                      <div className="text-dark card_design border border-secondary p-2 ">
                        <div className="card-body">
                          <h3 align="left">
                            
                            <i className="bi bi-telephone-plus-fill"></i>
                            <b> {callBack}</b>
                          </h3>
                          <p align="left" className="card-text text-dark">
                            
                            To Call
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 col-sm-2 p-2">
                      <div className="text-dark card_design border border-secondary p-2">
                        <div className="card-body">
                          <h3 align="left">
                            <i className="bi bi-phone-fill"></i>
                            <b> {totalCall}</b>
                          </h3>
                          <p align="left" className="card-text text-dark">
                            
                            Total Calls
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div align='center'><div className="col-9"><CardData className="align-self-end" /></div></div>
          
          
          <br/>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          {/* <button className="btn btn-info bg-gradient border-dark position-relative" data-bs-toggle="modal" href="#modalToggleTask" type="button"><i className="bi bi-circle-square"></i> Add Task 
                          </button> */}
          <div className="dropdown">
  <button
    className="btn btn-success bg-gradient dropdown-toggle border-dark"
    type="button"
    id="dropdownMenuButton1"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <i className="bi bi-plus-circle"></i> Add
  </button>
  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li>
      <a className="dropdown-item" href="#" data-bs-toggle="modal"
                  data-bs-target="#exampleModal"> 
       <i className="bi bi-plus-circle-fill"></i> Single Call
      </a>
    </li>
    <li>
      <a className="dropdown-item" href="#"  data-bs-toggle="modal"
                  data-bs-target="#exampleModalmulti">
        <i className="bi bi-file-earmark-spreadsheet"></i> Multi Call
      </a>
    </li>
    <li>
      <a className="dropdown-item"  data-bs-toggle="modal" href="#modalToggleTask">
      <i className="bi bi-bicycle"></i> Tasks
      </a>
    </li>
  </ul>
</div>

                          
                          <Link
                            to="#"
                          ><button className="btn btn-outline-dark position-relative" type="button" data-bs-toggle="modal"
                  data-bs-target="#exampleModal15">  <i className="bi bi-bezier2"></i> Source
                          </button></Link>

          <Link
                            to="/callinfo"
                          ><button className="btn btn-dark bg-gradient border-dark position-relative" type="button"><i className="bi bi-journal"></i> Contact Book 
                          </button></Link>
                          <Link
                            to="/taskboard"
                          ><button className="btn btn-warning bg-gradient border-dark position-relative" type="button"><i className="bi bi-square"></i> Task-Board 
                          <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-dark text-white">
  New
  <span className="visually-hidden">unread messages</span>
</span>

                          </button></Link>
  <Link
                            to="/sopboard"
                          ><button className="btn btn-primary bg-gradient border-dark position-relative" type="button"><i className="bi bi-clipboard2"></i> Lead Management 
                          <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-warning text-dark">
  New
  <span className="visually-hidden">unread messages</span>
</span>

                          </button></Link>
</div>
         
        </div>
        <div className="container">
          <ul
            className="nav nav-tabs border-0"
            id="myTab"
            role="tablist"
          >
            
            <li className="nav-item border-0" role="presentation">
              <button
                onClick={() => {
                  fetchToday('1', today_date)
                  tasktoday();
                }}
                className="nav-link active" 
                id="today-tab"
                data-bs-toggle="tab"
                data-bs-target="#today"
                type="button"
                role="tab"
                aria-controls="today"
                aria-selected="true"
              >
                <i className="bi bi-calendar-event"></i> Today
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={() => {
                  fetchPendingCall();
                  taskpending();
                }}
                className="nav-link"
                id="pending-tab"
                data-bs-toggle="tab"
                data-bs-target="#pending"
                type="button"
                role="tab"
                aria-controls="pending"
                aria-selected="true"
              >
                <i className="bi bi-calendar-x"></i> Dues
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={getToCallData}
                className="nav-link "
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                title="Add numbers you want to call"
                aria-selected="true"
              >
                <i className="bi bi-telephone"></i> To call
              </button>
            </li>
            {/* <li className="nav-item" role="presentation">
              <button
                onClick={fetchData2}
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                <i className="bi bi-telephone-plus"></i> Call Back
              </button>
            </li> */}
            <li className="nav-item" role="presentation">
              <button
                onClick={fetchData1}
                className="nav-link"
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                <i className="bi bi-telephone-outbound"></i> Called
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={fetchTask}
                className="nav-link"
                id="task-tab"
                data-bs-toggle="tab"
                data-bs-target="#task"
                type="button"
                role="tab"
                aria-controls="task"
                aria-selected="false"
              >
                <i className="bi bi-bicycle"></i> All Task
              </button>
            </li>
          </ul>
          <br />
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button className="btn btn-transparent" type="button">
                  Add To Call List:
                </button>
                <button
                  id="single"
                  type="button"
                  className="btn btn-dark bg-gradient "
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <i className="bi bi-plus-circle"></i> Single
                </button>
                <button
                  className="btn btn-success bg-gradient me-md-2"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalmulti"
                >
                  <i className="bi bi-file-earmark-spreadsheet"></i> Multi
                </button>
                <button
                  className="btn btn-outline-dark"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal15"
                >
                  <i className="bi bi-bezier2"></i> Source
                </button>
              </div>
              

              <br />
              {tocall == "" ? (
                <div align="center">
                  <img src={empty} width="400" height="400" />
                </div>
              ) : (
                <div className="card_design border border-secondary">
                  <br />
                  <div className="container">
                  <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              >
                    <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                      <caption>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          {/* <div style={{ width: "60px" }}>To Call</div> */}
                          {deleteSelect.length > 0 ? (
                            <div>
                              <select
                                value={select}
                                onChange={(e) => setSelect(e.target.value)}
                                name="assineTO_userID"
                                className="form-select  col-6 border border-success inside"
                                aria-label="Default select example"
                                required
                              >
                                <option>Select Action</option>
                                <option value="delete">Delete</option>
                                <option value="assign">Reassign</option>
                              </select>
                            </div>
                          ) : null}
                          <div style={{ marginLeft: "8px" }}>
                            {deleteSelect.length > 0 ? (
                              <button
                                data-bs-toggle="modal"
                                data-bs-target={
                                  select === "delete"
                                    ? "#exampleModal11"
                                    : "#exampleModal12"
                                }
                                className="btn btn-dark pop"
                                type="button"
                                disabled={
                                  !(select == "delete" || select == "assign")
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <i className="bi bi-arrow-right" />
                                &nbsp;Apply
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </caption>

                      <thead className="table-dark">
                        <tr>
                          <th scope="col">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={() =>
                                  handleSelectAll(tocall && tocall)
                                }
                                id="defaultCheck1"
                                checked={isCheckAll}
                              />
                              <i className="bi bi-person"></i> Name
                            </div>
                          </th>
                          <th scope="col">
                            <i className="bi bi-card-text"></i> Description
                          </th>
                          <th scope="col"><i className="bi bi-person-badge"></i> Assigned User</th>
                          <th scope="col"> <i className="bi bi-telephone"></i> Call type</th>
                          <th scope="col">
                            <i className="bi bi-calendar4-event"></i> Assigned Date
                          </th>
                          <th scope="col">
                            <i className="bi bi-menu-button-fill"></i> 
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tocall &&
                          tocall?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={deleteSelect.includes(item._id)}
                                    name="chk"
                                    id={item._id}
                                    // value={item._id}
                                    onChange={handleClickCheck}
                                    // id="defaultCheck1"
                                  />

{item.customer_name==""?<a
                                  href={
                                    "callinfo?phoneno=" + item.contact_number
                                  }
                                  target="_blank"
                                >{item.contact_number}</a>:<a
                                href={
                                  "callinfo?phoneno=" + item.contact_number
                                }
                                target="_blank"
                              >{item.customer_name}</a>
                        }
                        {returncontact(item)}
                                </div>
                              </td>
                            
                              <td>{item.description}</td>
                              <td>
                                <b>{item.next_call_username}</b>
                              </td>
                              <td>{item.next_call_script_category}</td>
                              <td>
                                <b>{item.call_again_date!=""?alterCallBack(item.call_again_date):null}</b>
                              </td>
                              <td>
                              {
                            item.Contact_Source == "" || item.Contact_Source == null ? null : (
                             <OverlayTrigger trigger="click" placement="top" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                                <i className="bi bi-bezier2"></i> 
                              </OverlayTrigger>

                            )
                           }
                           &nbsp;
                                <a
                                  href={
                                    "https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number
                                  }
                                  target="whatsapp"
                                >
                                  <i className="bi bi-whatsapp"></i>
                                </a>
                           {
                            item.next_call_script_category == "" || item.contact_number == "" || item._id ==""?
                            <OverlayTrigger trigger="click" placement="top" overlay={renderPopover("Data Canceled! Add assigned script, contact number and try again.")}>
                             <i className="bi bi-phone-fill text-secondary"></i> 
                          </OverlayTrigger>
                           :<Link
                            to={
                              `/call?nextcall=` +
                              item.next_call_script_category +
                              "&contactno=" +
                              item.contact_number +
                              "&email=" +
                              item.email_address +
                              "&customer=" +
                              item.customer_name +
                              "&calltype=tocall" +
                              sourcevalue(item) +
                              "&callId=" +
                              item._id
                            }
                          >
                            <i className="bi bi-phone-fill"></i>
                          </Link>
                           
                           }

                                
                                <i
                                  data-bs-toggle="modal"
                                  data-bs-target={"#Modal" + item._id}
                                  // onClick={() => delToCallData(item._id)}
                                  style={{ cursor: "pointer" }}
                                  className="bi bi-trash-fill"
                                ></i>
                                 <Alert
                                  modelid={"Modal" + item._id}
                                  heading={"You are about to delete a record."}
                                  description={
                                    "This will delete call record from list. Are you sure?"
                                  }
                                  method={delToCallData}
                                  item={item}
                                />
                               
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    </div>
                    
                    </div>

                    <div className="container">
                    <nav
                className="d-grid gap-2 d-md-flex justify-content-md-end"
                aria-label="..."
              >
                {/* tocall cahnges */}
                <ul className="pagination">
                  <li className="page-item">
                    <a className="btn btn-light">
                      
                      Total Pages: <b>{call_tocall_tp}</b>
                    </a>
                  </li>
                  {

call_tocall_cp == "1" || call_tocall_cp == "0"?<li className="page-item ">
<button 
  className="page-link bg-secondary text-white pop rounded" disabled
  style={{ cursor: "not-allowed" }}
  onClick={() => prePage_tocall(call_pre_tocall)}
>
  <i className="bi bi-arrow-left-circle" /> Previous
</button>
</li>:<li className="page-item ">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => prePage_tocall(call_pre_tocall)}
                    >
                      <i className="bi bi-arrow-left-circle" /> Previous
                    </a>
                  </li>

                  }
                  
                  <li className="page-item ">
                    <a className="page-link text-dark border-0 bg-transparent">
                      <b>{call_tocall_cp}</b>
                    </a>
                  </li>

{
  call_tocall_cp == call_tocall_tp || call_tocall_cp == '0'?<li className="page-item ">
  <button
    className="page-link bg-secondary text-white pop rounded" disabled
    style={{ cursor: "not-allowed" }}
    onClick={() => nextPage_tocall(call_next_tocall)}
  >
    Next <i className="bi bi-arrow-right-circle" />
  </button>
</li>:<li className="page-item ">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => nextPage_tocall(call_next_tocall)}
                    >
                      Next <i className="bi bi-arrow-right-circle" />
                    </a>
                  </li>

}

                  
                </ul>
              </nav>
                    </div>

                  </div>
                  <Alert
                    modelid={"exampleModal11"}
                    heading={"You are about to delete a record."}
                    description={
                      "This will delete call record from list. Are you sure?"
                    }
                    method={deleteAllselected}
                    item={deleteSelect}
                  />
                  <Alert
                    modelid={"exampleModal12"}
                    heading={"Reassign call."}
                    description={
                      <>
                        
                        <div className="row">
                          <div className="mb-1 col-6 bg-transparent">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                              <i className="bi bi-people-fill" /> Assign call
                              back to
                            </label>
                            <select
                              value={selectAssign.next_call_user}
                              onChange={onChangeAssignTo}
                              name="next_call_user"
                              className="form-select mb-3 col-6 border border-dark inside"
                              aria-label="Default select example"
                              required
                            >
                              <option>Select Person</option>
                              {feed &&
                                feed?.map((item) => (
                                  <option
                                    value={item.id + "," + item.user_name}
                                  >
                                    {item.user_name}-{item.role}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="mb-1 col-6 bg-transparent">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                              <i className="bi bi-alarm-fill" /> Call Back Time
                            </label>
                            <input
                              type="datetime-local"
                              value={selectAssign.call_again_date}
                              onChange={onChangeAssignTo}
                              name="call_again_date"
                              className="form-control border border-dark inside"
                              id="exampleFormControlInput1"
                              placeholder="Call Back"
                            />
                          </div>
                          <div className="mb-1 col-12 bg-transparent">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                              <i className="bi bi-file-text-fill"></i> Assign call
                              type
                            </label>
                            <select
                              value={selectAssign.next_call_script_category}
                              onChange={onChangeAssignTo}
                              name="next_call_script_category"
                              className="form-select mb-3 col-6 border border-dark inside"
                              aria-label="Default select example"
                              required
                            >
                              <option>Select Script Category</option>
                              {feedOrgCategory &&
                                feedOrgCategory?.map((item) => (
                                  <option
                                    value={item.script_type_name}
                                  >
                                    {item.script_type_name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          
                        </div>
                      </>
                    }
                    method={updateAssignTo}
                    item={deleteSelect}
                  />
                </div>
              )}

              <div>
                {/* Button trigger modal */}

                

                
                <div
                  className="modal fade"
                  id="exampleModal2"
                  tabIndex={-1}
                  aria-labelledby="exampleModal2Label"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          <i className="bi bi-telephone-plus-fill"></i> Add
                          phone number to call.
                        </h5>
                        <button
                          id="close"
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Customer Name
                              </label>
                              <input
                                name="customer_name"
                                value={addCallData.customer_name}
                                onChange={changeCallData}
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Raju Sharma"
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Phone Number
                              </label>
                              <input
                                name="contact_number"
                                value={addCallData.contact_number}
                                onChange={changeCallData}
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="8149910885"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Email Id
                              </label>
                              <input
                                name="email_address"
                                value={addCallData.email_address}
                                onChange={changeCallData}
                                type="email"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="name@example.com"
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Assign to
                              </label>
                              <select
                                value={addCallData.next_call_user}
                                onChange={changeCallData}
                                name="next_call_user"
                                className="form-select mb-3 col-6 border border-white inside"
                                aria-label="Default select example"
                                required
                              >
                                <option>Select Person</option>
                                {feeduser &&
                                  feeduser?.map((item) => (
                                    <option
                                      value={item.id + "," + item.user_name}
                                    >
                                      {item.user_name}-{item.role}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Select Script
                              </label>
                              <select
                                value={addCallData.next_call_script_category}
                                onChange={changeCallData}
                                name="next_call_script_category"
                                className="form-select mb-3 col-6 border border-secondary inside"
                                aria-label="Default select example"
                                required
                              >
                                {feedOrgCategory &&
                                  feedOrgCategory?.map((item) => (
                                    <option value={item.script_type_name}>
                                      {item.script_type_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Schedule
                              </label>
                              <input
                                name="call_again_date"
                                value={addCallData.call_again_date}
                                onChange={changeCallData}
                                type="datetime-local"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="name@example.com"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <p
                              className="collapsed text-dark"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFive"
                              aria-expanded="false"
                              aria-controls="flush-collapseFive"
                            >
                              Do you have a source?
                              <i className="bi bi-caret-down-fill"></i>
                            </p>
                          </div>
                        </div>
                        <div
                          id="flush-collapseFive"
                          className="accordion-collapse collapse bg-transparent"
                          aria-labelledby="flush-headingFive"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="row" style={{ alignItems: "center" }}>
                            <div className="col-5">
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Select source
                                </label>
                                <select
                                  value={addCallData.Contact_Source}
                                  onChange={changeCallData}
                                  name="Contact_Source"
                                  className="form-select mb-3 col-6 border"
                                  aria-label="Default select example"
                                >
                                  <option>Select source</option>
                                  {sourceList &&
                                    sourceList?.map((item) => (
                                      <option value={item}>{item}</option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="mb-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Add new source
                                  </label>
                                  <input
                                    name="source"
                                    value={addsource}
                                    onChange={(e) =>
                                      setAddscource(e.target.value)
                                    }
                                    className="form-control"
                                    placeholder="Type new source"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-2"
                              style={{ marginTop: "30px" }}
                            >
                              <div className="mb-3">
                                <div className="mb-3">
                                  <button
                                    className="btn btn-light"
                                    disabled={!addsource}
                                    onClick={handleAddSource}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              Description
                            </label>
                            <textarea
                              value={addCallData.description}
                              onChange={changeCallData}
                              name="description"
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows={3}
                              defaultValue={""}
                            />
                          </div>
                        </div>

                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                          <button
                            className="btn btn-primary"
                            onClick={sendToCallData}
                          >
                            <i className="bi bi-arrow-right"></i> Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade show active p-3 mb-5 card_design border border-primary"
              id="today"
              role="tabpanel"
              aria-labelledby="today-tab"
            >
              <br />
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    <i className="bi bi-phone-fill"></i> Call Today
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-TaskToday-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-TaskToday"
                    type="button"
                    role="tab"
                    aria-controls="pills-TaskToday"
                    aria-selected="false"
                  >
                    <i className="bi bi-bicycle"></i> Task Today
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                 <p className="text-secondary">Filter Calls:</p>
                  <div className="row">
                    <div className="col-6 col-sm-3">
                      <select
                        name="script_category"
                        value={filterToday.script_category}
                        onChange={updateTodayFilter}
                        className="form-select border border-secondary inside"
                        aria-label="Default select example"
                      >
                        <option value='' selected>Choose Script Category</option>
                        {feedOrgCategory &&
                          feedOrgCategory?.map((item) => (
                            <option value={item.script_type_name}>
                              {item.script_type_name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="col-4 col-sm-3">
                      <button
                        type="button"
                        onClick={()=>fetchToday('1', today_date)}
                        className="btn btn-dark me-md-3 pop"
                      >
                        <i className="bi bi-arrow-right"></i> Submit
                      </button>
                      {" "}
                      <button
                        type="button"
                        onClick={clearFilter}
                        className="btn btn-danger me-md-3 pop"
                      >
                        <i className="bi bi-x"></i>
                      </button>
                      
                    </div>
                   
                   
                  </div>
                  <br/>

                  {today.data == ""?
                  <div align="center">
                       <img
                  src={emptytable}  height={350}
                />
                  </div> 
                  :<> <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              >
                <table className="table table-striped table-hover border-white caption-top">
                  <caption>Today Calls</caption>
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">
                        
                        <i className="bi bi-tags-fill"></i>
                      </th>
                      <th scope="col">
                        <i className="bi bi-person"></i> Name
                      </th>
                     
                      <th scope="col">
                        <i className="bi bi-telephone-forward"></i> Call Type
                      </th>
                      <th scope="col">
                      <i className="bi bi-person-badge-fill"></i> Assigned 
                      </th>
                      <th scope="col">
                        <i className="bi bi-telephone-plus"></i> Call Back
                      </th>
                      <th scope="col">
                        <i className="bi bi-menu-button-fill"></i> 
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {today.data &&
                      today.data?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.call_brief == null ? (
                              <span className="badge bg-dark">To Call</span>
                            ) : (
                              <span className="badge bg-info text-dark">
                                Call Back
                              </span>
                            )}
                          </td>
                          <td>
                            <b>{item.customer_name==""?<a
                                href={
                                  "callinfo?phoneno=" + item.contact_number
                                }
                                target="_blank"
                              >{item.contact_number}</a>:<a
                              href={
                                "callinfo?phoneno=" + item.contact_number
                              }
                              target="_blank"
                            >{item.customer_name}</a>
                      }
                      <OverlayTrigger trigger="click" placement="bottom" overlay={renderPopover('Contact No: '.concat(item.contact_number, ', Email: ', item.email_address))}>
                      <i className="bi bi-caret-down-fill"></i>
                              </OverlayTrigger></b>
                          </td>           
                          <td>{item.next_call_script_category}</td>
                          <td>{item.next_call_username}</td>
                          <td>
                            <b>{alterCallBack(item.call_again_date)}</b>
                          </td>

                          <td>
                          {
                          item.Contact_Source == "" || item.Contact_Source == null ? null : (
                            <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                              <i className="bi bi-bezier2"></i> 
                            </OverlayTrigger>
                            
                          )
                         }&nbsp;
                            <a
                              href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                              target="whatsapp"
                            >
                              <i className="bi bi-whatsapp"></i>
                            </a>&nbsp;
                            <Link
                              to={
                                `/call?nextcall=` +
                                item.next_call_script_category +
                                "&contactno=" +
                                item.contact_number +
                                "&email=" +
                                item.email_address +
                                "&customer=" +
                                item.customer_name +
                                tocallvalue(item) +
                                sourcevalue(item) +
                                "&callId=" +
                                item.Id
                              }
                            >
                              <i className="bi bi-telephone-fill"></i>
                            </Link>&nbsp;
                            <i
                              onClick={() =>
                                openNav(item.contact_number, item.id)
                              }
                              className="bi bi-geo-fill"
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                </div>
                
                
                
                
                <div className="container mt-3">
                    <nav
                className="d-grid gap-2 d-md-flex justify-content-md-end"
                aria-label="..."
              >
                
                {/* tocall cahnges */}
                <ul className="pagination">
                <li className="page-item">
                    <a className="btn btn-light me-md-2">
                      
                      Record Size: <b>{paginationSize}</b>
                    </a>
                  </li>

                  <li className="page-item">
                    <a className="btn btn-light">
                      
                      Total Pages: <b>{today.total_pages}</b>
                    </a>
                  </li>
                  {

today.current_page == "1" || today.current_page == "0"?<li className="page-item ">
<button 
  className="page-link bg-secondary text-white pop rounded" disabled
  style={{ cursor: "not-allowed" }}

>
  <i className="bi bi-arrow-left-circle" /> Previous
</button>
</li>:<li className="page-item ">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => fetchToday(today.previous_page, today_date)}
                    >
                      <i className="bi bi-arrow-left-circle" /> Previous
                    </a>
                  </li>

                  }
                  
                  <li className="page-item ">
                    <a className="page-link text-dark border-0 bg-transparent">
                      <b>{today.current_page}</b>
                    </a>
                  </li>

{
  today.current_page == today.total_pages || today.current_page == '0'?<li className="page-item ">
  <button
    className="page-link bg-secondary text-white pop rounded" disabled
    style={{ cursor: "not-allowed" }}
  >
    Next <i className="bi bi-arrow-right-circle" />
  </button>
</li>:<li className="page-item ">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => fetchToday(today.next_page, today_date)}
                    >
                      Next <i className="bi bi-arrow-right-circle" />
                    </a>
                  </li>

}

                  
                </ul>
              </nav>
                    </div>  



                

                
                
                
                
                </>}
               
                  
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-TaskToday"
                  role="tabpanel"
                  aria-labelledby="pills-TaskToday-tab"
                >

<p className="text-secondary">Filter Calls:</p>
                  <div className="row">
                    <div className="col-3">
                      <select
                        name="task_type"
                        value={filterTask.task_type}
                        onChange={updateTaskType}
                        className="form-select border border-secondary inside"
                        aria-label="Default select example"
                      >
                        <option value="Assigned" selected>
                          Assigned
                        </option>
                        <option value="InProgress" selected>
                          In Progress
                        </option>
                        <option value="Canceled" selected>
                          Canceled
                        </option>
                      </select>
                    </div>

                    <div className="col-3">
                      <button
                        type="button"
                        onClick={tasktoday}
                        className="btn btn-dark me-md-3 pop"
                      >
                        <i className="bi bi-arrow-right"></i> Submit
                      </button>
                      <button
                        type="button"
                        onClick={clearFilter}
                        className="btn btn-danger pop"
                      >
                        <i className="bi bi-x"></i>
                      </button>
                    </div>
                  </div>
                  <br/>

               {task ==null?<div align="center">
                       <img
                  src={emptycall}  height={350}
                />
                  </div>:<>  

                  <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                    <caption>Tasks</caption>
                    <thead className="table-dark">
                      <tr>
                        <th scope="col">
                          
                          <i className="bi bi-tags-fill"></i>
                        </th>
                        <th scope="col">
                          <i className="bi bi-bicycle"></i> Task
                        </th>
                        <th scope="col">
                          <i className="bi bi-phone"></i> Contact
                        </th>
                        <th scope="col">
                          <i className="bi bi-person-circle"></i> Added
                        </th>
                        <th scope="col">
                          <i className="bi bi-calendar2-check"></i> Due Date
                        </th>
                        <th scope="col">
                          <i className="bi bi-calendar4-event"></i> Date
                        </th>
                        <th scope="col">
                          <i className="bi bi-menu-button-fill"></i>
                        </th>
                        <th scope="col">
                         
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {task &&
                        task?.map((item, index) => (
                          <tr key={index}>
                           <td>
                          {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                        </td>
                            <td>
                              <b>{item.taskHeading}</b>
                            </td>
                            <td>
                              <b>{item.customer_name==""?<a
                                  href={
                                    "callinfo?phoneno=" + item.contact_number
                                  }
                                  target="_blank"
                                >{item.contact_number}</a>:<a
                                href={
                                  "callinfo?phoneno=" + item.contact_number
                                }
                                target="_blank"
                              >{item.customer_name}</a>
                        }
                        {returncontact(item)}</b>
                            </td>
                            
                            <td>{item.added_by_username}</td>
                            <td>
                              <b>{alterCallBack(item.lastDate)}</b>
                            </td>
                            <td>{alterElement(item)}</td>
                            <td>
                            {
                            item.Contact_Source == "" || item.Contact_Source == null ? null : (
                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                                <i className="bi bi-bezier2"></i> 
                              </OverlayTrigger>
                              
                            )
                           }&nbsp;
                              <a
                                href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                                target="whatsapp"
                              >
                                <i className="bi bi-whatsapp"></i>
                              </a>
                              <i
                                onClick={() =>
                                  openNav(item.contact_number, item._id)
                                }
                                className="bi bi-geo-fill"
                              />
                              <div
                                className="modal fade"
                                id={"modalToggle" + item._id}
                                aria-hidden="true"
                                aria-labelledby="exampleModalToggleLabel"
                                tabIndex={-1}
                              >
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header border-0">
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      />
                                    </div>
                                    <div className="modal-body">
                                      <div className="row bg-transparent">
                                        <div className="mb-1 col-12 bg-transparent">
                                          <label
                                            for="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            <i className="bi bi-file-earmark-text-fill" />
                                            Change Status:
                                          </label>

                                          <input
                                            name="taskID"
                                            type="hidden"
                                            value={editTask.taskID}
                                            onChange={editTaskData}
                                          ></input>

                                          <select
                                            name="taskStatus"
                                            value={editTask.taskStatus}
                                            onChange={editTaskData}
                                            className="form-select mb-3 col-6 border border-success"
                                            aria-label="Default select example"
                                            required
                                          >
                                            <option selected>
                                              Select Task Status
                                            </option>
                                            <option value="Assigned">
                                              Assigned
                                            </option>
                                            <option value="In Progress">
                                              In Progress
                                            </option>
                                            <option value="Canceled">
                                             Canceled  
                                            </option>
                                            <option
                                              className="bg-success text-white"
                                              value="Completed"
                                            >
                                              Completed
                                            </option>
                                          </select>
                                        </div>

                                        <div className="mb-1 col-12">
                                          <div className="mb-1">
                                            <label
                                              for="exampleFormControlTextarea1"
                                              className="form-label"
                                            >
                                              <i className="bi bi-file-text-fill" />
                                              Remarks
                                            </label>
                                            <textarea
                                              name="remark"
                                              value={editTask.remark}
                                              onChange={editTaskData}
                                              className="form-control border border-success"
                                              id="exampleFormControlTextarea1"
                                              rows="2"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="modal-footer border-0">
                                      <button
                                        onClick={updateTask}
                                        className="btn btn-dark"
                                        data-bs-toggle="modal"
                                        data-bs-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button
                                className="btn btn-transparent btn-sm"
                                onClick={() => setDefaultTask(item)}
                                data-bs-toggle="modal"
                                href={"#modalToggle" + item._id}
                                role="button"
                              >
                                <i className="bi bi-pencil-fill"></i>
                              </button>
                              
                            </td>
                            <td><button
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={"#flush-" + item._id}
                                className="btn btn-transparent btn-sm"
                              >
                                
                                <i className="bi bi-caret-down-fill"></i>
                              </button>
                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item._id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div></td>
                          </tr>
                        ))}
                    </tbody>
                  </table></div></> }

                
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade p-3 mb-5 card_design border border-danger"
              id="pending"
              role="tabpanel"
              aria-labelledby="pending-tab"
            >
              <br />
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-call-pending-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-call-pending"
                    type="button"
                    role="tab"
                    aria-controls="pills-call-pending"
                    aria-selected="true"
                  >
                    <i className="bi bi-phone-fill"></i> Call Pending
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-TaskPending-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-TaskPending"
                    type="button"
                    role="tab"
                    aria-controls="pills-TaskToday"
                    aria-selected="false"
                  >
                    <i className="bi bi-bicycle"></i> Task Pending
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-call-pending"
                  role="tabpanel"
                  aria-labelledby="pills-call-pending-tab"
                >
                  {
                    pendingcall ==""? <div align="center">
                    <img
               src={emptytable}  height={350}
             />
               </div> : <>
               <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                    <caption>Pending Calls</caption>
                    <thead className="table-dark">
                      <tr>
                        <th scope="col">
                          
                          <i className="bi bi-tags-fill"></i>
                        </th>
                        <th scope="col">
                          <i className="bi bi-person"></i> Name
                        </th>
                        <th scope="col">
                          <i className="bi bi-phone-vibrate"></i> Call Brief
                        </th>
                        <th scope="col">
                          <i className="bi bi-compass"></i> Call Status
                        </th>
                       
                        <th scope="col">
                          <i className="bi bi-telephone-forward"></i> Call Type
                        </th>
                        <th scope="col">
                        <i className="bi bi-person-badge-fill"></i> Assigned
                        </th>
                        <th scope="col">
                          <i className="bi bi-telephone-plus"></i> Call Back
                        </th>

                        <th scope="col">
                          <i className="bi bi-menu-button-fill"></i> 
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendingcall &&
                        pendingcall?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              {item.call_brief == null ? (
                                <span className="badge bg-dark">To Call</span>
                              ) : (
                                <span className="badge bg-info text-dark">
                                  Call Back
                                </span>
                              )}
                            </td>
                            <td>
                              <b>
                              {item.customer_name==""?<a
                                  href={
                                    "callinfo?phoneno=" + item.contact_number
                                  }
                                  target="_blank"
                                >{item.contact_number}</a>:<a
                                href={
                                  "callinfo?phoneno=" + item.contact_number
                                }
                                target="_blank"
                              >{item.customer_name}</a>
                        }  {returncontact(item)}
                              </b>
                            </td>
                    
                            <td>{changeElement(item)}</td>
                            <td>{progressElement(item)}</td>
                            <td>{item.next_call_script_category}</td>
                            <td>{item.next_call_username}</td>
                            
                            <td>
                              <b>{alterCallBack(item.call_again_date)}</b>
                            </td>
                            <td>
                            {
                            item.Contact_Source == "" || item.Contact_Source == null ? null : (
                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                                <i className="bi bi-bezier2"></i> 
                              </OverlayTrigger>
                              
                            )
                           }&nbsp;
                              <a
                                href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                                target="whatsapp"
                              >
                                <i className="bi bi-whatsapp"></i>
                              </a>&nbsp;
                              <i
                                data-bs-target="#exampleModal2"
                                className="bi bi-pencil-fill"
                              ></i>&nbsp;
                              <Link
                                to={
                                  `/call?nextcall=` +
                                  item.next_call_script_category +
                                  "&contactno=" +
                                  item.contact_number +
                                  "&email=" +
                                  item.email_address +
                                  "&customer=" +
                                  item.customer_name +
                                  tocallvalue(item) +
                                  sourcevalue(item) +
                                  "&callId=" +
                                  item.id
                                }
                              >
                                <i className="bi bi-telephone-fill"></i>
                              </Link>&nbsp;
                              <i
                                onClick={() =>
                                  openNav(item.contact_number, item.id)
                                }
                                className="bi bi-geo-fill"
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table></div>
               </>
                  }
                
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-TaskPending"
                  role="tabpanel"
                  aria-labelledby="pills-TaskPending-tab"
                >

<p className="text-secondary">Filter Calls:</p>
                  <div className="row">
                    <div className="col-3">
                      <select
                        name="task_type"
                        value={filterTask.task_type}
                        onChange={updateTaskType}
                        className="form-select border border-secondary inside"
                        aria-label="Default select example"
                      >
                        <option value="Assigned" selected>
                          Assigned
                        </option>
                        <option value="InProgress" selected>
                          In Progress
                        </option>
                        <option value="Canceled" selected>
                          Canceled
                        </option>
                      </select>
                    </div>

                    <div className="col-3">
                      <button
                        type="button"
                        onClick={taskpending}
                        className="btn btn-dark me-md-3 pop"
                      >
                        <i className="bi bi-arrow-right"></i> Submit
                      </button>
                      <button
                        type="button"
                        onClick={clearFilter}
                        className="btn btn-danger pop"
                      >
                        <i className="bi bi-x"></i>
                      </button>
                    </div>
                  </div>
                  <br/>

                  {task ==null?<div align="center">
                       <img
                  src={emptycall}  height={350}
                />
                  </div>:<>
                  

                  <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                    <caption>Tasks</caption>
                    <thead className="table-dark">
                      <tr>
                        <th scope="col">
                          
                          <i className="bi bi-tags-fill"></i>
                        </th>
                        <th scope="col">
                          <i className="bi bi-bicycle"></i> Task
                        </th>
                        <th scope="col">
                          <i className="bi bi-person"></i> Name
                        </th>
                       
                        <th scope="col">
                          <i className="bi bi-person-circle"></i> Added
                        </th>
                        <th scope="col">
                          <i className="bi bi-calendar2-check"></i> Due Date
                        </th>
                        <th scope="col">
                          <i className="bi bi-menu-button-fill"></i>
                        </th>
                        <th scope="col">
                         
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {task &&
                        task?.map((item, index) => (
                          <tr key={index}>
                            <td>
                          {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                        </td>
                            <td>
                              <b>{item.taskHeading}</b>
                            </td>
                            <td>
                              <b>
                              {item.customer_name==""?<a
                                  href={
                                    "callinfo?phoneno=" + item.contact_number
                                  }
                                  target="_blank"
                                >{item.contact_number}</a>:<a
                                href={
                                  "callinfo?phoneno=" + item.contact_number
                                }
                                target="_blank"
                              >{item.customer_name}</a>
                        }  {returncontact(item)}
                              </b>
                            </td>
                            
                            <td>{item.added_by_username}</td>
                            <td>
                              <b>{alterCallBack(item.lastDate)}</b>
                            </td>
                            <td>{
                            item.Contact_Source == "" || item.Contact_Source == null ? null : (
                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                                <i className="bi bi-bezier2"></i> 
                              </OverlayTrigger>
                              
                            )
                           }&nbsp;
                              <a
                                href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                                target="whatsapp"
                              >
                                <i className="bi bi-whatsapp"></i>
                              </a>
                              <i
                                onClick={() =>
                                  openNav(item.contact_number, item._id)
                                }
                                className="bi bi-geo-fill"
                              />
                              <div
                                className="modal fade"
                                id={"modalToggle2" + item._id}
                                aria-hidden="true"
                                aria-labelledby="exampleModalToggleLabel"
                                tabIndex={-1}
                              >
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header border-0">
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      />
                                    </div>
                                    <div className="modal-body">
                                      <div className="row bg-transparent">
                                        <div className="mb-1 col-12 bg-transparent">
                                          <label
                                            for="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            <i className="bi bi-file-earmark-text-fill" />
                                            Change Status:
                                          </label>

                                          <input
                                            name="taskID"
                                            type="hidden"
                                            value={editTask.taskID}
                                            onChange={editTaskData}
                                          ></input>

                                          <select
                                            name="taskStatus"
                                            value={editTask.taskStatus}
                                            onChange={editTaskData}
                                            className="form-select mb-3 col-6 border border-success"
                                            aria-label="Default select example"
                                            required
                                          >
                                            <option selected>
                                              Select Task Status
                                            </option>
                                            <option value="Assigned">
                                              Assigned
                                            </option>
                                            <option value="In Progress">
                                              In Progress
                                            </option>
                                            <option value="Canceled">
                                            Canceled 
                                            </option>
                                            <option
                                              className="bg-success text-white"
                                              value="Completed"
                                            >
                                              Completed
                                            </option>
                                          </select>
                                        </div>

                                        <div className="mb-1 col-12">
                                          <div className="mb-1">
                                            <label
                                              for="exampleFormControlTextarea1"
                                              className="form-label"
                                            >
                                              <i className="bi bi-file-text-fill" />
                                              Remarks
                                            </label>
                                            <textarea
                                              name="remark"
                                              value={editTask.remark}
                                              onChange={editTaskData}
                                              className="form-control border border-success"
                                              id="exampleFormControlTextarea1"
                                              rows="2"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="modal-footer border-0">
                                      <button
                                        onClick={updateTask}
                                        className="btn btn-dark"
                                        data-bs-toggle="modal"
                                        data-bs-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button
                                className="btn btn-transparent btn-sm"
                                onClick={() => setDefaultTask(item)}
                                data-bs-toggle="modal"
                                href={"#modalToggle2" + item._id}
                                role="button"
                              >
                                <i className="bi bi-pencil-fill"></i>
                              </button>
                              
                            </td>
                            <td><button
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={"#flush-" + item._id}
                                className="btn btn-transparent btn-sm"
                              >
                                
                                <i className="bi bi-caret-down-fill"></i>
                              </button>
                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item._id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div></td>
                          </tr>
                        ))}
                    </tbody>
                  </table></div>
                  </>}

                </div>
              </div>
            </div>
            <div
              className="tab-pane fade p-3 mb-5 card_design border border-secondary"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <p className="text-secondary">Filter Calls:</p>
              <form onSubmit={followup2}>
                <div className="row">
                  <div className="col-2">
                    <select
                      value={filterData.call_brief}
                      onChange={updataFilterData}
                      name="call_brief"
                      className="form-select border border-secondary inside"
                      aria-label="Default select example"
                    >
                      <option selected>Select Call Brief</option>
                      <option value="Cut Call">Cut Call</option>
                      <option value="Didn't Pick">Didn't Pick</option>
                      <option value="Call Later">Call Later</option>
                      <option value="Not Valid">Not Valid</option>
                      <option value="Interested">Interested</option>
                      <option value="Not Interested">Not Interested</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <select
                      value={filterData.call_status}
                      onChange={updataFilterData}
                      name="call_status"
                      className="form-select border border-secondary inside"
                      aria-label="Default select example"
                    >
                      <option selected>Select Call Status</option>
                      <option value="None">None</option>
                      <option value="Salute %26 Confirmation">
                        Salute & Confirmation
                      </option>
                      <option value="Pitch and Information">
                        Pitch and Information
                      </option>
                      <option value="Ask Question %26 Talk">
                        Ask Question & Talk
                      </option>
                      <option value="Objection Handling">
                        Objection Handling
                      </option>
                      <option value="Closing">Call Closing</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <select
                      value={filterData.arrange}
                      onChange={updataFilterData}
                      name="arrange"
                      className="form-select border border-secondary inside"
                      aria-label="Default select example"
                    >
                      <option value="0">New to Old</option>
                      <option value="1">Old to New</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <button type="submit" className="btn btn-dark me-md-3 pop">
                      <i className="bi bi-arrow-right"></i> Submit
                    </button>
                    <button
                      type="button"
                      onClick={clearFilter}
                      className="btn btn-danger pop"
                    >
                      <i className="bi bi-x"></i>
                    </button>
                  </div>
                </div>
              </form>
              <br />
              <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                <caption>Call Backs</caption>
                <thead className="table-dark">
                  <tr>
                    <th scope="col">
                      <i className="bi bi-person"></i> Name
                    </th>
                    <th scope="col">
                      <i className="bi bi-phone-vibrate"></i> Call Brief
                    </th>
                    <th scope="col">
                      <i className="bi bi-compass"></i> Call Status
                    </th>
                    <th scope="col">
                      <i className="bi bi-telephone-forward"></i> Call Type
                    </th>
                    <th scope="col">
                    <i className="bi bi-person-badge-fill"></i> Assigned
                    </th>
                    <th scope="col">
                      <i className="bi bi-telephone-plus"></i> Call Back
                    </th>
                    <th scope="col">
                      <i className="bi bi-calendar4-event"></i> Date
                    </th>
                    <th scope="col">
                      <i className="bi bi-menu-button-fill"></i> 
                    </th>
                    <th scope="col">
                      </th>
                  </tr>
                </thead>
                <tbody>
                  {call2 &&
                    call2?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.customer_name=="" || item.customer_name==null?item.contact_number:item.customer_name
                        }
                        {returncontact(item)}
                        </td>
                        
                        <td>{changeElement(item)}</td>
                        <td>{progressElement(item)}</td>
                        
                        <td>{item.next_call_script_category}</td>
                        <td>{item.next_call_username}</td>
                        <td>
                          <b>{alterCallBack(item.call_again_date)}</b>
                        </td>
                        <td>{alterElement(item)}</td>
                        <td>
                          <a
                            href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                            target="whatsapp"
                          >
                            <i className="bi bi-whatsapp"></i>
                          </a>&nbsp;
                          <Link
                            to={
                              `/call?nextcall=` +
                              item.next_call_script_category +
                              "&contactno=" +
                              item.contact_number +
                              "&email=" +
                              item.email_address +
                              "&customer=" +
                              item.customer_name +
                              "&calltype=callback" +
                              sourcevalue(item) +
                              "&callId=" +
                              item.id
                            }
                          >
                            <i className="bi bi-telephone-fill"></i>
                          </Link>&nbsp;
                          <i
                            onClick={() =>
                              openNav(item.contact_number, item.id)
                            }
                            className="bi bi-geo-fill"
                          />
                          
                        </td>
                        <td><button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#flush-" + item.id}
                            className="btn btn-transparent btn-sm"
                          >
                            
                            <i className="bi bi-caret-down-fill"></i>
                          </button>
                          <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            <div className="accordion-item bg-transparent">
                              <div
                                id={"flush-" + item.id}
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  {item.description}
                                </div>
                              </div>
                            </div>
                          </div></td>
                      </tr>
                    ))}
                </tbody>
              </table></div>
              <nav
                className="d-grid gap-2 d-md-flex justify-content-md-end"
                aria-label="..."
              >
                <ul className="pagination">
                  <li className="page-item">
                    <a className="btn btn-light">
                      
                      Total Pages: <b>{call2_tp}</b>
                    </a>
                  </li>
                  {

call2_cp == "1" || call2_cp == "0"?<li className="page-item ">
<button 
  className="page-link bg-secondary text-white pop rounded" disabled
  style={{ cursor: "not-allowed" }}
  onClick={() => prePage_call2(call2_pre)}
>
  <i className="bi bi-arrow-left-circle" /> Previous
</button>
</li>:<li className="page-item ">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => prePage_call2(call2_pre)}
                    >
                      <i className="bi bi-arrow-left-circle" /> Previous
                    </a>
                  </li>

                  }
                  
                  <li className="page-item ">
                    <a className="page-link text-dark border-0 bg-transparent">
                      <b>{call2_cp}</b>
                    </a>
                  </li>

{
  call2_tp == call2_cp || call2_tp == '0'?<li className="page-item ">
  <button
    className="page-link bg-secondary text-white pop rounded" disabled
    style={{ cursor: "not-allowed" }}
    onClick={() => nextPage_call2(call2_next)}
  >
    Next <i className="bi bi-arrow-right-circle" />
  </button>
</li>:<li className="page-item ">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => nextPage_call2(call2_next)}
                    >
                      Next <i className="bi bi-arrow-right-circle" />
                    </a>
                  </li>

}

                  
                </ul>
              </nav>
            </div>
            <div
              className="tab-pane fade p-3 mb-5 card_design border border-secondary"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <p className="text-secondary">Filter Calls:</p>
              <form onSubmit={filter2}>
                <div className="row">
                  <div className="col-2">
                    <select
                      value={filterData.call_brief}
                      onChange={updataFilterData}
                      name="call_brief"
                      className="form-select border border-secondary inside"
                      aria-label="Default select example"
                    >
                      <option selected>Select Call Brief</option>
                      <option value="Cut Call">Cut Call</option>
                      <option value="Didn't Pick">Didn't Pick</option>
                      <option value="Call Later">Call Later</option>
                      <option value="Not Valid">Not Valid</option>
                      <option value="Interested">Interested</option>
                      <option value="Not Interested">Not Interested</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <select
                      value={filterData.call_status}
                      onChange={updataFilterData}
                      name="call_status"
                      className="form-select border border-secondary inside"
                      aria-label="Default select example"
                    >
                      <option selected>Select Call Status</option>
                      <option value="None">None</option>
                      <option value="Salute %26 Confirmation">
                        Salute & Confirmation
                      </option>
                      <option value="Pitch and Information">
                        Pitch and Information
                      </option>
                      <option value="Ask Question %26 Talk">
                        Ask Question & Talk
                      </option>
                      <option value="Objection Handling">
                        Objection Handling
                      </option>
                      <option value="Closing">Call Closing</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <select
                      value={filterData.arrange}
                      onChange={updataFilterData}
                      name="arrange"
                      className="form-select border border-secondary inside"
                      aria-label="Default select example"
                    >
                      <option value="0">New to Old</option>
                      <option value="1">Old to New</option>
                    </select>
                  </div>
                  <div className="col-6">
                    <button type="submit" className="btn btn-dark me-md-3 pop">
                      <i className="bi bi-arrow-right"></i> Submit
                    </button>
                    <div
                      className="modal fade"
                      id="exampleModal3"
                      tabIndex={-1}
                      aria-labelledby="exampleModal3Label"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header border-0">
                            <h5 className="modal-title" id="exampleModal3Label">
                              Download Contact Number
                            </h5>
                            <button
                              id="close1"
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            />
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-12">
                                <div className="input-group">
                                  Start Date : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <input
                                    type="date"
                                    className="form-control border-secondary"
                                    placeholder="Start Date"
                                    name="staring_date"
                                    value={downloadContact.staring_date}
                                    onChange={handleChangeDownloadContact}
                                  />
                                </div>
                                <br />
                                <div className="input-group">
                                  End Date :
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <input
                                    type="date"
                                    className="form-control border-secondary"
                                    placeholder="End Date"
                                    value={downloadContact.ending_date}
                                    onChange={handleChangeDownloadContact}
                                    name="ending_date"
                                  />
                                </div>
                                <br />
                                <div className="input-group">
                                  Type :
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;
                                  <select
                                    value={downloadContact.filetype}
                                    onChange={handleChangeDownloadContact}
                                    name="filetype"
                                    className="form-select bg-light text-black border border-dark"
                                    id="inputGroupSelect04"
                                    aria-label="Example select with button addon"
                                  >
                                    <option value="xlsx" selected>
                                      Excel
                                    </option>
                                    <option value="csv">CSV</option>
                                  </select>
                                </div>

                                <br />

                                <button className="btn bg-dark text-white pop" onClick={handleDownload}>
                                  Download
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={clearFilter}
                      className="btn btn-danger pop"
                    >
                      <i className="bi bi-x"></i>
                    </button>
                    <button
                      className="btn btn-success pop bg-gradient"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal3"
                      style={{ marginLeft: "16px" }}
                    >
                      <i className="bi bi-file-earmark-spreadsheet"></i> Export
                    </button>
                  </div>
                </div>
              </form>
              <br />
              <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                <caption>Call History</caption>
                <thead className="table-dark">
                  <tr>
                    <th scope="col">                    
                        <i className="bi bi-tags-fill"> </i>Tags
                    </th>
                    <th scope="col">
                      <i className="bi bi-person"></i> Name
                    </th>

                    <th scope="col">
                      <i className="bi bi-phone-vibrate"></i> Brief
                    </th>
                    <th scope="col-1">
                      <i className="bi bi-compass"></i> Status
                    </th>
                    <th scope="col">
                      <i className="bi bi-file-earmark-text" /> Type
                    </th>
                    <th scope="col">
                    <i className="bi bi-person-badge-fill"></i> Added
                    </th>
                    <th scope="col">
                      <i className="bi bi-calendar4-event"></i> Date
                    </th>
                    <th scope="col">
                      <i className="bi bi-menu-button-fill"></i> 
                    </th>
                    <th scope="col">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {call1 &&
                    call1?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <b>
                            {item.call_type == "Incoming" ? (
                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Inbound')}>
                                 <i
                                className="bi bi-telephone-inbound-fill text-primary"
                                title="Inbound"
                              ></i>
                                </OverlayTrigger>
                               
                            ) : (
                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Outbound')}>
                              <i
                                className="bi bi-telephone-outbound-fill text-dark"
                                title="Outbound"
                              ></i>
                              </OverlayTrigger>
                            )}
                          &nbsp;
                           {
                            item.Contact_Source == "" || item.Contact_Source == null ? null : (
                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                                <i className="bi bi-bezier2"></i> 
                              </OverlayTrigger>
                              
                            )
                           }
                          &nbsp;
                           {
                            item.call_again_date == "" || item.call_again_date == null ? null : (

                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Call Category: '.concat(item.next_call_script_category, ', Time: ', item.call_again_date, ', Assigned: ', item.next_call_username ))}>
                                <i className="bi bi-telephone-plus-fill"></i> 
                              </OverlayTrigger>

                             
                            )
                           }
                           &nbsp;
                           {changeFace(item.clint_mood)}
                           &nbsp;
                           {
                            item.call_type != null && item.script_category == null? (
                              <i data-bs-toggle="modal" onClick={()=>setupeditdata(item)} data-bs-target="#editcalldata" className="bi bi-question-circle-fill text-danger"></i>
                            ):null
                           }

                          
                           

                            
                          </b>
                        </td>
                        <td>
                          {item.customer_name=="" || item.customer_name==null?item.contact_number:item.customer_name
                        }
                        {returncontact(item)}
                        </td>
                        {/* <td>
                          <a
                            href={"callinfo?phoneno=" + item.contact_number}
                            target="_blank"
                          >
                            {item.contact_number}
                          </a>
                        </td> */}

                        <td>{changeElement(item)}</td>
                        <td>{progressElement(item)}</td>
                        <td>{item.script_category}</td>
                        <td>{item.added_by_username}</td>
                        <td>{alterElement(item)}</td>
                        <td>
                          
                          <a
                            href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                            target="whatsapp"
                          >
                            <i className="bi bi-whatsapp"></i>
                          </a>
                          <i
                            onClick={() =>
                              openNav(item.contact_number, item.id)
                            }
                            className="bi bi-geo-fill"
                          />
                          {changeRec(item)}

                          
                          <i
                            // onClick={() =>
                            //   handleDelete(
                            //     item.contact_number,
                            //     item.added_at,
                            //     org
                            //   )
                            // }
                            onClick={() => setdeleteItem(item)}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal10"
                            style={{ cursor: "pointer" }}
                            className="bi bi-trash-fill"
                          ></i>
                          <Alert
                            heading={"You are about to delete a record"}
                            description={
                              "This will delete call record from list. Are you sure?"
                            }
                            modelid={"exampleModal10"}
                            method={handleDelete}
                            item={deleteItem}
                          />
                          
                        </td>
                        <td><button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#flush-" + item.id}
                            className="btn btn-transparent btn-sm"
                          >
                            
                            <i className="bi bi-caret-down-fill"></i>
                          </button>
                          <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            <div className="accordion-item bg-transparent">
                              <div
                                id={"flush-" + item.id}
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  {item.description}
                                  
                                </div>
                              </div>
                            </div>
                          </div></td>
                      </tr>
                    ))}
                </tbody>
              </table></div>
              <nav
                className="d-grid gap-2 d-md-flex justify-content-md-end"
                aria-label="..."
              >
                <ul className="pagination">
                  <li className="page-item">
                    <a className="btn btn-light">
                      
                      Total Pages: <b>{call1_tp}</b>
                    </a>
                  </li>
                </ul>
                <ul className="pagination">
                  <li className="page-item">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => prePage_call1(call1_pre)}
                    >
                      <i className="bi bi-arrow-left-circle-fill" /> Previous
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link text-dark border-0 bg-transparent">
                      <b>{call1_cp}</b>
                    </a>
                  </li>
                  <li className="page-item">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => nextPage_call1(call1_next)}
                    >
                      Next <i className="bi bi-arrow-right-circle-fill" />
                    </a>
                  </li>
                </ul>
              </nav>
              <div>
       
        {/* Edit call Modal */}
        <div className="modal fade" id="editcalldata" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h5 className="modal-title" id="staticBackdropLabel">Edit call data</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
              <div className="row">
                          <div className="col-12">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-file-text-fill"></i> Select Script
                              </label>
                              <select
                                value={editcalldata.script_category}
                                onChange={updataEditCallData}
                                name="script_category"
                                className="form-select mb-3 col-6 border border-secondary inside"
                                aria-label="Default select example"
                                required
                              >
                                <option>Select Category</option>
                                {feedOrgCategory &&
                                  feedOrgCategory?.map((item) => (
                                    <option value={item.script_type_name}>
                                      {item.script_type_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-6">
                          <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-phone-vibrate-fill"></i> Select call Brief
                              </label>
                              <select
                                    value={editcalldata.call_brief}
                                    onChange={updataEditCallData}
                                    name="call_brief"
                                    className="form-select mb-3 border border-secondary inside"
                                    aria-label="Default select example"
                                    required
                                  >
                                    <option>Select Call Brief </option>
                                    <option value="In Valid">
                                      Invalid Number
                                    </option>
                                    <option value="Cut Call">Cut Call</option>
                                    <option value="Didn't Pick">
                                      Didn't Pick
                                    </option>
                                    <option value="Call Later">
                                      Call Later
                                    </option>
                                    <option value="Interested">
                                      Interested
                                    </option>
                                    <option value="Not Interested">
                                      Not Interested
                                    </option>
                                    <option value="Other">Other</option>
                                  </select>
                            </div>
                          </div>
                          <div className="col-6">
                          <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-compass-fill"></i> Select Call Status
                              </label>
                              <select
                                   value={editcalldata.call_status}
                                   onChange={updataEditCallData}
                                   name="call_status"
                                    className="form-select mb-3 col-6 border border-secondary inside"
                                    aria-label="Default select example"
                                    required
                                  >
                                    <option>Select Part</option>
                                    <option value="None">None</option>
                                    <option
                                      className="bg-primary text-white"
                                      value="Salute & Confirmation"
                                    >
                                      Salute & Confirmation
                                    </option>
                                    <option
                                      className="bg-danger text-white"
                                      value="Pitch and Information"
                                    >
                                      Pitch and Information
                                    </option>
                                    <option
                                      className="bg-warning text-dark"
                                      value="Ask Question & Talk"
                                    >
                                      Ask Question & Talk
                                    </option>
                                    <option
                                      className="bg-white text-dark"
                                      value="Objection Handling"
                                    >
                                      Objection Handling
                                    </option>
                                    <option
                                      className="bg-success text-white"
                                      value="Closing"
                                    >
                                      Call Closing
                                    </option>
                                  </select>
                            </div>
                          </div>
                          <p>How was the person?</p>
                              <div className="row" align='center'>
                                <div className="col-3">
                                  <input
                                    type="hidden"
                                    value={editcalldata.clint_mood == "intense"}
                                    name="clint_mood"
                                  />
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    value="intense"
                                    checked={editcalldata.clint_mood}
                                    onChange={updataEditCallData}
                                    name="clint_mood"
                                    id="clint_mood1"
                                    autoComplete="off"
                                    defaultChecked
                                  />
                                  <label htmlFor="clint_mood1">
                                    <h3 className="emojiChange">
                                      <i
                                        className="bi bi-emoji-angry-fill"
                                        title="Intense"
                                      ></i>
                                    </h3>
                                  </label>
                                </div>
                                <div className="col-3">
                                  <input
                                    type="hidden"
                                    value={editcalldata.clint_mood == "unhappy"}
                                    name="clint_mood"
                                  />
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    value="unhappy"
                                    checked={editcalldata.clint_mood}
                                    onChange={updataEditCallData}
                                    name="clint_mood"
                                    id="clint_mood2"
                                    autoComplete="off"
                                    defaultChecked
                                  />
                                  <label htmlFor="clint_mood2">
                                    <h3 className="emojiChange">
                                      <i
                                        className="bi bi-emoji-frown-fill"
                                        title="Unhappy"
                                      ></i>
                                    </h3>
                                  </label>
                                </div>
                                <div className="col-3">
                                  <input
                                    type="hidden"
                                    value={editcalldata.clint_mood == "normal"}
                                    name="clint_mood"
                                  />
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    value="normal"
                                    checked={editcalldata.clint_mood}
                                    onChange={updataEditCallData}
                                    name="clint_mood"
                                    id="clint_mood3"
                                    autoComplete="off"
                                    defaultChecked
                                  />
                                  <label htmlFor="clint_mood3">
                                    <h3 className="emojiChange">
                                      <i
                                        className="bi bi-emoji-smile-fill"
                                        title="Normal"
                                      ></i>
                                    </h3>
                                  </label>
                                </div>
                                <div className="col-3">
                                  <input
                                    type="hidden"
                                    value={editcalldata.clint_mood == "happy"}
                                    name="clint_mood"
                                  />
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    value="happy"
                                    checked={editcalldata.clint_mood}
                                    onChange={updataEditCallData}
                                    name="clint_mood"
                                    id="clint_mood4"
                                    autoComplete="off"
                                    defaultChecked
                                  />
                                  <label htmlFor="clint_mood4">
                                    <h3 className="emojiChange">
                                      <i
                                        className="bi bi-emoji-laughing-fill"
                                        title="Happy"
                                      ></i>
                                    </h3>
                                  </label>
                                </div>
                              </div>
                              <div className="col-12">
                              <div className="mb-1">
                                <label
                                  for="exampleFormControlTextarea1"
                                  className="form-label"
                                >
                                  <i className="bi bi-file-text-fill" />{" "}
                                  Description
                                </label>
                                <textarea
                                  value={editcalldata.description}
                                  onChange={updataEditCallData}
                                  name="description"
                                  className="form-control border border-white inside2"
                                  id="exampleFormControlTextarea1"
                                  rows="3"
                                ></textarea>
                              </div>
                              </div>
                        </div>
                
              </div>
              <div className="modal-footer border-0">
                <button type="button" className="btn btn-outline-dark pop" data-bs-dismiss="modal">Close</button>
                <button type="button" onClick={editCall} className="btn btn-dark pop">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
            </div>
            <div
              className="tab-pane fade p-3 mb-5 card_design border border-secondary"
              id="task"
              role="tabpanel"
              aria-labelledby="task-tab"
            >
              <p className="text-secondary">Filter Calls:</p>
              <div className="row">
                <div className="col-2">
                  <select
                    value={filterTaskUser.taskHeading}
                    onChange={updateTaskFilter}
                    name="taskHeading"
                    className="form-select border border-secondary inside"
                    aria-label="Default select example"
                  >
                    <option selected>Select Task</option>
                    {feedOrgTask &&
                      feedOrgTask?.map((item) => (
                        <option value={item.heading}>{item.heading}</option>
                      ))}
                  </select>
                </div>
                <div className="col-2">
                  <select
                    value={filterTaskUser.taskStatus}
                    onChange={updateTaskFilter}
                    name="taskStatus"
                    className="form-select border border-secondary inside"
                    aria-label="Default select example"
                  >
                    <option value="">Select Task Status</option>
                    <option value="Assigned">Assigned</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Canceled">Canceled</option>
                    <option className="bg-success text-white" value="Completed">
                      Completed
                    </option>
                  </select>
                </div>
                <div className="col-2">
                  <select
                    value={filterTaskUser.arrange}
                    onChange={updateTaskFilter}
                    name="arrange"
                    className="form-select border border-secondary inside"
                    aria-label="Default select example"
                  >
                    <option value="0">New to Old</option>
                    <option value="1">Old to New</option>
                  </select>
                </div>
                <div className="col-3">
                  <button
                    onClick={taskfilter}
                    className="btn btn-dark me-md-3 pop"
                  >
                    <i className="bi bi-arrow-right"></i> Submit
                  </button>
                  <button
                    type="button"
                    onClick={clearFilter}
                    className="btn btn-danger pop"
                  >
                    <i className="bi bi-x"></i>
                  </button>
                </div>
              </div>
              <br />
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button className="btn btn-transparent me-md-2" type="button">
                  Add Task:
                </button>
                <a
                  className="btn btn-dark"
                  data-bs-toggle="modal"
                  href="#modalToggleTask"
                  role="button"
                >
                  <i className="bi bi-plus-circle"></i> Single
                </a>
              </div>

             

              <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                <caption>Tasks</caption>
                <thead className="table-dark">
                  <tr>
                    <th scope="col">
                          
                          <i className="bi bi-tags-fill"></i>
                        </th>
                    <th scope="col">
                      <i className="bi bi-bicycle"></i> Task Heading
                    </th>
                    <th scope="col">
                      <i className="bi bi-person"></i> Name
                    </th>
                    <th scope="col">
                      <i className="bi bi-person-circle"></i> Added
                    </th>
                    <th scope="col">
                      <i className="bi bi-calendar2-check"></i> Due Date
                    </th>
                    <th scope="col">
                      <i className="bi bi-calendar4-event"></i> Date
                    </th>
                    <th scope="col">
                      <i className="bi bi-menu-button-fill"></i>
                    </th>
                    <th scope="col">
                      
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {task &&
                    task?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                        </td>
                        <td>
                          <b>{item.taskHeading}</b>
                        </td>
                        <td>
                        {item.customer_name=="" || item.customer_name==null?item.contact_number:item.customer_name
                        }
                        {returncontact(item)}
                        
                       
                        </td>
                        <td>{item.added_by_username}</td>
                        <td>
                          <b>{alterCallBack(item.lastDate)}</b>
                        </td>
                        <td>{alterElement(item)}</td>
                        <td>
                          <a
                            href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                            target="whatsapp"
                          >
                            <i className="bi bi-whatsapp"></i>
                          </a>
                          <i
                            onClick={() =>
                              openNav(item.contact_number, item._id)
                            }
                            className="bi bi-geo-fill"
                          />
                          <div
                            className="modal fade"
                            id={"modalToggle1" + item._id}
                            aria-hidden="true"
                            aria-labelledby="exampleModalToggleLabel"
                            tabIndex={-1}
                          >
                            <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                <div className="modal-header border-0">
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  />
                                </div>
                                <div className="modal-body">
                                  <div className="row bg-transparent">
                                    <div className="mb-1 col-12 bg-transparent">
                                      <label
                                        for="exampleFormControlInput1"
                                        className="form-label"
                                      >
                                        <i className="bi bi-file-earmark-text-fill" />
                                        Change Status:
                                      </label>

                                      <input
                                        name="taskID"
                                        type="hidden"
                                        value={editTask.taskID}
                                        onChange={editTaskData}
                                      ></input>

                                      <select
                                        name="taskStatus"
                                        value={editTask.taskStatus}
                                        onChange={editTaskData}
                                        className="form-select mb-3 col-6 border border-success"
                                        aria-label="Default select example"
                                        required
                                      >
                                        <option selected>
                                          Select Task Status
                                        </option>
                                        <option value="Assigned">
                                          Assigned
                                        </option>
                                        <option value="In Progress">
                                          In Progress
                                        </option>
                                        <option value="Canceled">
                                        Canceled
                                        </option>
                                        <option
                                          className="bg-success text-white"
                                          value="Completed"
                                        >
                                          Completed
                                        </option>
                                      </select>
                                    </div>

                                    <div className="mb-1 col-12">
                                      <div className="mb-1">
                                        <label
                                          for="exampleFormControlTextarea1"
                                          className="form-label"
                                        >
                                          <i className="bi bi-file-text-fill" />
                                          Remarks
                                        </label>
                                        <textarea
                                          name="remark"
                                          value={editTask.remark}
                                          onChange={editTaskData}
                                          className="form-control border border-success"
                                          id="exampleFormControlTextarea1"
                                          rows="2"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="modal-footer border-0">
                                  <button
                                    onClick={updateTask}
                                    className="btn btn-dark"
                                    data-bs-toggle="modal"
                                    data-bs-dismiss="modal"
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            className="btn btn-transparent btn-sm"
                            onClick={() => setDefaultTask(item)}
                            data-bs-toggle="modal"
                            href={"#modalToggle1" + item._id}
                            role="button"
                          >
                            <i className="bi bi-pencil-fill"></i>
                          </button>

                          {item.has_suppoting_file =='True'?
                          <> <i className="bi bi-folder-fill"  style={{ cursor: "pointer" }} data-bs-toggle="modal" onClick={()=>getSupportinDoc(item)} data-bs-target="#supportingFileModal">
                          
</i> </>
                         
                          :
                          <> <i className="bi bi-folder-plus"  style={{ cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#supportingFileModal">
                          
                          </i> </>

                          }
                          
                        </td>
                        <td><button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#flush-" + item._id}
                            className="btn btn-transparent btn-sm"
                          >
                            
                            <i className="bi bi-caret-down-fill"></i>
                          </button>
                          <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            <div className="accordion-item bg-transparent">
                              <div
                                id={"flush-" + item._id}
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  {item.description}
                                </div>
                              </div>
                            </div>
                          </div></td>
                      </tr>
                    ))}
                </tbody>
              </table></div>
              <nav
                className="d-grid gap-2 d-md-flex justify-content-md-end"
                aria-label="..."
              >
                <ul className="pagination">
                  <li className="page-item">
                    <a className="btn btn-light">
                      
                      Total Pages: <b>{task_tp}</b>
                    </a>
                  </li>
                  <li className="page-item ">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => page_task(task_pre)}
                    >
                      <i className="bi bi-arrow-left-circle" /> Previous
                    </a>
                  </li>
                  <li className="page-item ">
                    <a className="page-link text-dark border-0 bg-transparent">
                      <b>{task_cp}</b>
                    </a>
                  </li>
                  <li className="page-item ">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => page_task(task_next)}
                    >
                      Next <i className="bi bi-arrow-right-circle" />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div
              className="tab-pane fade"
              id="datahub"
              role="tabpanel"
              aria-labelledby="data-tab"
            >
              <br />
              <br />
              <img id="speak" src={cs} alt="" width="500" height="500"></img>
            </div>
            <div id="bottomAI">
              <button
                type="button"
                onClick={() => sunday1()}
                className="btn btn-info rounded-circle"
              >
                <h4>
                  <i className="bi bi-robot" />
                </h4>
              </button>
            </div>

            <div
              className="card w-25 chat-popup p-3 mb-5 border-0 shadow"
              id="sunday1"
            >
              <div className="card-body">
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <h3>
                    <i
                      onClick={() => sunday1_back()}
                      className="bi bi-x-circle-fill"
                    />
                  </h3>
                </div>
                <h5 className="card-title">
                  Sunday Suggestion <i className="bi bi-emoji-sunglasses"></i>
                </h5>
                {AdminSundayTemp()}
              </div>
            </div>

            <div className="card rec-popup p-2  border-0" id="rec">
            <span onClick={rec_back} className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ cursor: "pointer" }}>
            X
        <span className="visually-hidden"></span>
      </span>
              <audio controls ref={audioRef}>
                <source src={RecLink} />
                Your browser does not support the audio element.
              </audio>
            </div>

            {/* admin popups  */}
            <div
                className="modal fade"
                id="modalToggleTask"
                aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel"
                tabIndex={-1}
              >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                    <div className="modal-header border-0">
                    <h5 class="modal-title">Assign a task</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body">
                      <div className="row bg-transparent">
                        <div className="row">
                          <div className="col-5">
                            <br />
                            <br />
                            <br />
                            <img
                              src="https://salessunday-image.s3.ap-south-1.amazonaws.com/Pages-image/3.+Add+task.svg"
                              height={250}
                            />
                          </div>
                          <div className="col-7">
                            <div className="row">
                            <div className="col-10">
                            <label for="exampleFormControlInput1" class="form-label">Existing contact?</label>
                            <div className="input-group mb-3 input-group-sm">      
        <input type="text" name='tocall'  value={searchelement.tocall}
                                    onChange={updataSearchElement} className="form-control border-dark" placeholder="Recipient's Name/Number/Email" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button className="btn btn-dark" onClick={SearchHome} type="button" id="button-addon2"><i className="bi bi-search"></i></button>
      </div>
</div>
                            </div>
                            <div
                className="overflow-auto bg-light"
                style={{ maxWidth: "500px", maxHeight: "100px" }}
              >
                            <ul className="list-group">
                
              {personsearchresult &&
                          personsearchresult?.map((item) => (
                            <li onClick={()=>setperson(item,'task')} className="list-group-item cursor listhover"><i className="bi bi-person-circle"></i> {item.customer_name}   {masknumber(item.contact_number)}</li>
                          ))}
  
</ul></div>
                          <p align='center'>-or-</p>
                            
                            <div className="row">

                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    <i className="bi bi-person-circle"></i> Customer Name
                                  </label>
                                  <input
                                    name="customer_name"
                                    value={taskData.customer_name}
                                    onChange={changeTaskData}
                                    type="text"
                                    className="form-control mb-3 col-6 border border-secondary form-control-sm"
                                    id="exampleFormControlInput1"
                                    placeholder="Raju Sharma"
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    <i className="bi bi-phone-fill"></i> Phone
                                    Number
                                  </label>
                                  <input
                                    name="contact_number"
                                    value={taskData.contact_number}
                                    onChange={changeTaskData}
                                    className="form-control form-control-sm mb-3 col-6 border border-secondary"
                                    id="exampleFormControlInput1"
                                    placeholder="8149910885"
                                  />
                                </div>
                              </div>

                              <div className="mb-1 col-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    <i className="bi bi-phone-fill"></i> Email
                                  </label>
                                  <input
                                    name="email_address"
                                    value={taskData.email_address}
                                    onChange={changeTaskData}
                                    className="form-control form-control-sm mb-3 col-6 border border-secondary"
                                    id="exampleFormControlInput1"
                                    placeholder="user@gmail.com"
                                  />
                                </div>
                              </div>
                              <div className="mb-1 col-6 bg-transparent">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  <i className="bi bi-file-earmark-text-fill" /> Task Category:
                                </label>
                                <select
                                  name="taskHeading"
                                  value={taskData.taskHeading}
                                  onChange={changeTaskData}
                                  className="form-select form-select-sm mb-3 col-6 border border-secondary"
                                  aria-label="Default select example"
                                  required
                                >
                                  <option>Select Task</option>

                                  {feedOrgTask &&
                                    feedOrgTask?.map((item) => (
                                      <option value={item.heading}>
                                        {item.heading} ({item.description})
                                      </option>
                                    ))}
                                </select>
                              </div>

                              <div className="mb-1 col-6">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  <i className="bi bi-people-fill" /> Assign
                                  task to
                                </label>
                                <select
                                  name="assineTO_userID"
                                  value={taskData.assineTO_userID}
                                  onChange={changeTaskData}
                                  className="form-select form-select-sm mb-3 col-6 border border-secondary"
                                  aria-label="Default select example"
                                  required
                                >
                                  <option>Select Person</option>
                                  {feeduser &&
                                    feeduser?.map((item) => (
                                      <option
                                        value={item.id + "," + item.user_name}
                                        key={item.id}
                                      >
                                        {item.user_name}-{item.role}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="mb-1 col-6 bg-transparent">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  <i className="bi bi-alarm-fill" /> Due Date
                                </label>
                                <input
                                  type="datetime-local"
                                  name="lastDate"
                                  value={taskData.lastDate}
                                  onChange={changeTaskData}
                                  className="form-control form-control-sm border border-secondary"
                                  id="exampleFormControlInput1"
                                  placeholder="Call Back"
                                />
                              </div>
                            </div>

                            <div className="mb-1 col-12">
                              <div className="mb-1">
                                <label
                                  for="exampleFormControlTextarea1"
                                  className="form-label"
                                >
                                  <i className="bi bi-file-text-fill" /> Task
                                  Description
                                </label>
                                <textarea
                                  name="description"
                                  value={taskData.description}
                                  onChange={changeTaskData}
                                  className="form-control form-control-sm border border-secondary"
                                  id="exampleFormControlTextarea1"
                                  rows="2"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer border-0">
                      <button
                        className="btn btn-dark"
                        onClick={sendTask}
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ManageSouce
                modelid={"exampleModal15"}
                heading={""}
                description={
                  "This will delete call record from list. Are you sure?"
                }
                // method={delToCallData}
                // item={deleteItem}
              />


                {/* Modal */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  data-bs-backdrop="static"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header border-0">
                        <h5 className="modal-title">
                        Assign a
                          phone number. &nbsp;<OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('You have a lead or you want to call someone you can add here, deploy it and track it.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                        </h5>
                        <button
                          id="close"
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body">
                      <div className="row">
                      <div className="col-5">
                        <br/><br/>
                      <img
                              src={addsingle}
                              height={350}
                            />
                      </div>
                      <div className="col-7">
                      <div className="row">
                            <div className="col-10">
                            <label for="exampleFormControlInput1" class="form-label">Existing contact?</label>
                            <div className="input-group mb-3 input-group-sm">      
        <input type="text" name='tocall'  value={searchelement.tocall}
                                    onChange={updataSearchElement} className="form-control border-dark" placeholder="Recipient's Name/Number/Email" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button className="btn btn-dark" onClick={SearchHome} type="button" id="button-addon2"><i className="bi bi-search"></i></button>
      </div>
</div>
                            </div>
                            <div
                className="overflow-auto bg-light"
                style={{ maxWidth: "500px", maxHeight: "100px" }}
              >
                            <ul className="list-group">
                
              {personsearchresult &&
                          personsearchresult?.map((item) => (
                            <li onClick={()=>setperson(item,'call')} className="list-group-item cursor listhover"><i className="bi bi-person-circle"></i> {item.customer_name}   {masknumber(item.contact_number)}</li>
                          ))}
  
</ul></div>
                          <p align='center'>-or-</p>
           
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                              <i className="bi bi-person-circle"></i>  Customer Name
                              </label>
                              <input
                                name="customer_name"
                                value={addCallData.customer_name}
                                onChange={changeCallData}
                                type="text"
                                className="form-control form-control-sm border-dark"
                                id="exampleFormControlInput1"
                                placeholder="Raju Sharma"
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                              <i className="bi bi-telephone-fill"></i>  Phone Number
                              </label>
                              <input
                                name="contact_number"
                                value={addCallData.contact_number}
                                onChange={changeCallData}
                                type="text"
                                className="form-control form-control-sm border-dark"
                                id="exampleFormControlInput1"
                                placeholder="8149910885"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                              <i className="bi bi-envelope-fill"></i>  Email Id
                              </label>
                              <input
                                name="email_address"
                                value={addCallData.email_address}
                                onChange={changeCallData}
                                type="email"
                                className="form-control form-control-sm border-dark"
                                id="exampleFormControlInput1"
                                placeholder="name@example.com"
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-person-fill"></i> Assign to
                              </label>
                              <select
                                value={addCallData.next_call_user}
                                onChange={changeCallData}
                                name="next_call_user"
                                className="form-select form-select-sm mb-3 col-6 border border-dark"
                                aria-label="Default select example"
                                required
                              >
                                <option>Select Person</option>
                                {feeduser &&
                                  feeduser?.map((item) => (
                                    <option
                                      value={item.id + "," + item.user_name}
                                    >
                                      {item.user_name}-{item.role}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-file-text-fill"></i> Select Script
                              </label>
                              <select
                                value={addCallData.next_call_script_category}
                                onChange={changeCallData}
                                name="next_call_script_category"
                                className="form-select form-select-sm mb-3 col-6 border border-dark"
                                aria-label="Default select example"
                                required
                              >
                                <option>Select Category</option>
                                {feedOrgCategory &&
                                  feedOrgCategory?.map((item) => (
                                    <option value={item.script_type_name}>
                                      {item.script_type_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-alarm-fill"></i> Schedule
                              </label>
                              <input
                                name="call_again_date"
                                value={addCallData.call_again_date}
                                onChange={changeCallData}
                                type="datetime-local"
                                className="form-control form-control-sm border-dark"
                                id="exampleFormControlInput1"
                                placeholder="name@example.com"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <p
                              className="collapsed text-dark"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFive"
                              aria-expanded="false"
                              aria-controls="flush-collapseFive"
                            >
                              Do you have a source?
                              <i className="bi bi-caret-down-fill"></i>
                            </p>
                          </div>
                        </div>
                        <div
                          id="flush-collapseFive"
                          className="accordion-collapse collapse bg-transparent"
                          aria-labelledby="flush-headingFive"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="row" style={{ alignItems: "center" }}>
                            <div className="col-5">
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Select source
                                </label>
                                <select
                                  value={addCallData.Contact_Source}
                                  onChange={changeCallData}
                                  name="Contact_Source"
                                  className="form-select form-select-sm mb-3 col-6 border border-dark"
                                  aria-label="Default select example"
                                >
                                  <option>Select source</option>
                                  {sourceList &&
                                    sourceList?.map((item) => (
                                      <option value={item} selected>
                                        {item}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="mb-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Add new source
                                  </label>
                                  <input
                                    name="source"
                                    value={addsource}
                                    onChange={(e) =>
                                      setAddscource(e.target.value)
                                    }
                                    className="form-control form-control-sm border-dark"
                                    placeholder="Type new source"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-2"
                              style={{ marginTop: "30px" }}
                            >
                              <div className="mb-3">
                                <div className="mb-3">
                                  <button
                                    className="btn btn-light"
                                    disabled={!addsource}
                                    onClick={handleAddSource}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                             <i className="bi bi-card-text"></i> Description
                            </label>
                            <textarea
                              value={addCallData.description}
                              onChange={changeCallData}
                              name="description"
                              className="form-control form-control-sm border-dark"
                              id="exampleFormControlTextarea1"
                              rows={3}
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                          <button
                            className="btn btn-dark btn-sm"
                            onClick={sendToCallData}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* modal 2*/}

                <div
                  className="modal fade"
                  id="exampleModalmulti"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  data-bs-backdrop="static"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header border-0">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add data to call via Spreadsheet
                        </h5>
                        <button
                          id="close1"
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-5">
                            <img
                              src="https://salessunday-image.s3.ap-south-1.amazonaws.com/Pages-image/4.+Data+Upload.svg"
                              height={150}
                            />
                          </div>
                          <div className="col-7">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-1-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383h1.312Z"
                              />
                            </svg>&nbsp;
                            Download New Spreadsheet.
                            <b
                              style={{ cursor: "pointer" }}
                              onClick={() => getSpreedSheet()}
                            >
                              Click Here
                            </b>
                            <br />
                            <br />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-2-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z" />
                            </svg>&nbsp;
                            Add your Data accordion to instructions.
                            <br />
                            <br />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-3-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z" />
                            </svg>&nbsp;
                            Upload spreadsheet.
                            <br />
                            <br />
                            <input
                              type="file"
                              onChange={updataFile}
                              name="file"
                            />
                            <button
                              className="btn btn-dark btn-sm"
                              onClick={uploadSpreedSheet}
                              disabled={disabled}
                            >
                              {disabled ? (
                                <div className="d-flex align-items-center">
                                  <strong>Loading...</strong>
                                  <div
                                    className="spinner-border ms-auto"
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      marginLeft: "4px !important",
                                    }}
                                    role="status"
                                    aria-hidden="true"
                                  ></div>
                                </div>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              


            <div id="mySidenav" className="sidenav">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={closeNav}
              >
                ×
              </a>
              <div className="timeline">
                <div className="title">
                  <h2 className="text-white">Prospect Journey</h2>
                  <p className="text-white">(New to Old)</p>
                  <a href={"#" + trackId}>
                    <button
                      type="button"
                      className="btn btn-danger"
                      id="trackbtn"
                    >
                      <i className="bi bi-geo-fill" />
                    </button>
                  </a>
                </div>

                <div className="events">
                  {History &&
                    History?.map((item) =>
                      item.script_category != null ? (
                        <div className="event" id={item.id} key={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-light">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img 
                                    className="circle-pop2"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.script_category} </b>
                                  </h5>
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                  
                                  <span className="badge bg-light text-dark border-dark">
                                    {changeFace(item.clint_mood)}
                                    {" "}{item.clint_mood}
                                  </span>
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> called this
                                    number the call brief was
                                    <b>{" "}{item.call_brief}</b>. It went till
                                    <b>{" "}{item.call_status}</b>.
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-muted">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body ">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {progressElement(item)}
                              
                            </div>
                          </div>
                        </div>
                       ) :item.call_type != null ?
                       <div className="event" id={item.id}><div
                       className={trackId != item.id ? "knob" : "knob1"}
                     /> <div className="card bg-danger">
                     <div className="card-body">
                     <div className="row">
                                <div className="col-6">
                                  <img 
                                    className="rounded-circle"
                                    src={generateavatar(
                                      '?',
                                      "White",
                                      "#000"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {"Unkown Call"} </b>
                                  </h5>
                                </div>
                              </div>
                              <br/>
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary text-dark">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                     <p className="card-text">This call data is <b>Canceled.</b> Please complete the call details.</p>
                     <div className="row"><div className="col-12">
                                  <p align="right" className="card-text">
                                    <small className="text-dark">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div></div>
                      </div></div></div>
                       : (
                        <div className="event" id={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-info bg-gradient">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img 
                                    className="rounded-circle"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.taskHeading} </b>
                                  </h5>
                                </div>
                              </div>

                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> assigned
                                    <b>{" "}{item.taskHeading}</b> task to
                                    <b>{" "}{item.assineTO_userName}</b>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-white">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse bg-transparent"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body  bg-transparent">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      )
                    )}

                  <div className="line" />
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
          <div
            className="modal fade"
            id="audioModal"
            tabIndex={-1}
            aria-labelledby="audioModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-body">
                  <p>Upload Call Recording.</p>

                  <input
                    type="file"
                    onChange={updataAudioFile}
                    name="uploaded_file"
                  />

                  <button onClick={uploadAudio}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
    className="modal fade"
    id="supportingFileModal"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header border-0">
          <h5 className="modal-title" id="exampleModalLabel">
          <i className="bi bi-folder2-open"></i> Supporting File
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
        <p>Upload payment receipt/invoice/documents/CVs/forms/sheets etc.  </p>






        </div>
        <div className="modal-footer border-0">
        <div className="input-group">
  <input
    type="file"
    className="form-control"
    id="inputGroupFile04"
    aria-describedby="inputGroupFileAddon04"
    aria-label="Upload"
  />
  <button
    className="btn btn-dark"
    type="button"
    id="inputGroupFileAddon04"
  >
    Submit
  </button>
</div>

        </div>
      </div>
    </div>
  </div>
        <Offcanvas show={show} onHide={handleClose} className="scorebg">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-light"><i className="bi bi-phone-fill"></i> Call Analysis <span className="badge bg-danger">Beta</span></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="col-9">
          <div className="input-group input-group-sm mb-3">
  <input
    type="text"
    value={searchQuery}
    onChange={handleInputChange}
    className="form-control border-dark"
    placeholder="Search Keyword"
    aria-label="Recipient's username"
    aria-describedby="button-addon2"
  />
  {
      searchQuery.length  > 4? <button
      className="btn btn-dark border-dark"
      type="button"
      id="button-addon2"
      onClick={performSearch}
    >
      <i className="bi bi-search"></i>
    </button>: <button
    className="btn btn-light border-dark"
    type="button"
    id="button-addon2"
    onClick={performSearch}
    disabled>
    <i className="bi bi-search"></i>
  </button>
    }
 
</div>
          </div>

<div id="textDivision">

<div className="card bg-light">
  <div className="card-body">
  <i className="bi bi-clock-fill"></i> The duration of this call was <b className="text-primary">{convertToMinutes(transcription.call_duration)}.</b>
  </div>
</div>
       
<br/>
<div className="card_Summary">
  <div className="body_Summary">
  <span className="username_Summary"><i className="bi bi-balloon-fill"></i> Call Summary</span>
    <p className="text_Summary">
    {transcription.summary}
    </p>
   
  </div>
</div>


  <br/>

  <div className="card_Summary">
  <div className="body_Summary">
  <span className="username_Summary"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sign-stop-lights" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M6 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V4Zm2 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm1 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"/>
  <path fill-rule="evenodd" d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435Zm-1.4.7a.495.495 0 0 1 .7 0l6.516 6.515a.495.495 0 0 1 0 .7L8.35 14.866a.495.495 0 0 1-.7 0L1.134 8.35a.495.495 0 0 1 0-.7L7.65 1.134Z"/>
</svg>{" "}Objection</span>
    <p className="text_Summary">
    {transcription.objection}
    </p>
   
  </div>
</div>

<br/>

<div className="card_Summary">
<div className="body_Summary">
<span className="username_Summary"><i className="bi bi-robot"></i>{" "}Suggestion</span>
  <p className="text_Summary">
  {transcription.suggetion}
  </p>
 
</div>
</div>

<br/>

<div className="card_Summary">
<div className="body_Summary">
<span className="username_Summary"> <i className="bi bi-person-heart"></i>{" "}Sentiment</span>
  <p className="text_Summary">
  {transcription.sentiment}
  </p>
 
</div>
</div>

        <br/>
        <blockquote className="blockquote text-light">
  <p><i className="bi bi-file-text-fill"></i> Transcription.</p>
</blockquote>
<div className="text-light">
{transcription.transcription}
</div>
</div>
        
{/* <div className="card border-dark bg-light">
<div className="card-header">
<i className="bi bi-file-text-fill"></i> Transcription
  </div>
  <div className="card-body">
  <p className="mb-0">
            {transcription.transcription}
          </p> 
  </div>
  </div> */}
         


        </Offcanvas.Body>
      </Offcanvas>
      </>
    );
  } else {





    const updateSong = (source) => {
      setRecLink(source);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.load();
        audioRef.current.play();
      }
    };
  

   

    function getRec(item) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };
  
      axios
        .get(
          "/CallRecoding?".concat(
             "docucmet_id=",
            item.id
          ),
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            updateSong(data.data);
            rec();
          }
          setErrorMessage2(data.data.message);
        })
        .catch((err) => {
          console.log(err);
        });
    }


 

   
  
    function changeRec(item) {
      if (item.call_recoding == true) {
        return (
          <><button
            onClick={() => getRec(item)}
            className="border-0 bg-transparent"
          >
            <i className="bi bi-play-circle-fill"></i>
          </button>
        </>
        );
      } else if (item.call_recoding == false) {
        return (
          <button
            className="border-0 bg-transparent"
            data-bs-toggle="modal"
            data-bs-target="#audioModal"
          >
            <i className="bi bi-cloud-arrow-up"></i>
          </button>
        );
      } else {
        return (
          <button
          className="border-0 bg-transparent"
          data-bs-toggle="modal"
          data-bs-target="#audioModal"
        >
          <i className="bi bi-cloud-arrow-up"></i>
        </button>
        );
      }
    }
  
    let handleDelete = (item) => {
        let data = {
          organisation_id: org,
          contact_number: item.contact_number,
          added_at: item.added_at,
        };
  
        dispatch(
          deteleCallRecord({
            access_token,
            data: JSON.stringify(data),
          })
        ).then((res) => {
          if (res.payload.status == 200) {
            fetchData1();
          }
        });
      };


    function todayFilter() {
      const headers = {
        "Content-Type": "text/plain",
      };
      axios
        .get(
          "/user_callBack_todays?organisation_id=".concat(
            org,
            "&user_id=",
            user_id,
            "&script_category=",
            filterToday.script_category
          ),
          headers
        )
        .then((data) => {
          if (data.status == "200") {
            setToday(data.data);
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage);
        });
    }

    function fetchPendingCall() {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/usersCallDues?organisation_id=".concat(org, "&UserID=", user_id),
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            setPendingCall(data.data);
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage);
        });
    }

    function assignedTask() {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/userAssineTasks?organisationID=".concat(org, "&UserID=", user_id),
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            setTask(data.data.Task);
            setTask_pre(data.data.pagination.previous);
            setTask_next(data.data.pagination.next);
            setTask_tp(data.data.total_number_of_pages);
            setTask_cp(data.data.page_number);
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage);
        });
    }

    function createdTask() {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/userCreatedTasks?organisationID=".concat(org, "&UserID=", user_id),
          {headers}
        )
        .then((data) => {
          if (data.status == "200") {
            setTask(data.data.Task);
            setTask_pre(data.data.pagination.previous);
            setTask_next(data.data.pagination.next);
            setTask_tp(data.data.total_number_of_pages);
            setTask_cp(data.data.page_number);
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage);
        });
    }

    function fetchData1(e) {
      e.preventDefault();
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/get_users_all_contact?organisation_id=".concat(
            org,
            "&added_by_user=",
            user_id
          ),
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            setCall1(data.data.Call_records);
            setCall1_pre(data.data.pagination.previous);
            setCall1_next(data.data.pagination.next);
            setCall1_tp(data.data.total_number_of_pages);
            setCall1_cp(data.data.page_number);
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage);
        });
    }

    //////////////////////////////////////////////Follow up with filter///////////////////////////////////////////////////////

    ///////////////////////////////////Follow up ///////////////////////////////////////////////////////////

    function fetchData2(e) {
      e.preventDefault();
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/get_user_follow_contact?organisation_id=".concat(
            org,
            "&next_call_user=",
            user_id
          ),
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            setCall2(data.data.Call_records);
            setCall2(data.data.Call_records);
            setCall2_pre(data.data.pagination.previous);
            setCall2_next(data.data.pagination.next);
            setCall2_tp(data.data.total_number_of_pages);
            setCall2_cp(data.data.page_number);
          }
          setErrorMessage2(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage2);
        });
    }

    function followup2(e) {
      e.preventDefault();
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };

      if (filterData.call_brief == "") {
        axios
          .get(
            "/get_user_follow_contact?organisation_id=".concat(
              org,
              "&next_call_user=",
              user_id,
              "&call_status=",
              filterData.call_status,
              "&arrange=",
              filterData.arrange
            ),
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              setCall2(data.data.Call_records);
              setCall2_pre(data.data.pagination.previous);
              setCall2_next(data.data.pagination.next);
              setCall2_tp(data.data.total_number_of_pages);
              setCall2_cp(data.data.page_number);
            }
            setErrorMessage2(data.data.message);
          })
          .catch((err) => {
            console.log(err);
            console.log(errorMessage2);
          });
      } else if (filterData.call_status == "") {
        axios
          .get(
            "/get_user_follow_contact?organisation_id=".concat(
              org,
              "&next_call_user=",
              user_id,
              "&call_brief=",
              filterData.call_brief,
              "&arrange=",
              filterData.arrange
            ),
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              setCall2(data.data.Call_records);
              setCall2_pre(data.data.pagination.previous);
              setCall2_next(data.data.pagination.next);
              setCall2_tp(data.data.total_number_of_pages);
              setCall2_cp(data.data.page_number);
            }
            setErrorMessage2(data.data.message);
          })
          .catch((err) => {
            console.log(err);
            console.log(errorMessage2);
          });
      } else {
        axios
          .get(
            "/get_user_follow_contact?organisation_id=".concat(
              org,
              "&next_call_user=",
              user_id,
              "&call_brief=",
              filterData.call_brief,
              "&call_status=",
              filterData.call_status,
              "&arrange=",
              filterData.arrange
            ),
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              setCall2(data.data.Call_records);
              setCall2_pre(data.data.pagination.previous);
              setCall2_next(data.data.pagination.next);
              setCall2_tp(data.data.total_number_of_pages);
              setCall2_cp(data.data.page_number);
            }
            setErrorMessage2(data.data.message);
          })
          .catch((err) => {
            console.log(err);
            console.log(errorMessage2);
          });
      }
    }

    //////////////////////////////////////Fliter of all calls//////////////////////////////////////////////////////////

    function filter2(e) {
      e.preventDefault();
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };

      if (filterData.call_brief == "") {
        axios
          .get(
            "/get_users_all_contact?organisation_id=".concat(
              org,
              "&added_by_user=",
              user_id,
              "&call_status=",
              filterData.call_status,
              "&arrange=",
              filterData.arrange
            ),
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              setCall1(data.data.Call_records);
            }
            setErrorMessage2(data.data.message);
          })
          .catch((err) => {
            console.log(err);
            console.log(errorMessage2);
          });
      } else if (filterData.call_status == "") {
        axios
          .get(
            "/get_users_all_contact?organisation_id=".concat(
              org,
              "&added_by_user=",
              user_id,
              "&call_brief=",
              filterData.call_brief,
              "&arrange=",
              filterData.arrange
            ),
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              setCall1(data.data.Call_records);
            }
            setErrorMessage2(data.data.message);
          })
          .catch((err) => {
            console.log(err);
            console.log(errorMessage2);
          });
      } else {
        axios
          .get(
            "/get_users_all_contact?organisation_id=".concat(
              org,
              "&added_by_user=",
              user_id,
              "&call_brief=",
              filterData.call_brief,
              "&call_status=",
              filterData.call_status,
              "&arrange=",
              filterData.arrange
            ),
            { headers }
          )
          .then((data) => {
            if (data.status == "200") {
              setCall1(data.data.Call_records);
            }
            setErrorMessage2(data.data.message);
          })
          .catch((err) => {
            console.log(err);
            console.log(errorMessage2);
          });
      }
    }

    function assignedtaskfilter() {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/userAssineTasks?organisationID=".concat(
            org,
            "&UserID=",
            user_id,
            "&taskHeading=",
            filterTaskUser.taskHeading,
            "&taskStatus=",
            filterTaskUser.taskStatus,
            "&arrange=",
            filterTaskUser.arrange
          ),
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            setTask(data.data.Task);
            setTask_pre(data.data.pagination.previous);
            setTask_next(data.data.pagination.next);
            setTask_tp(data.data.total_number_of_pages);
            setTask_cp(data.data.page_number);
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage);
        });
    }

    function Createdtaskfilter() {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/userCreatedTasks?organisationID=".concat(
            org,
            "&UserID=",
            user_id,
            "&taskHeading=",
            filterTaskUser.taskHeading,
            "&taskStatus=",
            filterTaskUser.taskStatus,
            "&arrange=",
            filterTaskUser.arrange
          ),
          {headers}
        )
        .then((data) => {
          if (data.status == "200") {
            setTask(data.data.Task);
            setTask_pre(data.data.pagination.previous);
            setTask_next(data.data.pagination.next);
            setTask_tp(data.data.total_number_of_pages);
            setTask_cp(data.data.page_number);
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage);
        });
    }



    function taskpending() {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/get_UserTask_Dues?organizationID=".concat(org, "&userID=", user_id),
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            if (filterTask.task_type == "Assigned") {
              setTask(data.data.assigned);
            } else if (filterTask.task_type == "InProgress") {
              setTask(data.data.InProgress);
            } else if (filterTask.task_type == "Canceled") {
              setTask(data.data.Canceled);
            } else if (filterTask.task_type == "") {
              setTask(data.data.assigned);
            }
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage);
        });
    }

    function nextPage_call2(endpoint) {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };

      axios
        .get("".concat(endpoint), headers)
        .then((data) => {
          if (data.status == "200") {
            setCall2(data.data.Call_records);
            setCall2(data.data.Call_records);
            setCall2_pre(data.data.pagination.previous);
            setCall2_next(data.data.pagination.next);
            setCall2_tp(data.data.total_number_of_pages);
            setCall2_cp(data.data.page_number);
          }
          setErrorMessage2(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage2);
        });
    }

    function prePage_call2(endpoint) {
      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };

      axios
        .get("".concat(endpoint), headers)
        .then((data) => {
          if (data.status == "200") {
            setCall2(data.data.Call_records);
            setCall2_pre(data.data.pagination.previous);
            setCall2_next(data.data.pagination.next);
            setCall2_tp(data.data.total_number_of_pages);
            setCall2_cp(data.data.page_number);
          }
          setErrorMessage2(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage2);
        });
    }

 

    function nextPage_call1(endpoint) {
      const headers = {
        "Content-Type": "text/plain",
      };

      axios
        .get("".concat(endpoint), headers)
        .then((data) => {
          if (data.status == "200") {
            setCall1(data.data.Call_records);
            setCall1_pre(data.data.pagination.previous);
            setCall1_next(data.data.pagination.next);
            setCall1_tp(data.data.total_number_of_pages);
            setCall1_cp(data.data.page_number);
          }
          setErrorMessage2(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage2);
        });
    }

    function prePage_call1(endpoint) {
      const headers = {
        "Content-Type": "text/plain",
      };

      axios
        .get("".concat(endpoint), headers)
        .then((data) => {
          if (data.status == "200") {
            setCall1(data.data.Call_records);
            setCall1_pre(data.data.pagination.previous);
            setCall1_next(data.data.pagination.next);
            setCall1_tp(data.data.total_number_of_pages);
            setCall1_cp(data.data.page_number);
          }
          setErrorMessage2(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage2);
        });
    }

    function MemberSundayTemp() {
      if (todayCall1 != 0 && todayCall1 != 1) {
        return (
          <p className="card-text">
            You have to complete <b>{todayCall1}</b> calls.
          </p>
        );
      } else if (todayCall1 == 1) {
        return (
          <p className="card-text">
            You have to complete <b>{todayCall1}</b> call.
          </p>
        );
      } else {
        return (
          <>
            <img 
              src="https://salessunday-image.s3.ap-south-1.amazonaws.com/Pages-image/no+calls+available.svg"
              height={250}
            />
            <p className="card-text">No calls are assigned.</p>
          </>
        );
      }
    }

    return (
      <>
        <div className="container">
          <div className="row">
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item bg-transparent" id="callback">
                <p
                  className="accordion-header"
                  id="flush-headingOne"
                  onClick={getCardData1}
                  style={{ cursor: "pointer" }}
                >
                  <p
                    className="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Data Overview <i className="bi bi-caret-down-fill"></i>
                  </p>
                </p>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="text-dark card_design border border-secondary p-2">
                        <div className="card-body">
                          <h3 align="left">
                            <i className="bi bi-emoji-sunglasses-fill"></i>
                            <b> {intreseted1}</b>
                          </h3>
                          <p align="left" className="card-text text-dark">
                            
                            Interested
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-2">
                      <div className="text-dark card_design border border-secondary p-2">
                        <div className="card-body">
                          <h3 align="left">
                            <i className="bi bi-emoji-neutral-fill"></i>
                            <b> {notIntreseted1}</b>
                          </h3>
                          <p align="left" className="card-text text-dark">
                            Not Interested
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-2">
                      <div className="text-dark card_design border border-secondary p-2 ">
                        <div className="card-body">
                          <h3 align="left">
                            
                            <i className="bi bi-telephone-plus-fill"></i>
                            <b> {callBack1}</b>
                          </h3>
                          <p align="left" className="card-text text-dark">
                            
                            CallBack
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-2">
                      <div className="text-dark card_design border border-secondary p-2">
                        <div className="card-body">
                          <h3 align="left">
                            <i className="bi bi-phone-fill"></i>
                            <b> {totalCall1}</b>
                          </h3>
                          <p align="left" className="card-text text-dark">
                            
                            Total Calls
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <Link
                            to="/callinfo"
                          ><button className="btn btn-dark bg-gradient border-dark position-relative" type="button"><i className="bi bi-journal"></i> Contact Book 
                          </button></Link>
                          <Link
                            to="/taskboard"
                          ><button className="btn btn-warning bg-gradient border-dark position-relative" type="button"><i className="bi bi-square"></i> Task-Board 
                          <span className="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-dark text-white">
  New
  <span className="visually-hidden">unread messages</span>
</span>

                          </button></Link>
          </div>
        </div>
        <div className="container">
          <ul
            className="nav nav-tabs border-0"
            id="myTab"
            role="tablist"
          >
            
            <li className="nav-item" role="presentation">
              <button
                onClick={() => {
                  fetchToday('1', today_date)
                  tasktoday();
                }}
                className="nav-link active"
                id="today-tab"
                data-bs-toggle="tab"
                data-bs-target="#today"
                type="button"
                role="tab"
                aria-controls="today"
                aria-selected="false"
              >
                <i className="bi bi-calendar-event"></i> Today
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={() => {
                  fetchPendingCall();
                  taskpending();
                }}
                className="nav-link"
                id="pending-tab"
                data-bs-toggle="tab"
                data-bs-target="#pending"
                type="button"
                role="tab"
                aria-controls="pending"
                aria-selected="true"
              >
                <i className="bi bi-calendar-x"></i> Dues
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={getToCallData}
                className="nav-link"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <i className="bi bi-telephone"></i> To call
              </button>
            </li>
            {/* <li className="nav-item" role="presentation">
              <button
                onClick={fetchData2}
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                <i className="bi bi-telephone-plus"></i> Call Back
              </button>
            </li> */}
            <li className="nav-item" role="presentation">
              <button
                onClick={fetchData1}
                className="nav-link"
                id="contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#contact"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
              >
                <i className="bi bi-telephone-outbound"></i> Called
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={assignedTask}
                className="nav-link"
                id="task-tab"
                data-bs-toggle="tab"
                data-bs-target="#task"
                type="button"
                role="tab"
                aria-controls="task"
                aria-selected="false"
              >
                <i className="bi bi-bicycle"></i> All Task
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button className="btn btn-transparent me-md-2" type="button">
                  Add To Call List:
                </button>
                <button
                  id="single"
                  type="button"
                  className="btn btn-dark bg-gradient me-md-2"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <i className="bi bi-plus-circle"></i> Single
                </button>
                <button
                  className="btn btn-success bg-gradient"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalmulti"
                >
                  <i className="bi bi-file-earmark-spreadsheet"></i> Multi
                </button>
              </div>
              <div>
                {/* Button trigger modal */}

                {/* Modal */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header border-0">
                        <h5 className="modal-title">
                          Assign a
                          phone number. &nbsp;<OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip('You have a lead or you want to call someone you can add here, deploy it and track it.')}
    >
      <i className="bi bi-info-circle-fill text-dark"></i>
    </OverlayTrigger>
                        </h5>
                        <button
                          id="close"
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-5">
                          <br/><br/>
                          <img 
                              src={addsingle}
                              height={350}
                            />
                          </div>
                          <div className="col-7">
                          <div className="row">
                            <div className="col-10">
                            <label for="exampleFormControlInput1" class="form-label">Existing contact?</label>
                            <div className="input-group mb-3 input-group-sm">      
        <input type="text" name='tocall'  value={searchelement.tocall}
                                    onChange={updataSearchElement} className="form-control border-dark" placeholder="Recipient's Name/Number/Email" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button className="btn btn-dark" onClick={SearchHome} type="button" id="button-addon2"><i className="bi bi-search"></i></button>
      </div>
</div>
                            </div>
                            <div
                className="overflow-auto bg-light"
                style={{ maxWidth: "500px", maxHeight: "100px" }}
              >
                            <ul className="list-group">
                
              {personsearchresult &&
                          personsearchresult?.map((item) => (
                            <li onClick={()=>setperson(item,'call')} className="list-group-item cursor listhover"><i className="bi bi-person-circle"></i> {item.customer_name}   {masknumber(item.contact_number)}</li>
                          ))}
  
</ul></div>
                          <p align='center'>-or-</p>
                            <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                              <i className="bi bi-person-circle"></i>  Customer Name
                              </label>
                              <input
                                name="customer_name"
                                value={addCallData.customer_name}
                                onChange={changeCallData}
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Raju Sharma"
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                              <i className="bi bi-telephone-fill"></i>  Phone Number
                              </label>
                              <input
                                name="contact_number"
                                value={addCallData.contact_number}
                                onChange={changeCallData}
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="8149910885"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-envelope-fill"></i> Email Id
                              </label>
                              <input
                                name="email_address"
                                value={addCallData.email_address}
                                onChange={changeCallData}
                                type="email"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="name@example.com"
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-person-fill"></i> Assign to
                              </label>
                              <select
                                value={addCallData.next_call_user}
                                onChange={changeCallData}
                                name="next_call_user"
                                className="form-select mb-3 col-6 border"
                                aria-label="Default select example"
                                required
                              >
                                <option>Select Person</option>
                                {feeduser &&
                                  feeduser?.map((item) => (
                                    <option
                                      value={item.id + "," + item.user_name}
                                    >
                                      {item.user_name}-{item.role}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-file-text-fill"></i> Select Script
                              </label>
                              <select
                                value={addCallData.next_call_script_category}
                                onChange={changeCallData}
                                name="next_call_script_category"
                                className="form-select mb-3 col-6 border"
                                aria-label="Default select example"
                                required
                              >
                                <option>Select Category</option>
                                {feedOrgCategory &&
                                  feedOrgCategory?.map((item) => (
                                    <option value={item.script_type_name}>
                                      {item.script_type_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                               <i className="bi bi-alarm-fill"></i> Schedule
                              </label>
                              <input
                                name="call_again_date"
                                value={addCallData.call_again_date}
                                onChange={changeCallData}
                                type="datetime-local"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="name@example.com"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <p
                              className="collapsed text-dark"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFive"
                              aria-expanded="false"
                              aria-controls="flush-collapseFive"
                            >
                              Do you have a source?
                              <i className="bi bi-caret-down-fill"></i>
                            </p>
                          </div>
                        </div>
                        <div
                          id="flush-collapseFive"
                          className="accordion-collapse collapse bg-transparent"
                          aria-labelledby="flush-headingFive"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="row" style={{ alignItems: "center" }}>
                            <div className="col-5">
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Select source
                                </label>
                                <select
                                  value={addCallData.Contact_Source}
                                  onChange={changeCallData}
                                  name="Contact_Source"
                                  className="form-select mb-3 col-6 border"
                                  aria-label="Default select example"
                                >
                                  <option>Select source</option>
                                  {sourceList &&
                                    sourceList?.map((item) => (
                                      <option value={item}>{item}</option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="mb-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Add new source
                                  </label>
                                  <input
                                    name="source"
                                    value={addsource}
                                    onChange={(e) =>
                                      setAddscource(e.target.value)
                                    }
                                    className="form-control"
                                    placeholder="Type new source"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-2"
                              style={{ marginTop: "30px" }}
                            >
                              <div className="mb-3">
                                <div className="mb-3">
                                  <button
                                    className="btn btn-light"
                                    disabled={!addsource}
                                    onClick={handleAddSource}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                             <i className="bi bi-card-text"></i> Description
                            </label>
                            <textarea
                              value={addCallData.description}
                              onChange={changeCallData}
                              name="description"
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows={3}
                              defaultValue={""}
                            />
                          </div>
                        </div>

                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                          <button
                            className="btn btn-dark"
                            onClick={sendToCallData}
                          >
                            Submit
                          </button>
                        </div></div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                {/* modal 2*/}

                <div
                  className="modal fade"
                  id="exampleModalmulti"
                  tabIndex={-1}
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header border-0">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Add data to call via Spreadsheet
                        </h5>
                        <button
                          id="close1"
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-5">
                            <img
                              src="https://salessunday-image.s3.ap-south-1.amazonaws.com/Pages-image/4.+Data+Upload.svg"
                              height={150}
                            />
                          </div>
                          <div className="col-7">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-1-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383h1.312Z"
                              />
                            </svg>&nbsp;
                            Download New Spreadsheet.
                            <b
                              style={{ cursor: "pointer" }}
                              onClick={() => getSpreedSheet()}
                            >
                              Click Here
                            </b>
                            <br />
                            <br />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-2-circle-fill"
                              viewBox="0 0 16 16"
                            >&nbsp;
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM6.646 6.24c0-.691.493-1.306 1.336-1.306.756 0 1.313.492 1.313 1.236 0 .697-.469 1.23-.902 1.705l-2.971 3.293V12h5.344v-1.107H7.268v-.077l1.974-2.22.096-.107c.688-.763 1.287-1.428 1.287-2.43 0-1.266-1.031-2.215-2.613-2.215-1.758 0-2.637 1.19-2.637 2.402v.065h1.271v-.07Z" />
                            </svg>
                            Add your Data accordion to instructions.
                            <br />
                            <br />
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-3-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-8.082.414c.92 0 1.535.54 1.541 1.318.012.791-.615 1.36-1.588 1.354-.861-.006-1.482-.469-1.54-1.066H5.104c.047 1.177 1.05 2.144 2.754 2.144 1.653 0 2.954-.937 2.93-2.396-.023-1.278-1.031-1.846-1.734-1.916v-.07c.597-.1 1.505-.739 1.482-1.876-.03-1.177-1.043-2.074-2.637-2.062-1.675.006-2.59.984-2.625 2.12h1.248c.036-.556.557-1.054 1.348-1.054.785 0 1.348.486 1.348 1.195.006.715-.563 1.237-1.342 1.237h-.838v1.072h.879Z" />
                            </svg>
                            Upload spreadsheet.
                            <br />
                            <br />
                            <input
                              type="file"
                              onChange={updataFile}
                              name="file"
                            />
                            <button
                              className="btn btn-dark"
                              onClick={uploadSpreedSheet}
                              disabled={disabled}
                            >
                              {disabled ? (
                                <div className="d-flex align-items-center">
                                  <strong>Loading...</strong>
                                  <div
                                    className="spinner-border ms-auto"
                                    style={{
                                      height: "20px",
                                      width: "20px",
                                      marginLeft: "4px !important",
                                    }}
                                    role="status"
                                    aria-hidden="true"
                                  ></div>
                                </div>
                              ) : (
                                "Submit"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="modal fade"
                  id="exampleModal2"
                  tabIndex={-1}
                  aria-labelledby="exampleModal2Label"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          <i className="bi bi-telephone-plus-fill"></i> Add
                          phone number to call.
                        </h5>
                        <button
                          id="close"
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Customer Name
                              </label>
                              <input
                                name="customer_name"
                                value={addCallData.customer_name}
                                onChange={changeCallData}
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="Raju Sharma"
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Phone Number
                              </label>
                              <input
                                name="contact_number"
                                value={addCallData.contact_number}
                                onChange={changeCallData}
                                type="text"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="8149910885"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Email Id
                              </label>
                              <input
                                name="email_address"
                                value={addCallData.email_address}
                                onChange={changeCallData}
                                type="email"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="name@example.com"
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Assign to
                              </label>
                              <select
                                value={addCallData.next_call_user}
                                onChange={changeCallData}
                                name="next_call_user"
                                className="form-select mb-3 col-6 border border-white inside"
                                aria-label="Default select example"
                                required
                              >
                                <option>Select Person</option>
                                {feeduser &&
                                  feeduser?.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item.id + "," + item.user_name}
                                    >
                                      {item.user_name}-{item.role}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Select Script
                              </label>
                              <select
                                value={addCallData.next_call_script_category}
                                onChange={changeCallData}
                                name="next_call_script_category"
                                className="form-select mb-3 col-6 border border-secondary inside"
                                aria-label="Default select example"
                                required
                              >
                                {feedOrgCategory &&
                                  feedOrgCategory?.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item.script_type_name}
                                    >
                                      {item.script_type_name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="mb-3">
                              <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                              >
                                Schedule
                              </label>
                              <input
                                name="call_again_date"
                                value={addCallData.call_again_date}
                                onChange={changeCallData}
                                type="datetime-local"
                                className="form-control"
                                id="exampleFormControlInput1"
                                placeholder="name@example.com"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <p
                              className="collapsed text-dark"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapseFive"
                              aria-expanded="false"
                              aria-controls="flush-collapseFive"
                            >
                              Do you have a source?
                              <i className="bi bi-caret-down-fill"></i>
                            </p>
                          </div>
                        </div>
                        <div
                          id="flush-collapseFive"
                          className="accordion-collapse collapse bg-transparent"
                          aria-labelledby="flush-headingFive"
                          data-bs-parent="#accordionFlushExample1"
                        >
                          <div className="row" style={{ alignItems: "center" }}>
                            <div className="col-5">
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Select source
                                </label>
                                <select
                                  value={addCallData.Contact_Source}
                                  onChange={changeCallData}
                                  name="Contact_Source"
                                  className="form-select mb-3 col-6 border"
                                  aria-label="Default select example"
                                >
                                  <option>Select source</option>
                                  {sourceList &&
                                    sourceList?.map((item, index) => (
                                      <option key={index} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="mb-3">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Add new source
                                  </label>
                                  <input
                                    name="source"
                                    value={addsource}
                                    onChange={(e) =>
                                      setAddscource(e.target.value)
                                    }
                                    className="form-control"
                                    placeholder="Type new source"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-2"
                              style={{ marginTop: "30px" }}
                            >
                              <div className="mb-3">
                                <div className="mb-3">
                                  <button
                                    className="btn btn-light"
                                    disabled={!addsource}
                                    onClick={handleAddSource}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              Description
                            </label>
                            <textarea
                              value={addCallData.description}
                              onChange={changeCallData}
                              name="description"
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows={3}
                              defaultValue={""}
                            />
                          </div>
                        </div>

                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                          <button
                            className="btn btn-primary"
                            onClick={sendToCallData}
                          >
                            <i className="bi bi-arrow-right"></i> Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>

              <br />
              {tocall == "" ? (
                <div align="center">
                  <img src={empty} width="500" height="500" />
                </div>
              ) : (
                <div className="card_design border border-dark">
                  <br />
                  <div className="container">
                    <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                      <caption>To Call</caption>
                      <thead className="table-dark">
                        <tr>
                          <th scope="col">
                            <i className="bi bi-person"></i> Name
                          </th>
                          <th scope="col">
                            <i className="bi bi-card-text"></i> Description
                          </th>
                          <th scope="col"><i class="bi bi-person-badge"></i> Assigned User</th>
                          <th scope="col"><i class="bi bi-telephone"></i> Call type</th>
                          <th scope="col">
                            <i className="bi bi-calendar4-event"></i> Assigned Date
                          </th>
                          <th scope="col">
                            <i className="bi bi-menu-button-fill"></i> 
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {tocall &&
                          tocall?.map((item, index) => (
                            <tr key={index}>
                              <td>
                              {item.customer_name==""?<>{
                               details == true?item.contact_number:item.contact_number.slice(0, -3) + '*** '

                              }</>:<>{item.customer_name}</>
                        }
                        {returncontactmem(item)}
                              </td>
                             

                              <td>{item.description}</td>
                              <td>{item.next_call_username}</td>
                              <td>{item.next_call_script_category}</td>
                              <td>
                                <b>{item.call_again_date!=""?alterCallBack(item.call_again_date):null}</b>
                              </td>
                              <td>
                              {
                            item.Contact_Source == "" || item.Contact_Source == null ? null : (
                             <OverlayTrigger trigger="click" placement="top" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                                <i className="bi bi-bezier2"></i> 
                              </OverlayTrigger>

                            )
                           }&nbsp;
                                <a
                                  href={
                                    "https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number
                                  }
                                  target="whatsapp"
                                >
                                  <i className="bi bi-whatsapp"></i>
                                </a>
                                {
                            item.next_call_script_category == "" || item.contact_number == "" || item._id ==""?
                            <OverlayTrigger trigger="click" placement="top" overlay={renderPopover("Data Canceled! Add assigned script, contact number and try again.")}>
                             <i className="bi bi-phone-fill text-secondary"></i> 
                          </OverlayTrigger>
                           :<Link
                            to={
                              `/call?nextcall=` +
                              item.next_call_script_category +
                              "&contactno=" +
                              item.contact_number +
                              "&email=" +
                              item.email_address +
                              "&customer=" +
                              item.customer_name +
                              "&calltype=tocall" +
                              sourcevalue(item) +
                              "&callId=" +
                              item._id
                            }
                          >
                            <i className="bi bi-phone-fill"></i>
                          </Link>
                           
                           }
                                {/* <i
                                  onClick={() => delToCallData(item._id)}
                                  className="bi bi-trash-fill"
                                ></i> */}
                                <i
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal9"
                                  onClick={() => setdeleteItem(item)}
                                  style={{ cursor: "pointer" }}
                                  className="bi bi-trash-fill"
                                ></i>
                                <Alert
                                  modelid={"exampleModal9"}
                                  heading={"You are about to delete a record."}
                                  description={
                                    "This will delete call record from list. Are you sure?"
                                  }
                                  method={delToCallData}
                                  item={deleteItem}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table></div>
                    
                  </div>
                  <div className="container"><nav
                className="d-grid gap-2 d-md-flex justify-content-md-end"
                aria-label="..."
              >
                {/* tocall cahnges */}
                <ul className="pagination">
                  <li className="page-item">
                    <a className="btn btn-light">
                      
                      Total Pages: <b>{call_tocall_tp}</b>
                    </a>
                  </li>
                  {

call_tocall_cp == "1" || call_tocall_cp == "0"?<li className="page-item ">
<button 
  className="page-link bg-secondary text-white pop rounded" disabled
  style={{ cursor: "not-allowed" }}
  onClick={() => prePage_tocall(call_pre_tocall)}
>
  <i className="bi bi-arrow-left-circle" /> Previous
</button>
</li>:<li className="page-item ">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => prePage_tocall(call_pre_tocall)}
                    >
                      <i className="bi bi-arrow-left-circle" /> Previous
                    </a>
                  </li>

                  }
                  
                  <li className="page-item ">
                    <a className="page-link text-dark border-0 bg-transparent">
                      <b>{call_tocall_cp}</b>
                    </a>
                  </li>

{
  call_tocall_cp == call_tocall_tp || call_tocall_cp == '0'?<li className="page-item ">
  <button
    className="page-link bg-secondary text-white pop rounded" disabled
    style={{ cursor: "not-allowed" }}
    onClick={() => nextPage_tocall(call_next_tocall)}
  >
    Next <i className="bi bi-arrow-right-circle" />
  </button>
</li>:<li className="page-item ">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => nextPage_tocall(call_next_tocall)}
                    >
                      Next <i className="bi bi-arrow-right-circle" />
                    </a>
                  </li>

}

                  
                </ul>
              </nav></div>
                </div>
              )}

              <div></div>
            </div>
            <br />
            <div
              className="tab-pane fade p-3 mb-5 card_design border border-secondary"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <p className="text-secondary">Filter Calls:</p>
              <form onSubmit={followup2}>
                <div className="row">
                  <div className="col-2">
                    <select
                      value={filterData.call_brief}
                      onChange={updataFilterData}
                      name="call_brief"
                      className="form-select border border-secondary inside"
                      aria-label="Default select example"
                    >
                      <option selected>Select Call Brief</option>
                      <option value="Cut Call">Cut Call</option>
                      <option value="Didn't Pick">Didn't Pick</option>
                      <option value="Call Later">Call Later</option>
                      <option value="Not Valid">Not Valid</option>
                      <option value="Interested">Interested</option>
                      <option value="Not Interested">Not Interested</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <select
                      value={filterData.call_status}
                      onChange={updataFilterData}
                      name="call_status"
                      className="form-select border border-secondary inside"
                      aria-label="Default select example"
                    >
                      <option selected>Select Call Status</option>
                      <option value="None">None</option>
                      <option value="Salute %26 Confirmation">
                        Salute & Confirmation
                      </option>
                      <option value="Pitch and Information">
                        Pitch and Information
                      </option>
                      <option value="Ask Question %26 Talk">
                        Ask Question & Talk
                      </option>
                      <option value="Objection Handling">
                        Objection Handling
                      </option>
                      <option value="Closing">Call Closing</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <select
                      value={filterData.arrange}
                      onChange={updataFilterData}
                      name="arrange"
                      className="form-select border border-secondary inside"
                      aria-label="Default select example"
                    >
                      <option value="0">New to Old</option>
                      <option value="1">Old to New</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <button type="submit" className="btn btn-dark me-md-3 pop">
                      <i className="bi bi-arrow-right"></i> Submit
                    </button>
                    <button
                      type="button"
                      onClick={clearFilter}
                      className="btn btn-danger pop"
                    >
                      <i className="bi bi-x"></i>
                    </button>
                  </div>
                </div>
              </form>
              <br />
              <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                <caption>Call Backs</caption>
                <thead className="table-dark">
                  <tr>
                    <th scope="col">
                      <i className="bi bi-person"></i> Name
                    </th>
                    <th scope="col">
                      <i className="bi bi-phone-vibrate"></i> Call Brief
                    </th>
                    <th scope="col">
                      <i className="bi bi-compass"></i> Call Status
                    </th>
                    <th scope="col">
                      <i className="bi bi-telephone"></i> Last Call
                    </th>
                    <th scope="col">
                      <i className="bi bi-telephone-forward"></i> Next Call
                    </th>
                    <th scope="col">
                      <i className="bi bi-telephone-plus"></i> Call Back
                    </th>
                    <th scope="col">
                      <i className="bi bi-calendar4-event"></i> Date
                    </th>
                    <th scope="col">
                      <i className="bi bi-menu-button-fill"></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {call2 &&
                    call2?.map((item, index) => (
                      <tr key={index}>
                        <td>
                              <b>{item.customer_name==""?<>{
                               details == true?item.contact_number:item.contact_number.slice(0, -3) + '*** '

                              }</>:<>{item.customer_name}</>
                        }
                        {returncontactmem(item)}</b>
                            </td>
                       
                        <td>{changeElement(item)}</td>
                        <td>{progressElement(item)}</td>
                        <td>{item.script_category}</td>
                        <td>{item.next_call_script_category}
                        </td>
                        <td>
                          <b>{alterCallBack(item.call_again_date)}</b>
                        </td>
                        <td>{alterElement(item)}</td>
                        <td>
                        {
                            item.Contact_Source == "" || item.Contact_Source == null ? null : (
                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                                <i className="bi bi-bezier2"></i> 
                              </OverlayTrigger>
                              
                            )
                           }&nbsp;
                          <a
                            href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                            target="whatsapp"
                          >
                            <i className="bi bi-whatsapp"></i>
                          </a>&nbsp;
                          <Link
                            to={
                              `/call?nextcall=` +
                              item.next_call_script_category +
                              "&contactno=" +
                              item.contact_number +
                              "&email=" +
                              item.email_address +
                              "&customer=" +
                              item.customer_name +
                              "&calltype=callback" +
                              sourcevalue(item) +
                              "&callId=" +
                              item._id
                            }
                          >
                            <i className="bi bi-telephone-fill"></i>
                          </Link>&nbsp;
                          <i
                            onClick={() =>
                              openNav(item.contact_number, item.id)
                            }
                            className="bi bi-geo-fill"
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table></div>
              <nav
                className="d-grid gap-2 d-md-flex justify-content-md-end"
                aria-label="..."
              >
                <ul className="pagination">
                  <li className="page-item">
                    <a className="btn btn-light">
                      
                      Total Pages: <b>{call2_tp}</b>
                    </a>
                  </li>
                  {

call2_cp == "1" || call2_cp == "0"?<li className="page-item ">
<button 
  className="page-link bg-secondary text-white pop rounded" disabled
  style={{ cursor: "not-allowed" }}
  onClick={() => prePage_call2(call2_pre)}
>
  <i className="bi bi-arrow-left-circle" /> Previous
</button>
</li>:<li className="page-item ">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => prePage_call2(call2_pre)}
                    >
                      <i className="bi bi-arrow-left-circle" /> Previous
                    </a>
                  </li>

                  }
                  <li className="page-item ">
                    <a className="page-link text-dark border-0 bg-transparent">
                      <b>{call2_cp}</b>
                    </a>
                  </li>
                  {
  call2_tp == call2_cp || call2_tp == '0'?<li className="page-item ">
  <button
    className="page-link bg-secondary text-white pop rounded" disabled
    style={{ cursor: "not-allowed" }}
    onClick={() => nextPage_call2(call2_next)}
  >
    Next <i className="bi bi-arrow-right-circle" />
  </button>
</li>:<li className="page-item ">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => nextPage_call2(call2_next)}
                    >
                      Next <i className="bi bi-arrow-right-circle" />
                    </a>
                  </li>

}
                </ul>
              </nav>
            </div>
            <div
              className="tab-pane fade show active p-3 mb-5 card_design border border-primary"
              id="today"
              role="tabpanel"
              aria-labelledby="today-tab"
            >
              <br />
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    <i className="bi bi-phone-fill"></i> Call Today
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-TaskToday-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-TaskToday"
                    type="button"
                    role="tab"
                    aria-controls="pills-TaskToday"
                    aria-selected="false"
                  >
                    <i className="bi bi-bicycle"></i> Task Today
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                 <p className="text-secondary">Filter Calls:</p>
                  <div className="row">
                    <div className="col-6 col-sm-3">
                      <select
                        name="script_category"
                        value={filterToday.script_category}
                        onChange={updateTodayFilter}
                        className="form-select border border-secondary inside"
                        aria-label="Default select example"
                      >
                        <option value='' selected>Choose Script Category</option>
                        {feedOrgCategory &&
                          feedOrgCategory?.map((item) => (
                            <option value={item.script_type_name}>
                              {item.script_type_name}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="col-4 col-sm-3">
                      <button
                        type="button"
                        onClick={()=>fetchToday('1', today_date)}
                        className="btn btn-dark me-md-3 pop"
                      >
                        <i className="bi bi-arrow-right"></i> Submit
                      </button>
                      {" "}
                      <button
                        type="button"
                        onClick={clearFilter}
                        className="btn btn-danger me-md-3 pop"
                      >
                        <i className="bi bi-x"></i>
                      </button>
                      
                    </div>
                   
                   
                  </div>
                  <br/>

                  {today.data == ""?
                  <div align="center">
                       <img
                  src={emptytable}  height={350}
                />
                  </div> 
                  :<> <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              >
                <table className="table table-striped table-hover border-white caption-top">
                  <caption>Today Calls</caption>
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">
                        
                        <i className="bi bi-tags-fill"></i>
                      </th>
                      <th scope="col">
                        <i className="bi bi-person"></i> Name
                      </th>
                     
                      <th scope="col">
                        <i className="bi bi-telephone-forward"></i> Call Type
                      </th>
                      <th scope="col">
                      <i className="bi bi-person-badge-fill"></i> Assigned 
                      </th>
                      <th scope="col">
                        <i className="bi bi-telephone-plus"></i> Call Back
                      </th>
                      <th scope="col">
                        <i className="bi bi-menu-button-fill"></i> 
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {today.data &&
                      today.data?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.call_brief == null ? (
                              <span className="badge bg-dark">To Call</span>
                            ) : (
                              <span className="badge bg-info text-dark">
                                Call Back
                              </span>
                            )}
                          </td>
                          <td>
                            <b>{item.customer_name==""?<a
                                href={
                                  "callinfo?phoneno=" + item.contact_number
                                }
                                target="_blank"
                              >{item.contact_number}</a>:<a
                              href={
                                "callinfo?phoneno=" + item.contact_number
                              }
                              target="_blank"
                            >{item.customer_name}</a>
                      }
                      <OverlayTrigger trigger="click" placement="bottom" overlay={renderPopover('Contact No: '.concat(item.contact_number, ', Email: ', item.email_address))}>
                      <i className="bi bi-caret-down-fill"></i>
                              </OverlayTrigger></b>
                          </td>           
                          <td>{item.next_call_script_category}</td>
                          <td>{item.next_call_username}</td>
                          <td>
                            <b>{alterCallBack(item.call_again_date)}</b>
                          </td>

                          <td>
                          {
                          item.Contact_Source == "" || item.Contact_Source == null ? null : (
                            <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                              <i className="bi bi-bezier2"></i> 
                            </OverlayTrigger>
                            
                          )
                         }&nbsp;
                            <a
                              href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                              target="whatsapp"
                            >
                              <i className="bi bi-whatsapp"></i>
                            </a>&nbsp;
                            <Link
                              to={
                                `/call?nextcall=` +
                                item.next_call_script_category +
                                "&contactno=" +
                                item.contact_number +
                                "&email=" +
                                item.email_address +
                                "&customer=" +
                                item.customer_name +
                                tocallvalue(item) +
                                sourcevalue(item) +
                                "&callId=" +
                                item.Id
                              }
                            >
                              <i className="bi bi-telephone-fill"></i>
                            </Link>&nbsp;
                            <i
                              onClick={() =>
                                openNav(item.contact_number, item.id)
                              }
                              className="bi bi-geo-fill"
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                </div>
                
                
                
                
                <div className="container mt-3">
                    <nav
                className="d-grid gap-2 d-md-flex justify-content-md-end"
                aria-label="..."
              >
                
                {/* tocall cahnges */}
                <ul className="pagination">
                <li className="page-item">
                    <a className="btn btn-light me-md-2">
                      
                      Record Size: <b>{paginationSize}</b>
                    </a>
                  </li>

                  <li className="page-item">
                    <a className="btn btn-light">
                      
                      Total Pages: <b>{today.total_pages}</b>
                    </a>
                  </li>
                  {

today.current_page == "1" || today.current_page == "0"?<li className="page-item ">
<button 
  className="page-link bg-secondary text-white pop rounded" disabled
  style={{ cursor: "not-allowed" }}

>
  <i className="bi bi-arrow-left-circle" /> Previous
</button>
</li>:<li className="page-item ">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => fetchToday(today.previous_page, today_date)}
                    >
                      <i className="bi bi-arrow-left-circle" /> Previous
                    </a>
                  </li>

                  }
                  
                  <li className="page-item ">
                    <a className="page-link text-dark border-0 bg-transparent">
                      <b>{today.current_page}</b>
                    </a>
                  </li>

{
  today.current_page == today.total_pages || today.current_page == '0'?<li className="page-item ">
  <button
    className="page-link bg-secondary text-white pop rounded" disabled
    style={{ cursor: "not-allowed" }}
  >
    Next <i className="bi bi-arrow-right-circle" />
  </button>
</li>:<li className="page-item ">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => fetchToday(today.next_page, today_date)}
                    >
                      Next <i className="bi bi-arrow-right-circle" />
                    </a>
                  </li>

}

                  
                </ul>
              </nav>
                    </div>  



                

                
                
                
                
                </>}
               
                  
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-TaskToday"
                  role="tabpanel"
                  aria-labelledby="pills-TaskToday-tab"
                >

<p className="text-secondary">Filter Calls:</p>
                  <div className="row">
                    <div className="col-3">
                      <select
                        name="task_type"
                        value={filterTask.task_type}
                        onChange={updateTaskType}
                        className="form-select border border-secondary inside"
                        aria-label="Default select example"
                      >
                        <option value="Assigned" selected>
                          Assigned
                        </option>
                        <option value="InProgress" selected>
                          In Progress
                        </option>
                        <option value="Canceled" selected>
                          Canceled
                        </option>
                      </select>
                    </div>

                    <div className="col-3">
                      <button
                        type="button"
                        onClick={tasktoday}
                        className="btn btn-dark me-md-3 pop"
                      >
                        <i className="bi bi-arrow-right"></i> Submit
                      </button>
                      <button
                        type="button"
                        onClick={clearFilter}
                        className="btn btn-danger pop"
                      >
                        <i className="bi bi-x"></i>
                      </button>
                    </div>
                  </div>
                  <br/>

               {task ==null?<div align="center">
                       <img
                  src={emptycall}  height={350}
                />
                  </div>:<>  

                  <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                    <caption>Tasks</caption>
                    <thead className="table-dark">
                      <tr>
                        <th scope="col">
                          
                          <i className="bi bi-tags-fill"></i>
                        </th>
                        <th scope="col">
                          <i className="bi bi-bicycle"></i> Task
                        </th>
                        <th scope="col">
                          <i className="bi bi-phone"></i> Contact
                        </th>
                        <th scope="col">
                          <i className="bi bi-person-circle"></i> Added
                        </th>
                        <th scope="col">
                          <i className="bi bi-calendar2-check"></i> Due Date
                        </th>
                        <th scope="col">
                          <i className="bi bi-calendar4-event"></i> Date
                        </th>
                        <th scope="col">
                          <i className="bi bi-menu-button-fill"></i>
                        </th>
                        <th scope="col">
                         
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {task &&
                        task?.map((item, index) => (
                          <tr key={index}>
                           <td>
                          {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                        </td>
                            <td>
                              <b>{item.taskHeading}</b>
                            </td>
                            <td>
                              <b>{item.customer_name==""?<a
                                  href={
                                    "callinfo?phoneno=" + item.contact_number
                                  }
                                  target="_blank"
                                >{item.contact_number}</a>:<a
                                href={
                                  "callinfo?phoneno=" + item.contact_number
                                }
                                target="_blank"
                              >{item.customer_name}</a>
                        }
                        {returncontact(item)}</b>
                            </td>
                            
                            <td>{item.added_by_username}</td>
                            <td>
                              <b>{alterCallBack(item.lastDate)}</b>
                            </td>
                            <td>{alterElement(item)}</td>
                            <td>
                            {
                            item.Contact_Source == "" || item.Contact_Source == null ? null : (
                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                                <i className="bi bi-bezier2"></i> 
                              </OverlayTrigger>
                              
                            )
                           }&nbsp;
                              <a
                                href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                                target="whatsapp"
                              >
                                <i className="bi bi-whatsapp"></i>
                              </a>
                              <i
                                onClick={() =>
                                  openNav(item.contact_number, item._id)
                                }
                                className="bi bi-geo-fill"
                              />
                              <div
                                className="modal fade"
                                id={"modalToggle" + item._id}
                                aria-hidden="true"
                                aria-labelledby="exampleModalToggleLabel"
                                tabIndex={-1}
                              >
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header border-0">
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      />
                                    </div>
                                    <div className="modal-body">
                                      <div className="row bg-transparent">
                                        <div className="mb-1 col-12 bg-transparent">
                                          <label
                                            for="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            <i className="bi bi-file-earmark-text-fill" />
                                            Change Status:
                                          </label>

                                          <input
                                            name="taskID"
                                            type="hidden"
                                            value={editTask.taskID}
                                            onChange={editTaskData}
                                          ></input>

                                          <select
                                            name="taskStatus"
                                            value={editTask.taskStatus}
                                            onChange={editTaskData}
                                            className="form-select mb-3 col-6 border border-success"
                                            aria-label="Default select example"
                                            required
                                          >
                                            <option selected>
                                              Select Task Status
                                            </option>
                                            <option value="Assigned">
                                              Assigned
                                            </option>
                                            <option value="In Progress">
                                              In Progress
                                            </option>
                                            <option value="Canceled">
                                             Canceled  
                                            </option>
                                            <option
                                              className="bg-success text-white"
                                              value="Completed"
                                            >
                                              Completed
                                            </option>
                                          </select>
                                        </div>

                                        <div className="mb-1 col-12">
                                          <div className="mb-1">
                                            <label
                                              for="exampleFormControlTextarea1"
                                              className="form-label"
                                            >
                                              <i className="bi bi-file-text-fill" />
                                              Remarks
                                            </label>
                                            <textarea
                                              name="remark"
                                              value={editTask.remark}
                                              onChange={editTaskData}
                                              className="form-control border border-success"
                                              id="exampleFormControlTextarea1"
                                              rows="2"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="modal-footer border-0">
                                      <button
                                        onClick={updateTask}
                                        className="btn btn-dark"
                                        data-bs-toggle="modal"
                                        data-bs-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button
                                className="btn btn-transparent btn-sm"
                                onClick={() => setDefaultTask(item)}
                                data-bs-toggle="modal"
                                href={"#modalToggle" + item._id}
                                role="button"
                              >
                                <i className="bi bi-pencil-fill"></i>
                              </button>
                              
                            </td>
                            <td><button
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={"#flush-" + item._id}
                                className="btn btn-transparent btn-sm"
                              >
                                
                                <i className="bi bi-caret-down-fill"></i>
                              </button>
                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item._id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div></td>
                          </tr>
                        ))}
                    </tbody>
                  </table></div></> }

                
                </div>
              </div>
            </div>
            
            <div
              className="tab-pane fade p-3 mb-5 card_design border border-danger"
              id="pending"
              role="tabpanel"
              aria-labelledby="pending-tab"
            >
              <br />
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-call-pending-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-call-pending"
                    type="button"
                    role="tab"
                    aria-controls="pills-call-pending"
                    aria-selected="true"
                  >
                    <i className="bi bi-phone-fill"></i>Call Pending
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-TaskPending-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-TaskPending"
                    type="button"
                    role="tab"
                    aria-controls="pills-TaskToday"
                    aria-selected="false"
                  >
                    <i className="bi bi-bicycle"></i>Task Pending
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-call-pending"
                  role="tabpanel"
                  aria-labelledby="pills-call-pending-tab"
                >
                  <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                    <caption>Pending Calls</caption>
                    <thead className="table-dark">
                      <tr>
                        <th scope="col">
                          
                          <i className="bi bi-tags-fill"></i>
                        </th>
                        <th scope="col">
                          <i className="bi bi-person"></i> Name
                        </th>
                        <th scope="col">
                          <i className="bi bi-phone-vibrate"></i> Call Brief
                        </th>
                        <th scope="col">
                          <i className="bi bi-compass"></i> Call Status
                        </th>
                        <th scope="col">
                          <i className="bi bi-telephone"></i> Last Call
                        </th>
                        <th scope="col">
                          <i className="bi bi-telephone-forward"></i> Next Call
                        </th>
                        <th scope="col">
                          <i className="bi bi-telephone-plus"></i> Call Back
                        </th>
                        <th scope="col">
                          <i className="bi bi-menu-button-fill"></i> 
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendingcall &&
                        pendingcall?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              {item.call_brief == null ? (
                                <span className="badge bg-dark">To Call</span>
                              ) : (
                                <span className="badge bg-info text-dark">
                                  Call Back
                                </span>
                              )}
                            </td>
                            <td>
                              <b>{item.customer_name==""?<>{
                               details == true?item.contact_number:item.contact_number.slice(0, -3) + '*** '

                              }</>:<>{item.customer_name}</>
                        }
                        {returncontactmem(item)}</b>
                            </td>
                          
                            <td>{changeElement(item)}</td>
                            <td>{progressElement(item)}</td>
                            <td>{item.script_category}</td>
                            <td>{item.next_call_script_category}</td>
                            <td>{alterCallBack(item.call_again_date)}</td>
                            <td>
                            {
                            item.Contact_Source == "" || item.Contact_Source == null ? null : (
                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                                <i className="bi bi-bezier2"></i> 
                              </OverlayTrigger>
                              
                            )
                           }&nbsp;
                              <a
                                href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                                target="whatsapp"
                              >
                                <i className="bi bi-whatsapp"></i>
                              </a>&nbsp;
                              <Link
                                to={
                                  `/call?nextcall=` +
                                  item.next_call_script_category +
                                  "&contactno=" +
                                  item.contact_number +
                                  "&email=" +
                                  item.email_address +
                                  "&customer=" +
                                  item.customer_name +
                                  tocallvalue(item) +
                                  sourcevalue(item)
                                }
                              >
                                <i className="bi bi-telephone-fill"></i>
                              </Link>&nbsp;
                              <i
                                onClick={() =>
                                  openNav(item.contact_number, item.id)
                                }
                                className="bi bi-geo-fill"
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table></div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-TaskPending"
                  role="tabpanel"
                  aria-labelledby="pills-TaskPending-tab"
                >
                  <p className="text-secondary">Filter Calls:</p>
                  <div className="row">
                    <div className="col-3">
                      <select
                        name="task_type"
                        value={filterTask.task_type}
                        onChange={updateTaskType}
                        className="form-select border border-secondary inside"
                        aria-label="Default select example"
                      >
                        <option value="Assigned" selected>
                          Assigned
                        </option>
                        <option value="InProgress" selected>
                          In Progress
                        </option>
                        <option value="Canceled" selected>
                          Canceled
                        </option>
                      </select>
                    </div>

                    <div className="col-3">
                      <button
                        type="button"
                        onClick={taskpending}
                        className="btn btn-dark me-md-3 pop"
                      >
                        <i className="bi bi-arrow-right"></i> Submit
                      </button>
                      <button
                        type="button"
                        onClick={clearFilter}
                        className="btn btn-danger pop"
                      >
                        <i className="bi bi-x"></i>
                      </button>
                    </div>
                  </div>

                  <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                    <caption>Tasks</caption>
                    <thead className="table-dark">
                      <tr>
                        <th scope="col">
                          
                          <i className="bi bi-tags-fill"></i>
                        </th>
                        <th scope="col">
                          <i className="bi bi-bicycle"></i> Task
                        </th>
                        <th scope="col">
                          <i className="bi bi-person"></i> Name
                        </th>
                       
                        <th scope="col">
                          <i className="bi bi-person-circle"></i> Added
                        </th>
                        <th scope="col">
                          <i className="bi bi-calendar2-check"></i> Due Date
                        </th>
                        <th scope="col">
                          <i className="bi bi-calendar4-event"></i> Date
                        </th>
                        <th scope="col">
                          <i className="bi bi-menu-button-fill"></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {task &&
                        task?.map((item, index) => (
                          <tr key={index}>
                            <td>
                          {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                        </td>
                            <td>
                              <b>{item.taskHeading}</b>
                            </td>
                            <td>
                              <b>{item.customer_name==""?<>{
                               details == true?item.contact_number:item.contact_number.slice(0, -3) + '*** '

                              }</>:<>{item.customer_name}</>
                        }
                        {returncontactmem(item)}</b>
                            </td>
                            <td>{item.added_by_username}</td>
                            <td>
                              <b>{alterCallBack(item.lastDate)}</b>
                            </td>
                            <td>{alterElement(item)}</td>
                            <td>
                            {
                            item.Contact_Source == "" || item.Contact_Source == null ? null : (
                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                                <i className="bi bi-bezier2"></i> 
                              </OverlayTrigger>
                              
                            )
                           }&nbsp;
                              <a
                                href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                                target="whatsapp"
                              >
                                <i className="bi bi-whatsapp"></i>
                              </a>
                              <i
                                onClick={() =>
                                  openNav(item.contact_number, item._id)
                                }
                                className="bi bi-geo-fill"
                              />
                              <div
                                className="modal fade"
                                id={"modalToggle12" + item._id}
                                aria-hidden="true"
                                aria-labelledby="exampleModalToggleLabel"
                                tabIndex={-1}
                              >
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content">
                                    <div className="modal-header border-0">
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      />
                                    </div>
                                    <div className="modal-body">
                                      <div className="row bg-transparent">
                                        <div className="mb-1 col-12 bg-transparent">
                                          <label
                                            for="exampleFormControlInput1"
                                            className="form-label"
                                          >
                                            <i className="bi bi-file-earmark-text-fill" />
                                            Change Status:
                                          </label>

                                          <input
                                            name="taskID"
                                            type="hidden"
                                            value={editTask.taskID}
                                            onChange={editTaskData}
                                          ></input>

                                          <select
                                            name="taskStatus"
                                            value={editTask.taskStatus}
                                            onChange={editTaskData}
                                            className="form-select mb-3 col-6 border border-success"
                                            aria-label="Default select example"
                                            required
                                          >
                                            <option selected>
                                              Select Task Status
                                            </option>
                                            <option value="Assigned">
                                              Assigned
                                            </option>
                                            <option value="In Progress">
                                            In Progress 
                                            </option>
                                            <option value="Canceled">
                                            Canceled
                                            </option>
                                            <option
                                              className="bg-success text-white"
                                              value="Completed"
                                            >
                                              Completed
                                            </option>
                                          </select>
                                        </div>

                                        <div className="mb-1 col-12">
                                          <div className="mb-1">
                                            <label
                                              for="exampleFormControlTextarea1"
                                              className="form-label"
                                            >
                                              <i className="bi bi-file-text-fill" />
                                              Remarks
                                            </label>
                                            <textarea
                                              name="remark"
                                              value={editTask.remark}
                                              onChange={editTaskData}
                                              className="form-control border border-success"
                                              id="exampleFormControlTextarea1"
                                              rows="2"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="modal-footer border-0">
                                      <button
                                        onClick={updateTask}
                                        className="btn btn-dark"
                                        data-bs-toggle="modal"
                                        data-bs-dismiss="modal"
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button
                                className="btn btn-transparent btn-sm"
                                onClick={() => setDefaultTask(item)}
                                data-bs-toggle="modal"
                                href={"#modalToggle12" + item._id}
                                role="button"
                              >
                                <i className="bi bi-pencil-fill"></i>
                              </button>
                              <button
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={"#flush-" + item._id}
                                className="btn btn-transparent btn-sm"
                              >
                                Description
                                <i className="bi bi-caret-down-fill"></i>
                              </button>
                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item._id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table></div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade p-3 mb-5 card_design border border-secondary"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <p className="text-secondary">Filter Calls:</p>
              <form onSubmit={filter2}>
                <div className="row">
                  <div className="col-2">
                    <select
                      value={filterData.call_brief}
                      onChange={updataFilterData}
                      name="call_brief"
                      className="form-select border border-secondary inside"
                      aria-label="Default select example"
                    >
                      <option selected>Select Call Brief</option>
                      <option value="Cut Call">Cut Call</option>
                      <option value="Didn't Pick">Didn't Pick</option>
                      <option value="Call Later">Call Later</option>
                      <option value="Not Valid">Not Valid</option>
                      <option value="Interested">Interested</option>
                      <option value="Not Interested">Not Interested</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <select
                      value={filterData.call_status}
                      onChange={updataFilterData}
                      name="call_status"
                      className="form-select border border-secondary inside"
                      aria-label="Default select example"
                    >
                      <option selected>Select Call Status</option>
                      <option value="None">None</option>
                      <option value="Salute %26 Confirmation">
                        Salute & Confirmation
                      </option>
                      <option value="Pitch and Information">
                        Pitch and Information
                      </option>
                      <option value="Ask Question %26 Talk">
                        Ask Question & Talk
                      </option>
                      <option value="Objection Handling">
                        Objection Handling
                      </option>
                      <option value="Closing">Call Closing</option>
                    </select>
                  </div>
                  <div className="col-2">
                    <select
                      value={filterData.arrange}
                      onChange={updataFilterData}
                      name="arrange"
                      className="form-select border border-secondary inside"
                      aria-label="Default select example"
                    >
                      <option value="0">New to Old</option>
                      <option value="1">Old to New</option>
                    </select>
                  </div>
                  <div className="col-3">
                    <button type="submit" className="btn btn-dark me-md-3 pop">
                      <i className="bi bi-arrow-right"></i> Submit
                    </button>
                    <button
                      type="button"
                      onClick={clearFilter}
                      className="btn btn-danger pop"
                    >
                      <i className="bi bi-x"></i>
                    </button>
                  </div>
                </div>
              </form>
              <br />
              <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                <caption>Call History</caption>
                <thead className="table-dark">
                  <tr>
                    <th scope="col">   
                        <i className="bi bi-tags-fill"></i>
                      
                    </th>
                    <th scope="col">
                      <i className="bi bi-person"></i> Name
                    </th>

                    <th scope="col">
                      <i className="bi bi-phone-vibrate"></i> Brief
                    </th>
                    <th scope="col">
                      <i className="bi bi-compass"></i> Status
                    </th>
                    <th scope="col"> Type</th>
                  
                    <th scope="col">
                      <i className="bi bi-calendar4-event"></i> Date
                    </th>

                    
                    <th scope="col">
                      <i className="bi bi-menu-button-fill"></i> 
                    </th>
                    <th >
                     
                    </th>
                    <></>
                  </tr>
                </thead>
                <tbody>
                  {call1 &&
                    call1?.map((item, index) => (
                      <tr key={index}>
                     <td>
                          <b>
                            {item.call_type == "Incoming" ? (
                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Inbound')}>
                                 <i
                                className="bi bi-telephone-inbound-fill text-primary"
                                title="Inbound"
                              ></i>
                                </OverlayTrigger>
                               
                            ) : (
                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Outbound')}>
                              <i
                                className="bi bi-telephone-outbound-fill text-dark"
                                title="Outbound"
                              ></i>
                              </OverlayTrigger>
                            )}
                          &nbsp;
                           {
                            item.Contact_Source == "" || item.Contact_Source == null ? null : (
                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                                <i className="bi bi-bezier2"></i> 
                              </OverlayTrigger>
                              
                            )
                           }
                          &nbsp;
                           {
                            item.call_again_date == "" || item.call_again_date == null ? null : (

                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Call Category: '.concat(item.next_call_script_category, ', Time: ', item.call_again_date, ', Assigned: ', item.next_call_username ))}>
                                <i className="bi bi-telephone-plus-fill"></i> 
                              </OverlayTrigger>

                             
                            )
                           }
                           &nbsp;
                           {changeFace(item.clint_mood)}
                           &nbsp;
                           {
                            item.call_type != null && item.script_category == null? (
                              <i data-bs-toggle="modal" onClick={()=>setupeditdata(item)} data-bs-target="#editcalldata" className="bi bi-question-circle-fill text-danger"></i>
                            ):null
                           }

                          
                           

                            
                          </b>
                        </td>
                        <td>
                              <b>
                              {item.customer_name==""?<>{
                               details == true?item.contact_number:item.contact_number.slice(0, -3) + '*** '

                              }</>:<>{item.customer_name}</>
                        }  {returncontactmem(item)}
                              </b>
                            </td>
                        {/* <td>
                          <a
                            href={"callinfo?phoneno=" + item.contact_number}
                            target="_blank"
                          >
                            {item.contact_number}
                          </a>
                        </td> */}

                        <td>{changeElement(item)}</td>
                        <td>{progressElement(item)}</td>
                        <td>{item.script_category}</td>
                        <td>{alterElement(item)}</td>
                        <td>
                          
                          <a
                            href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                            target="whatsapp"
                          >
                            <i className="bi bi-whatsapp"></i>
                          </a>
                          <i
                            onClick={() =>
                              openNav(item.contact_number, item.id)
                            }
                            className="bi bi-geo-fill"
                          />
                          {changeRec(item)}

                          
                          <i
                            // onClick={() =>
                            //   handleDelete(
                            //     item.contact_number,
                            //     item.added_at,
                            //     org
                            //   )
                            // }
                            onClick={() => setdeleteItem(item)}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal10"
                            style={{ cursor: "pointer" }}
                            className="bi bi-trash-fill"
                          ></i>
                          <Alert
                            heading={"You are about to delete a record"}
                            description={
                              "This will delete call record from list. Are you sure?"
                            }
                            modelid={"exampleModal10"}
                            method={handleDelete}
                            item={deleteItem}
                          />
                          
                        </td>
                        <td><button
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#flush-" + item.id}
                            className="btn btn-transparent btn-sm"
                          >
                            
                            <i className="bi bi-caret-down-fill"></i>
                          </button>
                          <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            <div className="accordion-item bg-transparent">
                              <div
                                id={"flush-" + item.id}
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample"
                              >
                                <div className="accordion-body">
                                  {item.description}
                                  
                                </div>
                              </div>
                            </div>
                          </div></td>
                      </tr>
                    ))}
                </tbody>
              </table></div>
              <nav
                className="d-grid gap-2 d-md-flex justify-content-md-end"
                aria-label="..."
              >
                <ul className="pagination">
                  <li className="page-item">
                    <a className="btn btn-light">
                      
                      Total Pages: <b>{call1_tp}</b>
                    </a>
                  </li>
                </ul>
                <ul className="pagination">
                  <li className="page-item">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => prePage_call1(call1_pre)}
                    >
                      <i className="bi bi-arrow-left-circle-fill" /> Previous
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link text-dark border-0 bg-transparent">
                      <b>{call1_cp}</b>
                    </a>
                  </li>
                  <li className="page-item">
                    <a
                      className="page-link bg-dark text-white pop rounded"
                      style={{ cursor: "pointer" }}
                      onClick={() => nextPage_call1(call1_next)}
                    >
                      Next <i className="bi bi-arrow-right-circle-fill" />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div
              className="tab-pane fade p-3 mb-5 card_design border border-secondary"
              id="task"
              role="tabpanel"
              aria-labelledby="task-tab"
            >
              <div>
                <br />
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button className="btn btn-transparent me-md-2" type="button">
                    Add Task:
                  </button>
                  <a
                    className="btn btn-dark"
                    data-bs-toggle="modal"
                    href="#modalToggleTask"
                    role="button"
                  >
                    <i className="bi bi-plus-circle"></i> Single
                  </a>
                </div>

                <div
                  className="modal fade"
                  id="modalToggleTask"
                  aria-hidden="true"
                  aria-labelledby="exampleModalToggleLabel"
                  tabIndex={-1}
                >
                  <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                    <div className="modal-header border-0">
                    <h5 class="modal-title">Assign a task</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <div className="modal-body">
                      <div className="row bg-transparent">
                        <div className="row">
                          <div className="col-5">
                            <br />
                            <br />
                            <br />
                            <img
                              src="https://salessunday-image.s3.ap-south-1.amazonaws.com/Pages-image/3.+Add+task.svg"
                              height={250}
                            />
                          </div>
                          <div className="col-7">
                            <div className="row">
                            <div className="col-10">
                            <label for="exampleFormControlInput1" class="form-label">Existing contact?</label>
                            <div className="input-group mb-3 input-group-sm">      
        <input type="text" name='tocall'  value={searchelement.tocall}
                                    onChange={updataSearchElement} className="form-control border-dark" placeholder="Recipient's Name/Number/Email" aria-label="Recipient's username" aria-describedby="button-addon2" />
        <button className="btn btn-dark" onClick={SearchHome} type="button" id="button-addon2"><i className="bi bi-search"></i></button>
      </div>
</div>
                            </div>
                            <div
                className="overflow-auto bg-light"
                style={{ maxWidth: "500px", maxHeight: "100px" }}
              >
                            <ul className="list-group">
                
              {personsearchresult &&
                          personsearchresult?.map((item) => (
                            <li onClick={()=>setperson(item,'task')} className="list-group-item cursor listhover"><i className="bi bi-person-circle"></i> {item.customer_name}   {masknumber(item.contact_number)}</li>
                          ))}
  
</ul></div>
                          <p align='center'>-or-</p>
                            
                            <div className="row">

                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    <i className="bi bi-person-circle"></i> Customer Name
                                  </label>
                                  <input
                                    name="customer_name"
                                    value={taskData.customer_name}
                                    onChange={changeTaskData}
                                    type="text"
                                    className="form-control mb-3 col-6 border border-secondary form-control-sm"
                                    id="exampleFormControlInput1"
                                    placeholder="Raju Sharma"
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    <i className="bi bi-phone-fill"></i> Phone
                                    Number
                                  </label>
                                  <input
                                    name="contact_number"
                                    value={taskData.contact_number}
                                    onChange={changeTaskData}
                                    className="form-control form-control-sm mb-3 col-6 border border-secondary"
                                    id="exampleFormControlInput1"
                                    placeholder="8149910885"
                                  />
                                </div>
                              </div>

                              <div className="mb-1 col-6">
                                <div className="mb-3">
                                  <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    <i className="bi bi-phone-fill"></i> Email
                                  </label>
                                  <input
                                    name="email_address"
                                    value={taskData.email_address}
                                    onChange={changeTaskData}
                                    className="form-control form-control-sm mb-3 col-6 border border-secondary"
                                    id="exampleFormControlInput1"
                                    placeholder="user@gmail.com"
                                  />
                                </div>
                              </div>
                              <div className="mb-1 col-6 bg-transparent">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  <i className="bi bi-file-earmark-text-fill" /> Task Category:
                                </label>
                                <select
                                  name="taskHeading"
                                  value={taskData.taskHeading}
                                  onChange={changeTaskData}
                                  className="form-select form-select-sm mb-3 col-6 border border-secondary"
                                  aria-label="Default select example"
                                  required
                                >
                                  <option>Select Task</option>

                                  {feedOrgTask &&
                                    feedOrgTask?.map((item) => (
                                      <option value={item.heading}>
                                        {item.heading} ({item.description})
                                      </option>
                                    ))}
                                </select>
                              </div>

                              <div className="mb-1 col-6">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  <i className="bi bi-people-fill" /> Assign
                                  task to
                                </label>
                                <select
                                  name="assineTO_userID"
                                  value={taskData.assineTO_userID}
                                  onChange={changeTaskData}
                                  className="form-select form-select-sm mb-3 col-6 border border-secondary"
                                  aria-label="Default select example"
                                  required
                                >
                                  <option>Select Person</option>
                                  {feeduser &&
                                    feeduser?.map((item) => (
                                      <option
                                        value={item.id + "," + item.user_name}
                                        key={item.id}
                                      >
                                        {item.user_name}-{item.role}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="mb-1 col-6 bg-transparent">
                                <label
                                  for="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  <i className="bi bi-alarm-fill" /> Due Date
                                </label>
                                <input
                                  type="datetime-local"
                                  name="lastDate"
                                  value={taskData.lastDate}
                                  onChange={changeTaskData}
                                  className="form-control form-control-sm border border-secondary"
                                  id="exampleFormControlInput1"
                                  placeholder="Call Back"
                                />
                              </div>
                            </div>

                            <div className="mb-1 col-12">
                              <div className="mb-1">
                                <label
                                  for="exampleFormControlTextarea1"
                                  className="form-label"
                                >
                                  <i className="bi bi-file-text-fill" /> Task
                                  Description
                                </label>
                                <textarea
                                  name="description"
                                  value={taskData.description}
                                  onChange={changeTaskData}
                                  className="form-control form-control-sm border border-secondary"
                                  id="exampleFormControlTextarea1"
                                  rows="2"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer border-0">
                      <button
                        className="btn btn-dark"
                        onClick={sendTask}
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                </div>
                <br />
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      onClick={assignedTask}
                      className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-assigned"
                      type="button"
                      role="tab"
                      aria-controls="pills-assigned"
                      aria-selected="true"
                    >
                      Assigned
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      onClick={createdTask}
                      className="nav-link"
                      id="pills-created-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-created"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Created
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-assigned"
                    role="tabpanel"
                    aria-labelledby="pills-assigned-tab"
                  >
                    <p className="text-secondary">Filter Tasks:</p>

                    <div className="row">
                      <div className="col-2">
                        <select
                          value={filterTaskUser.taskHeading}
                          onChange={updateTaskFilter}
                          name="taskHeading"
                          className="form-select border border-secondary inside"
                          aria-label="Default select example"
                        >
                          <option selected>Select Task</option>
                          {feedOrgTask &&
                            feedOrgTask?.map((item, index) => (
                              <option value={item.heading} key={index}>
                                {item.heading}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-2">
                        <select
                          value={filterTaskUser.taskStatus}
                          onChange={updateTaskFilter}
                          name="taskStatus"
                          className="form-select border border-secondary inside"
                          aria-label="Default select example"
                        >
                          <option selected>Select Task Status</option>
                          <option value="Assigned">Assigned</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Canceled">Canceled</option>
                          <option
                            className="bg-success text-white"
                            value="Completed"
                          >
                            Completed
                          </option>
                        </select>
                      </div>
                      <div className="col-2">
                        <select
                          value={filterTaskUser.arrange}
                          onChange={updateTaskFilter}
                          name="arrange"
                          className="form-select border border-secondary inside"
                          aria-label="Default select example"
                        >
                          <option value="0">New to Old</option>
                          <option value="1">Old to New</option>
                        </select>
                      </div>
                      <div className="col-3">
                        <button
                          onClick={assignedtaskfilter}
                          className="btn btn-dark me-md-3 pop"
                        >
                          <i className="bi bi-arrow-right"></i> Submit
                        </button>
                        <button
                          type="button"
                          onClick={clearFilter}
                          className="btn btn-danger pop"
                        >
                          <i className="bi bi-x"></i>
                        </button>
                      </div>
                    </div>

                    <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                      <caption>Tasks</caption>
                      <thead className="table-dark">
                        <tr>
                        <th scope="col">
                          
                          <i className="bi bi-tags-fill"></i>
                        </th>
                          <th scope="col">
                            <i className="bi bi-bicycle"></i> Task Heading
                          </th>
                          <th scope="col">
                            <i className="bi bi-person"></i> Name
                          </th>
                        
                      
                          <th scope="col">
                            <i className="bi bi-person-circle"></i> Added
                          </th>
                          <th scope="col">
                            <i className="bi bi-calendar2-check"></i> Due Date
                          </th>
                          <th scope="col">
                            <i className="bi bi-calendar4-event"></i> Date
                          </th>
                          <th scope="col">
                            <i className="bi bi-menu-button-fill"></i> 
                          </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {task &&
                          task?.map((item, index) => (
                            <tr key={index}>
                               <td>
                          {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                        </td>
                              <td>
                                <b>{item.taskHeading}</b>
                              </td>
                              <td>
                              <b>{item.customer_name==""?<>{
                               details == true?item.contact_number:item.contact_number.slice(0, -3) + '*** '

                              }</>:<>{item.customer_name}</>
                        }
                        {returncontactmem(item)}</b>
                            </td>
                             
                              
                              <td>{item.added_by_username}</td>
                              <td>
                                <b>{alterCallBack(item.lastDate)}</b>
                              </td>
                              <td>{alterElement(item)}</td>
                              <td>
                                <div
                                  className="modal fade"
                                  id={"modalToggle13" + item._id}
                                  aria-hidden="true"
                                  aria-labelledby="exampleModalToggleLabel"
                                  tabIndex={-1}
                                >
                                  <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                      <div className="modal-header border-0">
                                        <button
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        />
                                      </div>
                                      <div className="modal-body">
                                        <div className="row bg-transparent">
                                          <div className="mb-1 col-12 bg-transparent">
                                            <label
                                              for="exampleFormControlInput1"
                                              className="form-label"
                                            >
                                              <i className="bi bi-file-earmark-text-fill" />
                                              Change Status:
                                            </label>

                                            <input
                                              name="taskID"
                                              type="hidden"
                                              value={editTask.taskID}
                                              onChange={editTaskData}
                                            ></input>

                                            <select
                                              name="taskStatus"
                                              value={editTask.taskStatus}
                                              onChange={editTaskData}
                                              className="form-select mb-3 col-6 border border-success"
                                              aria-label="Default select example"
                                              required
                                            >
                                              <option selected>
                                                Select Task Status
                                              </option>
                                              <option value="Assigned">
                                                Assigned
                                              </option>
                                              <option value="In Progress">
                                                In Progress
                                              </option>
                                              <option value="Canceled">
                                              Canceled 
                                              </option>
                                              <option
                                                className="bg-success text-white"
                                                value="Completed"
                                              >
                                                Completed
                                              </option>
                                            </select>
                                          </div>

                                          <div className="mb-1 col-12">
                                            <div className="mb-1">
                                              <label
                                                for="exampleFormControlTextarea1"
                                                className="form-label"
                                              >
                                                <i className="bi bi-file-text-fill" />
                                                Remarks
                                              </label>
                                              <textarea
                                                name="remark"
                                                value={editTask.remark}
                                                onChange={editTaskData}
                                                className="form-control border border-success"
                                                id="exampleFormControlTextarea1"
                                                rows="2"
                                              ></textarea>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="modal-footer border-0">
                                        <button
                                          onClick={updateTask}
                                          className="btn btn-dark"
                                          data-bs-toggle="modal"
                                          data-bs-dismiss="modal"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <a
                                  href={
                                    "https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number
                                  }
                                  target="whatsapp"
                                >
                                  <i className="bi bi-whatsapp"></i>
                                </a>
                                <button
                                  className="btn btn-transparent btn-sm"
                                  onClick={() => setDefaultTask(item)}
                                  data-bs-toggle="modal"
                                  href={"#modalToggle13" + item._id}
                                  role="button"
                                >
                                  <i className="bi bi-pencil-fill"></i>
                                </button>

                                
                              </td>
                              <td><button
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={"#flush-" + item._id}
                                  className="btn btn-transparent btn-sm"
                                >
                                  
                                  <i className="bi bi-caret-down-fill"></i>
                                </button>
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item bg-transparent">
                                    <div
                                      id={"flush-" + item._id}
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingOne"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        {item.description}
                                      </div>
                                    </div>
                                  </div>
                                </div></td>
                            </tr>
                          ))}
                      </tbody>
                    </table></div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-created"
                    role="tabpanel"
                    aria-labelledby="pills-created-tab"
                  >
                    <p className="text-secondary">Filter Tasks:</p>

                    <div className="row">
                      <div className="col-2">
                        <select
                          value={filterTaskUser.taskHeading}
                          onChange={updateTaskFilter}
                          name="taskHeading"
                          className="form-select border border-secondary inside"
                          aria-label="Default select example"
                        >
                          <option selected>Select Task</option>
                          {feedOrgTask &&
                            feedOrgTask?.map((item, index) => (
                              <option value={item.heading} key={index}>
                                {item.heading}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="col-2">
                        <select
                          value={filterTaskUser.taskStatus}
                          onChange={updateTaskFilter}
                          name="taskStatus"
                          className="form-select border border-secondary inside"
                          aria-label="Default select example"
                        >
                          <option selected>Select Task Status</option>
                          <option value="Assigned">Assigned</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Canceled">Canceled</option>
                          <option
                            className="bg-success text-white"
                            value="Completed"
                          >
                            Completed
                          </option>
                        </select>
                      </div>
                      <div className="col-2">
                        <select
                          value={filterTaskUser.arrange}
                          onChange={updateTaskFilter}
                          name="arrange"
                          className="form-select border border-secondary inside"
                          aria-label="Default select example"
                        >
                          <option value="0">New to Old</option>
                          <option value="1">Old to New</option>
                        </select>
                      </div>
                      <div className="col-3">
                        <button
                          onClick={Createdtaskfilter}
                          className="btn btn-dark me-md-3 pop"
                        >
                          <i className="bi bi-arrow-right"></i> Submit
                        </button>
                        <button
                          type="button"
                          onClick={clearFilter}
                          className="btn btn-danger pop"
                        >
                          <i className="bi bi-x"></i>
                        </button>
                      </div>
                    </div>

                    <div
                className="overflow-auto"
                style={{ maxWidth: "1500px", maxHeight: "800px" }}
              ><table className="table table-striped table-hover border-white caption-top">
                      <caption>Tasks</caption>
                      <thead className="table-dark">
                        <tr>
                        <th scope="col">
                          
                          <i className="bi bi-tags-fill"></i>
                        </th>
                          <th scope="col">
                            <i className="bi bi-bicycle"></i> Task Heading
                          </th>
                          <th scope="col">
                            <i className="bi bi-person"></i> Name
                          </th>
                          <th scope="col">
                            <i className="bi bi-person-circle"></i> Assigned to
                          </th>
                          <th scope="col">
                            <i className="bi bi-calendar2-check"></i> Due Date
                          </th>
                          <th scope="col">
                            <i className="bi bi-calendar4-event"></i> Date
                          </th>
                          <th scope="col">
                            <i className="bi bi-menu-button-fill"></i> 
                          </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {task &&
                          task?.map((item, index) => (
                            <tr key={index}>
                              <td>{item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                                  </td>
                              <td>
                                <b>{item.taskHeading}</b>
                              </td>
                              <td>
                              <b>
                              {item.customer_name==""?<>{
                               details == true?item.contact_number:item.contact_number.slice(0, -3) + '*** '

                              }</>:<>{item.customer_name}</>
                        }  {returncontactmem(item)}
                              </b>
                            </td>
                             
                            
                              <td>{item.assineTO_userName}</td>
                              <td>{alterCallBack(item.lastDate)}</td>
                              <td>{alterElement(item)}</td>
                              <td>
                                <div
                                  className="modal fade"
                                  id={"modalToggle14" + item._id}
                                  aria-hidden="true"
                                  aria-labelledby="exampleModalToggleLabel"
                                  tabIndex={-1}
                                >
                                  <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                      <div className="modal-header border-0">
                                        <button
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        />
                                      </div>
                                      <div className="modal-body">
                                        <div className="row bg-transparent">
                                          <div className="mb-1 col-12 bg-transparent">
                                            <label
                                              for="exampleFormControlInput1"
                                              className="form-label"
                                            >
                                              <i className="bi bi-file-earmark-text-fill" />
                                              Change Status:
                                            </label>

                                            <input
                                              name="taskID"
                                              type="hidden"
                                              value={editTask.taskID}
                                              onChange={editTaskData}
                                            ></input>

                                            <select
                                              name="taskStatus"
                                              value={editTask.taskStatus}
                                              onChange={editTaskData}
                                              className="form-select mb-3 col-6 border border-success"
                                              aria-label="Default select example"
                                              required
                                            >
                                              <option selected>
                                                Select Task Status
                                              </option>
                                              <option value="Assigned">
                                                Assigned
                                              </option>
                                              <option value="In Progress">
                                                In Progress
                                              </option>
                                              <option value="Canceled">
                                              Canceled 
                                              </option>
                                              <option
                                                className="bg-success text-white"
                                                value="Completed"
                                              >
                                                Completed
                                              </option>
                                            </select>
                                          </div>

                                          <div className="mb-1 col-12">
                                            <div className="mb-1">
                                              <label
                                                for="exampleFormControlTextarea1"
                                                className="form-label"
                                              >
                                                <i className="bi bi-file-text-fill" />
                                                Remarks
                                              </label>
                                              <textarea
                                                name="remark"
                                                value={editTask.remark}
                                                onChange={editTaskData}
                                                className="form-control border border-success"
                                                id="exampleFormControlTextarea1"
                                                rows="2"
                                              ></textarea>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="modal-footer border-0">
                                        <button
                                          onClick={updateTask}
                                          className="btn btn-dark"
                                          data-bs-toggle="modal"
                                          data-bs-dismiss="modal"
                                        >
                                          Submit
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <a
                                  href={
                                    "https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number
                                  }
                                  target="whatsapp"
                                >
                                  <i className="bi bi-whatsapp"></i>
                                </a>
                                <button
                                  className="btn btn-transparent btn-sm"
                                  onClick={() => setDefaultTask(item)}
                                  data-bs-toggle="modal"
                                  href={"#modalToggle" + item._id}
                                  role="button"
                                >
                                  <i className="bi bi-pencil-fill"></i>
                                </button>

                               
                              </td>
                              <td>
                              <button
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={"#flush-" + item._id}
                                  className="btn btn-transparent btn-sm"
                                >
                                  
                                  <i className="bi bi-caret-down-fill"></i>
                                </button>
                                <div
                                  className="accordion accordion-flush"
                                  id="accordionFlushExample"
                                >
                                  <div className="accordion-item bg-transparent">
                                    <div
                                      id={"flush-" + item._id}
                                      className="accordion-collapse collapse"
                                      aria-labelledby="flush-headingOne"
                                      data-bs-parent="#accordionFlushExample"
                                    >
                                      <div className="accordion-body">
                                        {item.description}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table></div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="datahub"
              role="tabpanel"
              aria-labelledby="data-tab"
            >
              <br />
              <br />
              <img id="speak" src={cs} alt="" width="500" height="500"></img>
            </div>
          </div>

          <div id="bottomAI">
            <button
              type="button"
              onClick={() => sunday1()}
              className="btn btn-info rounded-circle"
            >
              <h4>
                <i className="bi bi-robot" />
              </h4>
            </button>
          </div>

          <div
            className="card w-25 chat-popup p-3 mb-5 border-0 shadow"
            id="sunday1"
          >
            <div className="card-body">
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <h3>
                  <i
                    onClick={() => sunday1_back()}
                    className="bi bi-x-circle-fill"
                  />
                </h3>
              </div>
              <h5 className="card-title">
                Sunday Suggestion <i className="bi bi-emoji-sunglasses"></i>
              </h5>
              {MemberSundayTemp()}
            </div>
          </div>
          <div className="card rec-popup p-2  border-0" id="rec">
            <span onClick={rec_back} className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ cursor: "pointer" }}>
            X
        <span className="visually-hidden"></span>
      </span>
              <audio controls ref={audioRef}>
                <source src={RecLink} />
                Your browser does not support the audio element.
              </audio>
            </div>
          <div id="mySidenav" className="sidenav">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={closeNav}
              >
                ×
              </a>
              <div className="timeline">
                <div className="title">
                  <h2 className="text-white">Prospect Journey</h2>
                  <p className="text-white">(New to Old)</p>
                  <a href={"#" + trackId}>
                    <button
                      type="button"
                      className="btn btn-danger"
                      id="trackbtn"
                    >
                      <i className="bi bi-geo-fill" />
                    </button>
                  </a>
                </div>

                <div className="events">
                  {History &&
                    History?.map((item) =>
                      item.script_category != null ? (
                        <div className="event" id={item.id} key={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-light">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    className="circle-pop2"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.script_category} </b>
                                  </h5>
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                  
                                  <span className="badge bg-light text-dark border-dark">
                                    {changeFace(item.clint_mood)}
                                    {" "}{item.clint_mood}
                                  </span>
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> called this
                                    number the call brief was
                                    <b>{" "}{item.call_brief}</b>. It went till
                                    <b>{" "}{item.call_status}</b>.
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-muted">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body ">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {progressElement(item)}
                              
                            </div>
                          </div>
                        </div>
                       ) :item.call_type != null ?
                       <div className="event" id={item.id}><div
                       className={trackId != item.id ? "knob" : "knob1"}
                     /> <div className="card bg-danger">
                     <div className="card-body">
                     <div className="row">
                                <div className="col-6">
                                  <img
                                    className="rounded-circle"
                                    src={generateavatar(
                                      '?',
                                      "White",
                                      "#000"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {"Unkown Call"} </b>
                                  </h5>
                                </div>
                              </div>
                              <br/>
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary text-dark">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                     <p className="card-text">This call data is <b>Canceled.</b> Please complete the call details.</p>
                     <div className="row"><div className="col-12">
                                  <p align="right" className="card-text">
                                    <small className="text-dark">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div></div>
                      </div></div></div>
                       : (
                        <div className="event" id={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-info bg-gradient">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    className="rounded-circle"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.taskHeading} </b>
                                  </h5>
                                </div>
                              </div>

                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> assigned
                                    <b>{" "}{item.taskHeading}</b> task to
                                    <b>{" "}{item.assineTO_userName}</b>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-white">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse bg-transparent"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body  bg-transparent">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      )
                    )}

                  <div className="line" />
                </div>
              </div>
            </div>
        </div>
      </>
    );
  }
}

export default Data;
