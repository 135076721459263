import React, { Component ,PureComponent, useEffect,useState, useRef } from "react";
import axios from "axios";
import emptytarget from "../media/Team goals-pana.svg";
import { Link } from "react-router-dom";

function TargetStatus() {

  function addCommasToInteger(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    

  const Auth = localStorage.getItem("Auth");
  const admin = localStorage.getItem("admin");
  const [totalAssigned , setTotalAssigned ] = useState('0');
  const [totalCompleted , setTotalCompleted ] = useState('0');
  const [errorMessage, setErrorMessage] = useState("");
 
  function get_target(){

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];
    const monthName = monthNames[currentMonth];


    const currentYear = currentDate.getFullYear();

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        `/get_target?month=${monthName}&year=${currentYear}`,
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          

          let totalAssigned = 0;
          let totalCompleted = 0;
 

          let target = data.data[0]["target"] 

          console.log(target,'target')
           
          // Loop through each object in the array
        
        target.forEach(item => {
          if (item.assigned) {
              totalAssigned += parseInt(item.assigned);
          }
          if (item.completed) {
              totalCompleted += parseInt(item.completed);
          }
      });


setTotalAssigned(totalAssigned);
setTotalCompleted(totalCompleted);

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });

  }

  
  useEffect(() => {
    if(admin != true){
      get_target();
     
    }
 }, [1]);

  return (
    <> {admin =="true"?<>
        <div className="container" align='left'>
        <br/>

        

      
<div className="accordion" id="accordionExample">
<div className="accordion-item">
<h2 className="accordion-header" id="headingOne">
  <button
    className="accordion-button bg-dark text-secondary"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#collapse6"
    aria-expanded="true"
    aria-controls="collapse6"
  >
    <i className="bi bi-bullseye"></i> &nbsp;Target status:
  </button>
</h2>
<div
  id="collapse6"
  className="accordion-collapse collapse show"
  aria-labelledby="headingOne"
  data-bs-parent="#accordionExample"
>
  <div className="accordion-body bg-dark text-light" align='center'>
{totalAssigned != ""?<><div className="row">
<div className="col-12">

<h4 class="text-danger" >
  <b>
<i className="bi bi-award-fill"></i> {addCommasToInteger(totalCompleted)} 
<span class="text-secondary"> / {addCommasToInteger(totalAssigned)}</span>
</b>
</h4>
<p className="text-secondary"> Completed vs Assigned target</p>

</div>


</div>
<div className="col-10 ">
<div className="progress">
<div
className="progress-bar"
role="progressbar"
style={{ width: `${(totalCompleted / totalAssigned) * 100}%` }}
aria-valuenow={(totalCompleted / totalAssigned) * 100}
aria-valuemin={0}
aria-valuemax={100}
>
{Math.round((totalCompleted / totalAssigned) * 100)}%
</div>
</div>
<br/>


</div></>
        :<div align='center'>
        <img src={emptytarget} width="80%" /><p className="text-light">Please set this month's targets</p>
        <Link
               to="/report"
             ><button type="button" className="btn btn-sm btn-primary"> <i className="bi bi-bullseye"></i> Set targets</button></Link></div>}


</div>
</div>
</div>
</div>


</div>
        </>
        :null
        }
    </>
  )
}

export default TargetStatus