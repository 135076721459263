import React, { Component, useEffect, useState, useRef } from "react";
import axios from "axios";
import Tooltip1 from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LeadDetails from "../components/LeadDetails";
import Weekreport from "../components/weekreport";
import emptylead from "../media/undraw_add_files_re_v09g.svg";
import emptytask from "../media/Add tasks-pana.svg";
import {
  getContactNumber,
  todayCallAdmin,
  adminCallDues,
  pendingTodaysAdmintask,

} from "../redux/slices/dataSlice";
import Popover from 'react-bootstrap/Popover';
import { tocallvalue, progressElement, alterElement, alterCallBack, changeElement, changeFace, generateavatar} from '../helper';
import Offcanvas from 'react-bootstrap/Offcanvas';
import LeadPerormance from "../components/LeadPerormance";
import CardData from "../components/CardData";
import TargetStatus from "../components/TargetStatus";

const date = new Date().toDateString();
const token = localStorage.getItem("token");
const Auth = localStorage.getItem("Auth");
const access_token = localStorage.getItem("Auth");
const user_name = localStorage.getItem("user_name");
const org = localStorage.getItem("org");
const admin = localStorage.getItem("admin");
const user_id = localStorage.getItem("token");
const role = localStorage.getItem("role");

const renderPopover = (props) => (
  <Popover id="popover-basic">
  <Popover.Body>
  {props}
  </Popover.Body>
  </Popover>
);

ChartJS.register(ArcElement, Tooltip, Legend);


function fixstring(str, size){

  if (str.length > size) {
    return str.substring(0, size) + "...";
  } else {
    return str;
  }
}


function Home() {
  const dispatch = useDispatch();  
  const [allscore, setallscore] = useState([]);
  const [today, setToday] = useState([]);
  const [pendingcall, setPendingCall] = useState();
  const [task, setTask] = useState();
  const [taskpending, setTaskPending] = useState();
  const [details, setdetails] = useState(true);
  const [History, setHistory] = useState();
  const [trackId, setTrackId] = useState();
  const [lastdata, setLastdata] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [filterTask, setFilterTask] = useState({ task_type: "" });
  const [taskstatus, settaskstatus ] = useState({
    'Assigned': "", 'Incomplete': "", 'InProgress': "", 'Completed': "", 
  });
  const [activestatus, setactivestatus ] = useState({
    'Active': "", 'Not_Active': ""
  })

const [showLead, setShowLead] = useState(false);
const [leadId,setLeadId] = useState('');
  
  const audioRef = useRef();

  function rec() {
    document.getElementById("rec").style.display = "block";
  }
  function rec_back() {
    document.getElementById("rec").style.display = "none";
  }

  const [RecLink, setRecLink] = useState("");
   const piedata = {
  labels: ['In Complete','Assigned', 'In Progress',  'Completed'],
  datasets: [
    {
      label: '# of Votes',
      data: [taskstatus.Incomplete, taskstatus.Assigned, taskstatus.InProgress, taskstatus.Completed],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
       
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        
      ],
      borderWidth: 1,
    },
  ],
};

function handleShowLead (item) {
   
  setShowLead(true);
  setLeadId(item);
};

const handleCloseLead = () => {setShowLead(false)
};

const piedata2 = {
  labels: ['Active','Not Active'],
  datasets: [
    {
      label: '# of Votes',
      data: [activestatus.Active, activestatus.Not_Active],
      backgroundColor: [
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 99, 132, 0.2)'
      ],
      borderColor: [
        'rgba(54, 162, 235, 1)',
        'rgba(255, 99, 132, 1)'
      ],
      borderWidth: 1,
    },
  ],
};

  function returncontact(item){

    if(admin != 'true'){

      if( details == true){
        return <OverlayTrigger trigger="click" placement="bottom" overlay={renderPopover('Contact No: '.concat(item.contact_number, ', Email: ', item.email_address))}>
        <i className="bi bi-caret-down-fill"></i>
                </OverlayTrigger>}else{
        return <OverlayTrigger trigger="click" placement="bottom" overlay={renderPopover('Contact No: '.concat(item.contact_number.slice(0, -3) + '*** ', ', Email: ', item.email_address))}>
        <i className="bi bi-caret-down-fill"></i>
                </OverlayTrigger>}
    }else{
        return <OverlayTrigger trigger="click" placement="bottom" overlay={renderPopover('Contact No: '.concat(item.contact_number, ', Email: ', item.email_address))}>
        <i className="bi bi-caret-down-fill"></i>
                </OverlayTrigger>}

  } 


  const renderTooltip = (props) => (
    <Tooltip1 id="button-tooltip" {...props}>
      {props}
    </Tooltip1>
  );

  const sourcevalue = (item) => {
    if ( item.Contact_Source != "" && item.Contact_Source != null) {
      var sourcevar = "&callsource=".concat(item.Contact_Source);
      return sourcevar;
    }else{
      var sourcevar = "&callsource=".concat(" ");
      return sourcevar;
    }
  };


  function changeRec(item) {
    if (item.call_recoding == true) {
      return (
        <button
          onClick={() => getRec(item)}
          className="border-0 bg-transparent"
        >
          <i className="bi bi-play-circle-fill"></i>
        </button>
      );
    } else {
      return null;
    }
  }
  function alterElement(item) {
    var added_at = item.added_at;
    added_at = added_at.substring(0, 16);

    return added_at;
  }

  function getCall() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_user_call_count?userid=".concat(token), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          let user_call = Object.values(data.data);
          let user_date = Object.keys(data.data);

         
        }
        
      })
      .catch((err) => console.log(err));
  }
  function fetchOrgData() {
  
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_organisation?organisation_id=".concat(org), { headers })
      .then((data) => {
        if (data.status == "200") {

          
if(admin != "true"){
  setdetails(data.data[0].Numbermassing);
}else{
  setdetails(true);
  
}


        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }


  function getScore() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_scores_for_admin", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          
          rankcheck(data.data);
       
        }
        
      })
      .catch((err) => console.log(err));
  }


  function getRec(item) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get(
        "/CallRecoding?".concat(
           "docucmet_id=",
          item.id
        ),
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          updateSong(data.data);
          rec();
        }
        
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function rankcheck(item){

    item.sort(function(a, b){return b.Todays_Score - a.Todays_Score});

  setallscore(item);

  }
  


  function get_today_leads(){

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .post(
        "/get_last_leads", {},
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
       
          setLastdata(data.data);
        
        }
        
      })
      .catch((err) => {
        console.log(err);
      });


  }


  
  function fetchPendingCall() {

    if (admin == "true") {

      dispatch(
        adminCallDues({
          access_token,
          org,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          setPendingCall(res.payload.data);
        }
      });

    }else{

      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/usersCallDues?organisation_id=".concat(org, "&UserID=", user_id),
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            setPendingCall(data.data);
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage);
        });

    }


  }

  function alterCallBack(item) {
    var cb = item;
    
    if(item == '' || item == null){
  
      cb = '';
  
  
    }else{
  
      cb = cb.replace("T", " ");
    var month = cb.substring(5, 7);
    var day = cb.substring(8, 10);
    var time = cb.substring(11, 16);  
    cb = time.concat(' ', day,'/', month )
    
  }
  
    
    return cb;
  }


  const updateSong = (source) => {
    setRecLink(source);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
      audioRef.current.play();
    }
  };

  function fetchToday() {

    if (admin == "true") {

      dispatch(
        todayCallAdmin({
          access_token,
          org,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          
          setToday(res.payload.data);

          if(admin =="true"){
            setToday(res.payload.data.Call_records);
          }else{
            setToday(res.payload.data);
          }

        }
      });

    }else{

      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/user_todayCall?organisation_id=".concat(org, "&user_id=", user_id),
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            setToday(data.data);
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage);
        });


    }
     

 
  }

  function searchContact(item) {
    let phoneno = item;
    dispatch(
      getContactNumber({
        access_token,
        org,
        phoneNo: phoneno,
      })
    ).then((res) => {
      if (res.payload.status == 200) {
        setHistory(res.payload.data.call_history);
      }
    });
  }


  function tasktoday() {

    if (admin == "true") {

      dispatch(
        pendingTodaysAdmintask({
          access_token,
          org,
        })
      ).then((res) => {
        if (res.payload.status == 200) {
          if (filterTask.task_type == "Assigned") {
            setTask(res.payload.data.assigned);
          } else if (filterTask.task_type == "InProgress") {
            setTask(res.payload.data.InProgress);
          } else if (filterTask.task_type == "Incomplete") {
            setTask(res.payload.data.Incomplete);
          } else if (filterTask.task_type == "") {
            setTask(res.payload.data.assigned);
          }
        }
      });

    }else{

      const headers = {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${Auth}`,
      };
      axios
        .get(
          "/pending_todays_task?organizationID=".concat(
            org,
            "&userID=",
            user_id
          ),
          { headers }
        )
        .then((data) => {
          if (data.status == "200") {
            if (filterTask.task_type == "Assigned") {
              setTask(data.data.assigned);
            } else if (filterTask.task_type == "InProgress") {
              setTask(data.data.InProgress);
            } else if (filterTask.task_type == "Incomplete") {
              setTask(data.data.Incomplete);
            } else if (filterTask.task_type == "") {
              setTask(data.data.assigned);
            }
          }
          setErrorMessage(data.data.message);
        })
        .catch((err) => {
          console.log(err);
          console.log(errorMessage);
        });
    } 
  }


  
  function addCommasToInteger(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  
  function soppercentage(item){

    if(item.links == null || item.links ==''){
      return 0;
    }else{
      return ((item.links[item.links.length-1]["value"] / item.links[0]["value"]) * 100).toFixed(1);
    }

  }

  function convertDateFormat(dateString) {
    const dateObj = new Date(dateString);
    
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = String(dateObj.getHours()).padStart(2, '0');
    const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    
    return `${day}-${month} ${hours}:${minutes}`;
}


  function openNav(item, itemId) {
    setHistory();

    searchContact(item);
    setTrackId(itemId);

    document.getElementById("mySidenav").style.width = "500px";
    document.getElementById("main").style.marginLeft = "500px";
    document.getElementById("trackbtn").click();
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }


  function gettaskpending() {

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/get_pending_task",
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          setTaskPending(data.data.Pending_tasks);
       
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });

  }


  function gettaskstatus(){
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/get_task_status",
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          console.log(data.data.Assigned);
          settaskstatus({
            'Assigned': data.data.Assigned, 'Incomplete': data.data.Incomplete, 'InProgress': data.data.InProgress, 'Completed': data.data.Completed, 
          });
       
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });

  }

  function getactive(){
    
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(
        "/client_status",
        { headers }
      )
      .then((data) => {
        if (data.status == "200") {
          
          setactivestatus({
            'Active': data.data.active_count, 'Not_Active': data.data.not_active_count
          });

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => {
        console.log(err);
        console.log(errorMessage);
      });

  }


  // function NotificationTemp() {

  //   if (admin == "true") {

  //     if (todayCall != 0 && todayCall != 1) {
  //       return (
  //         <p className="card-text">
  //           Your team has to complete <b>{todayCall}</b> calls.
  //         </p>
  //       );
  //     } else if (todayCall == 1) {
  //       return (
  //         <p className="card-text">
  //           Your team has to complete <b>{todayCall}</b> call.
  //         </p>
  //       );
  //     } else {
  //       return (
  //         <p className="card-text">
  //         No calls are assigned to anyone today.
  //       </p>
  //       );
  //     }

  //   }else{


  //     if (todayCall != 0 && todayCall != 1) {
  //       return (
  //         <p className="card-text">
  //           You have to complete <b>{todayCall}</b> calls.
  //         </p>
  //       );
  //     } else if (todayCall == 1) {
  //       return (
  //         <p className="card-text">
  //           You have to complete <b>{todayCall}</b> call.
  //         </p>
  //       );
  //     } else {
  //       return (
  //         <p className="card-text">No calls are assigned.</p>
  //       );
  //     }

  //   }

    
  // }



 

  useEffect(() => {
    if(admin != true){
      fetchOrgData();
      get_today_leads();
     
    }
    getScore();
    fetchToday();
    fetchPendingCall();
    getCall(); 
    gettaskstatus();
    tasktoday();
    gettaskpending();
    getactive();

  
    
   
    
  }, [1]);



  return (
    <div className="container-fluid">
<div className="container">
<div className="row">
  <div className="col-12">
  {/* <div className="accordion" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button
        className="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        Accordion Item #1
      </button>
    </h2>
    <div
      id="collapseOne"
      className="accordion-collapse collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div className="accordion-body">
        <strong>This is the first item's accordion body.</strong> It is shown by
        default, until the collapse plugin adds the appropriate classes that we
        use to style each element. These classes control the overall appearance,
        as well as the showing and hiding via CSS transitions. You can modify
        any of this with custom CSS or overriding our default variables. It's
        also worth noting that just about any HTML can go within the{" "}
        <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    
    </div>
  </div>
</div> */}

  </div>
</div>
</div>

    <div className="row">
    <div className="col-12 col-sm-3">

      {admin != 'true'?
      <><div className="continer" align='center'>
      <div className="card1">
<header className="card1-header">
  <p align='left'>{date}</p>
  <div  align='left'><span className="title">Your Score is {allscore &&
                                    allscore?.map((item, index) => (

                                      item.added_by_user == token? <>{item.Todays_Score}</>
                                      :<></>
                                     
                                        
                                    ))}</span></div>
                                   
</header>
<div className="card1-author">
  <a className="author-avatar" href="#">
    <span>
    <img
                                    className="circle"
                                    src={generateavatar(
                                      user_name,
                                      "Black",
                                      "#FFF5C3"
                                    )}
                                    width="40"
                                    height="40"
                                  ></img>
  </span></a>
 
  <div className="author-name" align='left'>
    <div className="author-name-prefix">{fixstring(user_name, '16')}</div> {role}
    </div>
  </div>
  <div className="tags1" align='left'>
    <a href="#"><i className="bi bi-arrow-clockwise"></i></a>

    
  </div>
</div>
      </div>

          <div className="container">    
<br/>
<div className="col-sm-12">
  {allscore !=''?<><p className='text-secondary'>Top 5 today <i className="bi bi-award"></i></p></>:null}
   
   </div>
   {allscore !=''?<>
   <ul class="list-group">
   {allscore &&
                                    allscore?.map((item, index) => (

                                      index < 5? 
                                      <> 
                                      {item.added_by_user == token?
                                       <li className="list-group-item d-flex justify-content-between align-items-center text-white bg-dark border-secondary">
                                       <img
                                     className="circle"
                                     src={generateavatar(
                                       item.added_by_username,
                                       "White",
                                       "#004aad"
                                     )}
                                     width="30"
                                     height="30"
                                   ></img>{" "} {item.added_by_username} - {item.Todays_Score}
     <span className="badge bg-dark text-white rounded-pill">{index + 1}</span>
   </li>
                                      :<li className="list-group-item d-flex justify-content-between align-items-center text-dark bg-light border-lightborder-light">
                                      <img
                                    className="circle"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="30"
                                    height="30"
                                  ></img>{" "} {item.added_by_username} - {item.Todays_Score}
    <span className="badge bg-white text-dark rounded-pill">{index + 1}</span>
  </li>}
                                     
                                      </>
                                      :null
                                      
                                     
                                        
                                    ))}
</ul></>
   :<div align='center'>
    {/* <br/><img src={emptyscore} height={180} /> */}
    </div>}
   
     

          </div></>:<><LeadPerormance/></>}

     

          <div className="container ">
          <br/>
          <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button bg-dark text-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
      <i className="bi bi-bicycle"></i> &nbsp; Tasks distribution
      </button>
    </h2>
    <div id="collapse2" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
    
      <div className="accordion-body bg-dark">
              
              {Object.values(taskstatus).every(value => value === undefined)?<><div align='center'>
  <img src={emptytask} width="80%" /><p className="text-light">Please add tasks</p>
  <Link
         to="/callrecord"
       ><button type="button" className="btn btn-sm btn-primary"> <i className="bi bi-bicycle"></i> Add tasks</button></Link></div></>:<><Pie data={piedata} /></> }
              
              </div>
    </div>
  </div>
           
        
            </div>
         

        </div>
      
        <div className="col-12 col-sm-9">
          <div className="row">
          <div className="col-12 col-sm-8">  
            <CardData/>
            <Weekreport/>
              <p className="collapsed text-dark"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse5"
                    aria-expanded="false"
                    aria-controls="collapse5"
                  >
                     <i className="bi bi-check2-square"></i> Todo calls and tasks <i className="bi bi-caret-down-fill"></i>
                  </p>
          <div className="accordion" id="accordionExample">

  <div className="accordion-item border-0 bg-transparent">
 
    <div
      id="collapse5"
      className="accordion-collapse bg-transparent collapse show border-0"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div className="bg-transparent border-0">
      <div className="row">
          <div className="col-12 col-sm-6">

          <p><span className="badge bg-primary"><i className="bi bi-calendar-event"></i> Today</span> <i className="bi bi-arrow-down"></i></p>
            
           
 
        
            <div>
            {today ==null || today ==''?null :<><div className="card bg-dark shadow-lg">
              <div class="card-header text-white">
              <i className="bi bi-phone-fill"></i> Calls
  </div>
              <table className="table table-light table-striped">
        
        <tbody>

        {today &&
                        today?.map((item, index) => (
                          index < 5? 
                          <tr>
            <td> 
           
            <i className="bi bi-person-circle"></i>&nbsp;<b className="link-dark">
                              {item.customer_name==""?<>{
                               details == true?item.contact_number:item.contact_number.slice(0, -3) + '*** '

                              }</>:<>{fixstring(item.customer_name, '18')}</>
                        }  {returncontact(item)}
                              </b><br/>
                              {item.next_call_script_category}
                            </td>
                           
                            
       
           
            <td align='right'>
            {item.call_again_date!=""?alterCallBack(item.call_again_date):null}
                            <br/>{
                            item.Contact_Source == "" || item.Contact_Source == null ? null : (
                              <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                                <i className="bi bi-bezier2"></i> 
                              </OverlayTrigger>
                              
                            )
                           }&nbsp;
                              <a
                                href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                                target="whatsapp"
                              >
                                <i className="bi bi-whatsapp"></i>
                              </a>&nbsp;
                             
                              <Link
                                to={
                                  `/call?nextcall=` +
                                  item.next_call_script_category +
                                  "&contactno=" +
                                  item.contact_number +
                                  "&email=" +
                                  item.email_address +
                                  "&customer=" +
                                  item.customer_name +
                                  tocallvalue(item) +
                                  sourcevalue(item) +
                                  "&callId=" +
                                  item._id + (item.TicketId && item.TicketId !== "None" ? `&tid=${item.TicketId}` : `&tid=${'0'}`)
                                }
                              >
                                <i className="bi bi-telephone-fill"></i>
                              </Link>&nbsp;
                                <i
                              onClick={() =>
                                openNav(item.contact_number, item.id)
                              }
                              className="bi bi-geo-fill"
                            />
                            </td>
                            
          </tr>:null
                          ))}
          
        </tbody>
      </table>
              </div><br/></>}
            
              
              {task ==null || task =='' ?null:<><div className="card bg-dark shadow-lg">
              <div class="card-header text-white">
              <i className="bi bi-bicycle"></i> Tasks
  </div>
              <table className="table table-light table-striped">
        
        <tbody>

        {task &&
                        task?.map((item, index) => (
                          index < 5 && item.taskStatus != "Completed" && item.taskStatus != "Incomplete" ? 
                          <tr>
                           <td><i className="bi bi-person-circle"></i>&nbsp; <b className="link-dark">
                              {item.customer_name==""?<>{
                               details == true?item.contact_number:item.contact_number.slice(0, -3) + '*** '

                              }</>:<>{fixstring(item.customer_name, '18')}</>
                        }  {returncontact(item)}
                              </b>
                              <br/>{item.taskHeading}
                          </td>
                          <td align="right">
                          {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Incomplete" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Incomplete
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                          <br/>{alterCallBack(item.lastDate)}
                            </td>
                             </tr>  
                          :null                         
                        ))}
         
          
        </tbody>
      </table>
              </div></>}
      
      </div>
      

          </div>

         <div className="col-12 col-sm-6">
         <p><span className="badge bg-danger"><i className="bi bi-calendar-x"></i> Pending</span> <i className="bi bi-arrow-down"></i></p>
         {pendingcall ==null || pendingcall =='' ?null:<><div className="card bg-dark border-danger shadow-lg">
         
         <div class="card-header text-white">
         <i className="bi bi-phone-fill"></i> Calls
</div>
         <table className="table table-light table-striped">
   <tbody>
   {pendingcall &&
                   pendingcall?.map((item, index) => (
                     index < 5? 
                     <tr>
       <td> 
       <i className="bi bi-person-circle"></i>&nbsp;
                         <b className="link-dark">
                         {item.customer_name==""?<>{
                          details == true?item.contact_number:item.contact_number.slice(0, -3) + '*** '

                         }</>:<>{fixstring(item.customer_name, '18')}</>
                   }  {returncontact(item)}
                         </b>
                         <br/>{item.next_call_script_category}
                       </td>
                     
  
      
       <td align='right'>
       {item.call_again_date!=""?alterCallBack(item.call_again_date):null}
       <br/>{
                       item.Contact_Source == "" || item.Contact_Source == null ? null : (
                         <OverlayTrigger trigger="click" placement="right" overlay={renderPopover('Source: '.concat(item.Contact_Source))}>
                           <i className="bi bi-bezier2"></i> 
                         </OverlayTrigger>
                         
                       )
                      }&nbsp;
                         <a
                           href={"https://web.whatsapp.com/send/?text&type=phone_number&app_absent=0&phone=%2B91" + item.contact_number}
                           target="whatsapp"
                         >
                           <i className="bi bi-whatsapp"></i>
                         </a>&nbsp;
                        
                         <Link
                           to={
                             `/call?nextcall=` +
                             item.next_call_script_category +
                             "&contactno=" +
                             item.contact_number +
                             "&email=" +
                             item.email_address +
                             "&customer=" +
                             item.customer_name +
                             tocallvalue(item) +
                             sourcevalue(item) +
                             "&callId=" +
                             item._id + (item.TicketId && item.TicketId !== "None" ? `&tid=${item.TicketId}` : `&tid=${'0'}`)
                           }
                         >
                           <i className="bi bi-telephone-fill"></i>
                         </Link>&nbsp;
                           <i
                         onClick={() =>
                           openNav(item.contact_number, item._id)
                         }
                         className="bi bi-geo-fill"
                       />
                       </td>
     </tr>:null
                     ))}
     
    
   </tbody>
 </table>
         </div><br/></>}

         
         
         {taskpending ==null || taskpending ==''?null:<><div className="card bg-dark border-danger shadow-lg">
              <div class="card-header text-white">
              <i className="bi bi-bicycle"></i> Tasks
  </div>
              <table className="table table-light table-striped">
        <tbody>
        {taskpending &&
                        taskpending?.map((item, index) => (
                          index < 5 && item.taskStatus != "Completed" && item.taskStatus != "Incomplete"? 
                          <tr>
                          <td><i className="bi bi-person-circle"></i>&nbsp; <b className="link-dark">
                              {item.customer_name==""?<>{
                               details == true?item.contact_number:item.contact_number.slice(0, -3) + '*** '

                              }</>:<>{fixstring(item.customer_name, '18')}</>
                        }  {returncontact(item)}
                              </b>
                              <br/>{item.taskHeading}
                          </td>
                          <td align="right">
                          {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Incomplete" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Incomplete
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                          <br/>{alterCallBack(item.lastDate)}
                            </td>
                         
                        </tr>  
                          :null                         
                        ))}
        </tbody>
      </table>
              </div></>}
         
         </div>

          </div>
      </div>
    </div>
  </div>
</div>


        </div>
        
        <div className="col-12 col-sm-4 hometable" align='center'>

    
            {/* <div align='center'>
            <br/> 
          
            <div class="card-notification">
  <div class="img-notification"></div>
  <div class="textBox">
    <div class="textContent">
      <p class="h1" align='left'></p>
      <span class="span"></span>
    </div>
    <p class="p" align='left'>{NotificationTemp()}</p>
  <div>
</div></div></div>
            </div> */}

        
            <TargetStatus/>

            {
              admin == "true"?<>
               <div className="container">
            <br/>

            
            <div className="accordion-item">
  <h2 className="accordion-header" id="headingOne">
    <button
      className="accordion-button bg-dark text-secondary"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#collapse7"
      aria-expanded="true"
      aria-controls="collapse7"
    >
      <i className="bi bi-person-fill"></i> &nbsp; Last 5 active leads
    </button>
  </h2>
  <div
    id="collapse7"
    className="accordion-collapse collapse show"
    aria-labelledby="headingOne"
    data-bs-parent="#accordionExample"
  >
    <div className="accordion-body bg-dark">

            
                  <div align='left'>

{lastdata.leaddata && lastdata.leaddata.length > 0 ? (
    lastdata.leaddata.map((item, index) => (
        <><div className="row text-light  p-0 mb-1 rounded">
            <div className="col-2 ">
                <h3> <img
                    className="circle"
                    src={generateavatar(
                        item.title,
                        "White",
                        "#004aad"
                    )}
                    width="42"
                    height="42"
                ></img></h3>
            </div>
            <div className="col-8">
                <p><div className="p m-0 p-0"> {item.addedAt == null ? <>N/A</> : <> <span className="badge bg-light text-dark"><i className="bi bi-calendar-event-fill"></i> {convertDateFormat(item.addedAt)}</span></>} <span className="badge bg-warning text-dark"><i className="bi bi-bezier"></i> {fixstring(item.pipeline, '15')}</span> </div><div className="p-0 m-0">#{item.TicketId} - {fixstring(item.title, '18')} </div></p>
            </div>
            <div className="col-1 d-flex align-items-center">
                <h5><i className="bi bi-arrows-angle-expand arrowexpand" onClick={() => handleShowLead(item.TicketId)} ></i></h5>
            </div>
        </div></>
    ))
) : (
  <div align='center'>
  <img src={emptylead} width="80%" /><p className="text-light">Please add leads</p>
</div>
)}

   </div>
              </div>
  </div>
</div>


            </div>
              </>:null
            }

            
           
           
            {admin == "true"?<>  <div className="container">
              <br/>
              {activestatus.Active === "" && activestatus.Not_Active === ""?<></>:<div className="card border-dark shadow-lg">
              <div className="card-header bg-dark text-secondary" align='left'>
              <i className="bi bi-person-check-fill"></i> Active, Inactive prospects 
              </div>
              <div className="card-body bg-dark">
              <Pie data={piedata2} />
              <br/>
              </div>
            
            </div>}
            
            </div>
</>:null}
          


        </div>
          </div>

          
          <br/>
         
       
        </div>
      
        </div>
        <div className="card rec-popup p-2  border-0" id="rec">
            <span onClick={rec_back} className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ cursor: "pointer" }}>
            X
        <span className="visually-hidden"></span>
      </span>
              <audio controls ref={audioRef}>
                <source src={RecLink} />
                Your browser does not support the audio element.
              </audio>
            </div>

        <div id="mySidenav" className="sidenav">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={closeNav}
              >
                ×
              </a>
              <div className="timeline">
                <div className="title">
                  <h2 className="text-white">Prospect Journey</h2>
                  <p className="text-white">(New to Old)</p>
                  <a href={"#" + trackId}>
                    <button
                      type="button"
                      className="btn btn-danger"
                      id="trackbtn"
                    >
                      <i className="bi bi-geo-fill" />
                    </button>
                  </a>
                </div>

                <div className="events">
                  {History &&
                    History?.map((item) =>
                      item.script_category != null ? (
                        <div className="event" id={item.id} key={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-light">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    className="circle-pop2"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.script_category} </b>
                                  </h5>
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                  
                                  <span className="badge bg-light text-dark border-dark">
                                    {changeFace(item.clint_mood)}
                                    {" "}{item.clint_mood}
                                  </span>
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> called this
                                    number the call brief was
                                    <b>{" "}{item.call_brief}</b>. It went till
                                    <b>{" "}{item.call_status}</b>.
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-muted">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body ">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {progressElement(item)}
                              
                            </div>
                          </div>
                        </div>
                       ) :item.call_type != null ?
                       <div className="event" id={item.id}><div
                       className={trackId != item.id ? "knob" : "knob1"}
                     /> <div className="card bg-danger">
                     <div className="card-body">
                     <div className="row">
                                <div className="col-6">
                                  <img
                                    className="rounded-circle"
                                    src={generateavatar(
                                      '?',
                                      "White",
                                      "#000"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {"Unkown Call"} </b>
                                  </h5>
                                </div>
                              </div>
                              <br/>
                              <div className="row">
                                <div className="col-3">
                                  {item.call_type == "Incoming" ? (
                                    <span className="badge bg-primary text-dark">
                                      <i className="bi bi-telephone-inbound-fill"></i>
                                      {" "}Incoming
                                    </span>
                                  ) : (
                                    <span className="badge bg-success">
                                      <i className="bi bi-telephone-outbound-fill"></i>
                                      {" "}Outgoing
                                    </span>
                                  )}
                                </div>
                                <div className="col-3">
                                {changeRec(item)}
                                </div>
                              </div>
                     <p className="card-text">This call data is <b>Canceled.</b> Please complete the call details.</p>
                     <div className="row"><div className="col-12">
                                  <p align="right" className="card-text">
                                    <small className="text-dark">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div></div>
                      </div></div></div>
                       : (
                        <div className="event" id={item.id}>
                          <div
                            className={trackId != item.id ? "knob" : "knob1"}
                          />

                          <div className="card bg-info bg-gradient">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-6">
                                  <img
                                    className="rounded-circle"
                                    src={generateavatar(
                                      item.added_by_username,
                                      "White",
                                      "#004aad"
                                    )}
                                    width="35"
                                    height="35"
                                  ></img>
                                </div>
                                <div className="col-6">
                                  <h5 align="right" className="card-title">
                                    <b> {item.taskHeading} </b>
                                  </h5>
                                </div>
                              </div>

                              <br />
                              <div className="row">
                                <div className="col-3">
                                  {item.taskStatus == "Assigned" ? (
                                    <span className="badge bg-primary">
                                      <i className="bi bi-person-lines-fill"></i>
                                      Assigned
                                    </span>
                                  ) : item.taskStatus == "In Progress" ? (
                                    <span className="badge bg-warning text-dark">
                                      <i className="bi bi-fan"></i> In Progress
                                    </span>
                                  ) : item.taskStatus == "Canceled" ? (
                                    <span className="badge bg-danger">
                                      <i className="bi bi-x-lg"></i> Canceled
                                    </span>
                                  ) : item.taskStatus == "Completed" ? (
                                    <span className="badge bg-success">
                                      <i className="bi bi-check-lg"></i>
                                      Completed
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-12">
                                  <p className="card-text">
                                    <b>{item.added_by_username}</b> assigned
                                    <b>{" "}{item.taskHeading}</b> task to
                                    <b>{" "}{item.assineTO_userName}</b>
                                  </p>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-" + item.id}
                                    className="btn btn-transparent btn-sm"
                                  >
                                    Description
                                    <i className="bi bi-caret-down-fill"></i>
                                  </button>
                                </div>
                                <div className="col-6">
                                  <p align="right" className="card-text">
                                    <small className="text-white">
                                      {alterElement(item)}&nbsp;
                                    </small>
                                  </p>
                                </div>
                              </div>

                              <div
                                className="accordion accordion-flush"
                                id="accordionFlushExample"
                              >
                                <div className="accordion-item bg-transparent">
                                  <div
                                    id={"flush-" + item.id}
                                    className="accordion-collapse collapse bg-transparent"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample"
                                  >
                                    <div className="accordion-body  bg-transparent">
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                      )
                    )}

                  <div className="line" />
                </div>
              </div>
            </div>
            <Offcanvas show={showLead} placement={'start'}  onHide={handleCloseLead}>
            <Offcanvas.Header closeButton>
          <Offcanvas.Title><i className="bi bi-card-heading"></i> Lead details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <LeadDetails leadData={{TicketId :leadId}} leadType={'callview'}/>
        </Offcanvas.Body>
            </Offcanvas>

        </div>
  )
}

export default Home;