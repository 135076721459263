import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import initialState from "../initialstate";
import axios from "axios";
//1st api common
export const getTasks = createAsyncThunk(
  "getTasks",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(`/GetTaskHeadings?organisationID=${org}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//2nd api
export const getScriptAll = createAsyncThunk(
  "getScriptAll",
  async ({ access_token, org }, { rejectWithValue }) => {
    try {
      return await axios.get(`/get_all_script_types?orgID=${org}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//3rd api common
export const getUserData = createAsyncThunk(
  "getUserData",
  async ({ access_token, org }, { rejectWithValue }) => {
    try {
      return await axios.get(`/getusers?organisation_id=${org}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//4rd api
export const getAdminCard = createAsyncThunk(
  "getAdminCard",
  async ({ access_token, org, date_range }, { rejectWithValue }) => {
    console.log(date_range, 'date_range');
    try {
      return await axios.get(`/admin_call_card_data?organisation_id=${org}&date_range=${date_range}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//5th api
export const getUserCard = createAsyncThunk(
  "getUserCard",
  async ({ access_token, org, userId }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/call_card_data?organisation_id=${org}&user_id=${userId}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//6th api
export const getToCallAdmin = createAsyncThunk(
  "getToCallAdmin",
  async ({ access_token, org }, { rejectWithValue }) => {
    try {
      return await axios.get(`/admingetTocall?organisation_id=${org}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//7th api
export const getToCallUser = createAsyncThunk(
  "getToCallUser",
  async ({ access_token, org, userId }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/USergetTocall?organisation_id=${org}&UserID=${userId}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//8th api common
export const getContactNumber = createAsyncThunk(
  "getContactNumber",
  async ({ access_token, org, phoneNo }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/find_contact?contact_number=${phoneNo}&organisation_id=${org}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//9th api
export const updateTasks = createAsyncThunk(
  "updateTasks",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/UpdateTask/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//10th api
export const deleteContactToCall = createAsyncThunk(
  "deleteContactToCall",
  async ({ access_token, id }, { rejectWithValue }) => {
    try {
      return await axios.delete(`/deleteContactToCall?record_id=${id}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//11th api
export const addNewContact = createAsyncThunk(
  "addNewContact",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/AddNewContact`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//12th api
export const generateNewFile = createAsyncThunk(
  "generateNewFile",
  async ({ access_token, org }, { rejectWithValue }) => {
    try {
      return await axios.get(`/GenerateNewFile?organisation_id=${org}`, {
        headers: {
          "Content-Type": "application/json",
          responseType: "blob",
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//13th api
export const uploadConatctFile = createAsyncThunk(
  "uploadConatctFile",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/UploadConatctFileNew`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//for leaduplaod

export const UploadLeadFile = createAsyncThunk(
  "UploadLeadFile",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/UploadLeadFile`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          // Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//14th api common
export const addNewTask = createAsyncThunk(
  "addNewTask",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/addNewTask`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//15th api
export const todayCallAdmin = createAsyncThunk(
  "todayCallAdmin",
  async ({ access_token, org,page }, { rejectWithValue }) => {

    let endpoint = '';

    console.log(page, 'page');

    if(page == null ){

    endpoint = `/todayCall_admin?organisation_id=${org}`

    }else{

    endpoint = page

    }

    try {
      return await axios.get(endpoint, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//16th api
export const adminCallDues = createAsyncThunk(
  "adminCallDues",
  async ({ access_token, org }, { rejectWithValue }) => {
    try {
      return await axios.get(`/adminCallDues?organisation_id=${org}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//17th api
export const getAllTaskAdmin = createAsyncThunk(
  "getAllTaskAdmin",
  async ({ access_token, org }, { rejectWithValue }) => {
    try {
      return await axios.get(`/Get_all_taskAdmin?organisationID=${org}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//18th api
export const todayCallAdminsSriptCategory = createAsyncThunk(
  "todayCallAdminsSriptCategory",
  async ({ access_token, org, script }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/todayCall_admin?organisation_id=${org}&script_category=${script}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//19th api
export const getAllContact = createAsyncThunk(
  "getAllContact",
  async ({ access_token, org }, { rejectWithValue }) => {
    try {
      return await axios.get(`/get_all_contact?organisation_id=${org}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//20 api
export const getfollowContact = createAsyncThunk(
  "getfollowContact",
  async ({ access_token, org }, { rejectWithValue }) => {
    try {
      return await axios.get(`/get_follow_contact?organisation_id=${org}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//21 api
export const uploadCallRecoding = createAsyncThunk(
  "uploadCallRecoding",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/UploadCallRecoding`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// 22 api
export const getAllTaskAdminFilter = createAsyncThunk(
  "getAllTaskAdminFilter",
  async ({ access_token, org, data }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/Get_all_taskAdmin?organisationID=${org}&taskHeading=${data.heading}&taskStatus=${data.status}&arrange=${data.arrange}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//23 api
export const pendingTodaysAdmintask = createAsyncThunk(
  "pendingTodaysAdmintask",
  async ({ access_token, org }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/pending_todays_Admintask?organizationID=${org}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//24 api
export const adminTaskDues = createAsyncThunk(
  "adminTaskDues",
  async ({ access_token, org }, { rejectWithValue }) => {
    try {
      return await axios.get(`/admin_task_dues?organizationID=${org}`, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//25 api
export const deteleCallRecord = createAsyncThunk(
  "deteleCallRecord",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/Detele_CallRecord/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//26 api

export const Upload_Organization_Image = createAsyncThunk(
  "Upload_Organization_Image",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/Upload_Organization_Image`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//27th Api - Get assigned call for date
export const dateAssignedCall = createAsyncThunk(
  "dateAssignedCall",
  async ({ access_token,call_date,page_number,scriptCategory,paginationSize }, { rejectWithValue }) => {

    let endpoint = '';


    if(scriptCategory == ''){
      endpoint = `/Get_CallsbyDate?call_date=${call_date}&Pagenumber=${page_number}&script_category&page_size=${paginationSize}`


    }else{
      endpoint = `/Get_CallsbyDate?call_date=${call_date}&Pagenumber=${page_number}&script_category=${scriptCategory}&page_size=${paginationSize}`

      
    }


     
  
    try {
      return await axios.get(endpoint, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//-----------------------------------------------------------------------------------------------

export const getCallGoals = createAsyncThunk(
  "getCallGoals",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/Get_ScriptGoal?organisation_id=${org}&scripts_types=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPar1Latest = createAsyncThunk(
  "getParLatest",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/par1_latest?orgid=${org}&script_catagory=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPar2Latest = createAsyncThunk(
  "getPar2Latest",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/par2_latest?orgid=${org}&script_catagory=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPar3Latest = createAsyncThunk(
  "getPar3Latest",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/par3_latest?orgid=${org}&script_catagory=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPar4Latest = createAsyncThunk(
  "getPar4Latest",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/par4_latest?orgid=${org}&script_catagory=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPar5Latest = createAsyncThunk(
  "getPar5Latest",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/par5_latest?orgid=${org}&script_catagory=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getPar6Latest = createAsyncThunk(
  "getPar6Latest",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/par6_latest?orgid=${org}&script_catagory=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addsuggestions = createAsyncThunk(
  "addsuggestions",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/addsuggestions/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addquestions = createAsyncThunk(
  "addquestions",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/addquestions/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getseachquestions = createAsyncThunk(
  "getseachquestions",
  async ({ access_token, org, question }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/seach_questions?organisation_id=${org}&questionText=${question}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const upvote = createAsyncThunk(
  "upvote",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.put(`/upvote/`, data, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const downvote = createAsyncThunk(
  "downvote",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.put(`/downvote/`, data, {
        headers: {
          "Content-Type": "text/plain",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCallDetails = createAsyncThunk(
  "updateCallDetails",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/update_call_details/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addNewCall = createAsyncThunk(
  "addNewCall",
  async ({ access_token, data }, { rejectWithValue }) => {
    try {
      return await axios.post(`/add_new_call/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const findContactDetails = createAsyncThunk(
  "findContactDetails",
  async ({ access_token, org, contact_number }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/find_contact_details?organisation_id=${org}&contact_number=${contact_number}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getscriptCallEndingInstruction = createAsyncThunk(
  "getscriptCallEndingInstruction",
  async ({ access_token, org, type }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/Get_script_callEnding_instruction?organisation_id=${org}&scripts_types=${type}`,
        {
          headers: {
            "Content-Type": "text/plain",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getSourceListData = createAsyncThunk(
  "getSourceList",
  async ({ access_token }, { rejectWithValue }) => {
    try {
      return await axios.get(`/Get_contactsourcelist`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getSourceListAllData = createAsyncThunk(
  "getSourceListAllData",
  async ({ access_token }, { rejectWithValue }) => {
    try {
      return await axios.get(`/Get_Details_contactsourceList`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteSourceList = createAsyncThunk(
  "deleteSourceList",
  async ({ access_token, source, value }, { rejectWithValue }) => {
    try {
      return await axios.put(
        `/change_delete_status?Contact_Source=${source}&delete=${value}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const changeShareType = createAsyncThunk(
  "changeShareType",
  async ({ access_token, source, value }, { rejectWithValue }) => {
    try {
      return await axios.put(
        `/change_share_type?Contact_Source=${source}&share=${value}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getSharedData = createAsyncThunk(
  "getSharedData",
  async ({ source, org, SourceAuth }, { rejectWithValue }) => {
    try {
      return await axios.get(
        `/Get_call_and_Task_list?Contact_Source=${source}&organisation_id=${org}`, {
          headers: {
            Authorization: `Bearer ${SourceAuth}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const dataSlice = createSlice({
  name: "datapage",
  initialState: initialState.data,
  reducers: {},
  extraReducers: {
    [getUserData.fulfilled]: (state, action) => {
      state.data = action.payload.data.scripts_types;
    },
    [getContactNumber.fulfilled]: (state, action) => {},
  },
});

export const {} = dataSlice.actions;

export default dataSlice.reducer;
