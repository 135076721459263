import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import emptysop from "../media/Empty-rafiki.svg";

function LeadPerformance() {
  const Auth = localStorage.getItem("Auth");
  const admin = localStorage.getItem("admin");

  const [sopPerformance, setSopPerformance] = useState({
    Total: {},
    sop: [],
    sop_total: 0,
  });

  const [loading, setLoading] = useState(true); // State to manage loader visibility

  function get_sop_performance() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Auth}`,
    };

    axios
      .get("/Get_sop_performance", { headers })
      .then((data) => {
        if (data.status === 200) {
          setSopPerformance(data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false); // Hide loader once API call is complete
      });
  }

  useEffect(() => {
    if (admin !== true) {
      get_sop_performance();
    }
  }, []);

  return (
    <div className="container">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className="accordion-button bg-dark text-secondary"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse1"
            aria-expanded="true"
            aria-controls="collapse1"
          >
            <i className="bi bi-clipboard-data"></i> &nbsp; Process performance
          </button>
        </h2>
        <div
          id="collapse1"
          className="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body bg-dark">
            {loading ? (
              // Loader element
              <div align="center" className="text-light">
                <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <p>Loading performance data...</p>
              </div>
            ) : sopPerformance.sop.length > 0 ? (
              <>
                <h1 className="text-light" align="center">
                  {sopPerformance.sop_total}
                </h1>
                <p className="text-secondary" align="center">
                  Total leads
                </p>
                <hr className="text-secondary" />

                <div align="left">
                  <p className="text-secondary">
                    Process details <i className="bi bi-arrow-down"></i>
                  </p>

                  {sopPerformance.sop.map((item, index) => (
                    <div key={index}>
                      <p className="text-light">
                        <i className="bi bi-arrow-right"></i> {item.name} &nbsp;
                        <span className="badge rounded-pill bg-light text-dark">
                          {sopPerformance.Total[item.name]}
                        </span>
                      </p>
                      <p>
                        <span className="badge bg-primary">
                          Active: {item.status.active}
                        </span>
                        &nbsp;
                        <span className="badge bg-success">
                          Won: {item.status.won}
                        </span>
                        &nbsp;
                        <span className="badge bg-danger">
                          Lost: {item.status.lost}
                        </span>
                        &nbsp;
                        <span className="badge bg-dark border border-light">
                          Closed: {item.status.closed}
                        </span>
                      </p>
                      <hr />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div align="center">
                <img src={emptysop} width="80%" />
                <p className="text-light">Please add process categories</p>
                <Link to="/sop">
                  <button type="button" className="btn btn-sm btn-primary">
                    <i className="bi bi-bezier"></i> Setup process
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadPerformance;
