import React, { Component ,PureComponent, useEffect,useState, useRef } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from "axios";

function Weekreport() {
  
  const org = localStorage.getItem("org");
  const Auth = localStorage.getItem("Auth");
  const admin = localStorage.getItem("admin");

  const [errorMessage, setErrorMessage] = useState("");
  
  const [score, setScore] = useState([]);
  const [leads, setLeads] = useState([]);
  const [calls, setCalls] = useState([]);

  const [selectedScript, setSelectedScript] = useState("All");
  const [selectedLead, setSelectedLead] = useState("All");

  const [feedOrgCategory, setOrgCategory] = useState([]);
  const [feedSopCategory, setSopCategory] = useState();



  function handleChange(e){
    // setSelectedScript(e.target.value);
    weekcallsperf(e.target.value);
  }

  function handleSOPChange(e){
    // setSelectedScript(e.target.value);
    weekleads(e.target.value);
  }

  function getAllSop() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_sop_name", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setSopCategory(data.data);
        }
      })
      .catch((err) => console.log(err));
  }

  function getScript() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/get_all_script_types?orgID=".concat(org), {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setOrgCategory(data.data);
        }
        
      })
      .catch((err) => console.log(err));
  }

  function weekscore() {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get("/week_get_scores", {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setScore(data.data);

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }


  function weekleads(item) {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(`/week_get_leads?lead_type=${item}`, {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setLeads(data.data);
          setSelectedLead(item)

        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }

  function weekcallsperf(item) {
    const headers = {
      "Content-Type": "text/plain",
      Authorization: `Bearer ${Auth}`,
    };
    axios
      .get(`/week_get_calls?script_type=${item}`, {
        headers,
      })
      .then((data) => {
        if (data.status == "200") {
          setCalls(data.data);
          setSelectedScript(item)
         
        }
        setErrorMessage(data.data.message);
      })
      .catch((err) => console.log(err));
  }


  useEffect(() => {
   

    weekscore()
    getAllSop()
    getScript()
    
  }, [1]);

  return (
    <>
    <div className="row">{admin =="true"?<>
  <nav>
    <div className="nav nav-tabs border-0 mt-2" id="nav-tab" role="tablist">
      <button
        className="nav-link active"
        id="nav-score-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-score"
        type="button"
        role="tab"
        aria-controls="nav-score"
        aria-selected="true"
      >
        <i className="bi bi-controller"></i> Score status
      </button>
      <button
        className="nav-link"
        id="nav-leads-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-leads"
        type="button"
        role="tab"
        aria-controls="nav-leads"
        aria-selected="false"
        onClick={()=>weekleads('All')}
      >
        <i className="bi bi-person"></i> Leads status
      </button>
      <button
        className="nav-link"
        id="nav-calls-pre-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-calls-pre"
        type="button"
        role="tab"
        aria-controls="nav-calls-pre"
        aria-selected="false"
        onClick={()=>weekcallsperf('All')}
      >
       <i className="bi bi-phone"></i> Calls status
      </button>
    </div>
  </nav>
  <div className="tab-content" id="nav-tabContent">
    <div
      className="tab-pane fade show active"
      id="nav-score"
      role="tabpanel"
      aria-labelledby="nav-score-tab"
    >
          <div className="container mb-2">
              <br/>
              <p><i className="bi bi-building"></i> Team score performance :</p>
              <div align='center'>
              <ResponsiveContainer width="100%" height={250}> 
              <LineChart width={730} height={250} data={score}
            margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="score" stroke="#8884d8" strokeWidth={3} />
            
          </LineChart>
          </ResponsiveContainer>
          </div>

              </div>
    </div>
    <div
      className="tab-pane fade"
      id="nav-leads"
      role="tabpanel"
      aria-labelledby="nav-leads-tab"
    >
       <div className="container mb-2">
       <br/>
        <div className="row mb-1">
          <div className="col-8"><p><i className="bi bi-person-fill"></i> New leads added :</p></div>
          <div className="col-4">
          <select
  className="form-select form-select-sm border border-0 shadow-sm"
  aria-label=".form-select-sm example"
  onChange={handleSOPChange}
  // name='selectedScript'
  value={selectedLead}
>
  <option selected="All">Select process</option>
  {feedSopCategory &&
                        feedSopCategory?.map((item) => (
                          <option value={item}>{item}</option>
                          ))}
</select>

          </div>
        </div>
             
              
              <div align='center'>
              <ResponsiveContainer width="100%" height={250}> 
              <LineChart width={730} height={250} data={leads}
            margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="leads" stroke="#8884d8" strokeWidth={3} />
            
          </LineChart>
          </ResponsiveContainer>
          </div>

              </div>
    </div>
    <div
      className="tab-pane fade"
      id="nav-calls-pre"
      role="tabpanel"
      aria-labelledby="nav-calls-pre-tab"
    >
       <div className="container mb-2">
              <br/>
              <div className="row mb-1">
          <div className="col-8"><p><i className="bi bi-phone-fill"></i> Team call performance :</p></div>
          <div className="col-4">
          <select
  className="form-select form-select-sm border border-0 shadow-sm"
  aria-label=".form-select-sm example"
  onChange={handleChange}
  // name='selectedScript'
  value={selectedScript}
>
  <option selected="All">Select category</option>
  {feedOrgCategory?.map((item)=>(<option value={item.script_type_name}>{item.script_type_name}</option>))}
</select>

          </div>
        </div>
             
              <div align='center'>
              <ResponsiveContainer width="100%" height={250}> 
              <LineChart width={730} height={250} data={calls}
            margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="calls" stroke="#8884d8" strokeWidth={3} />
            
          </LineChart>
          </ResponsiveContainer>
          </div>

              </div>
    </div>
  </div>
</>:null}</div>

   
    </>
     );
}

export default Weekreport;
