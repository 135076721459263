import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getAdminCard, getUserCard } from "../redux/slices/dataSlice";

function CardData() {
  const dispatch = useDispatch();
  const org = localStorage.getItem("org");
  const admin = localStorage.getItem("admin");

  const [selected_date_range, setselected_date_range] = useState("today");
  console.log(selected_date_range,'selected_date_range');
  const [intreseted, setIntreseted] = useState(0);
  const [intreseted_pre, setIntreseted_pre] = useState(0);
  const [notIntreseted, setNotIntreseted] = useState(0);
  const [notIntreseted_pre, setNotIntreseted_pre] = useState(0);
  const [callBack, setCallBack] = useState(0);
  const [callBack_pre, setCallBack_pre] = useState(0);
  const [totalCall, setTotalCall] = useState(0);
  const [totalCall_pre, setTotalCall_pre] = useState(0);
  const [todayCall, setTodayCall] = useState(0);
  const [loading, setLoading] = useState(true); // State to manage loader visibility
  const user_id = localStorage.getItem("token");
  const access_token = localStorage.getItem("Auth");


  function calculateGrowth(b, a, type) {
    // Handle the case where the past value (b) is zero
    if (b === 0) {
        return {
            percentage: 0, // Neutral when b is 0
            change: "neutral",
            message: (
                <span className="small_font text-muted">
                    <i className="bi bi-dash-circle-fill"></i>
                    {" "} N/A
                </span>
            )
        };
    }

    // Calculate percentage change
    const percentage = ((a - b) / b) * 100;
    const change = percentage > 0 ? "positive" : percentage < 0 ? "negative" : "neutral";

    return {
        percentage: parseFloat(percentage.toFixed(2)), // Round to 2 decimal places
        change: change,
        message: (
            <span className={`small_font ${change === 'positive' ? 'text-success' : change === 'negative' ? 'text-danger' : 'text-muted'}`}>
                <i className={`bi ${change === 'positive' ? 'bi-arrow-up-circle-fill' : change === 'negative' ? 'bi-arrow-down-circle-fill' : 'bi-dash-circle-fill'}`}></i>
                {percentage > 0 ? ' +' : ''} {parseFloat(percentage.toFixed(2))}% 
            </span>
        )
    };
}



  function getCardData(date_range) {
    console.log(date_range,'check_item')
    setLoading(true); // Show loader before API call starts

    if (admin === "true") {
      dispatch(
        getAdminCard({
          access_token,
          org,
          date_range
        })
      )
        .then((res) => {
          if (res.payload.status === 200) {
            setIntreseted(res.payload.data.Interested);
            setNotIntreseted(res.payload.data.Not_Interested);
            setCallBack(res.payload.data.Follow_Up);
            setTotalCall(res.payload.data.Total_call);
            setTodayCall(res.payload.data.Todays_calls);
            setIntreseted_pre(res.payload.data.Previous.Interested);
            setNotIntreseted_pre(res.payload.data.Previous.Not_Interested);
            setCallBack_pre(res.payload.data.Previous.Follow_Up);
            setTotalCall_pre(res.payload.data.Previous.Total_call);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false)); // Hide loader after API call
    } else {
      dispatch(
        getUserCard({
          access_token,
          org,
          userId: user_id,
          date_range
        })
      )
        .then((res) => {
          if (res.payload.status === 200) {
            setIntreseted(res.payload.data.Interested);
            setNotIntreseted(res.payload.data.Not_Interested);
            setCallBack(res.payload.data.Follow_Up);
            setTotalCall(res.payload.data.Total_call);
            setTodayCall(res.payload.data.Todays_calls);
            setIntreseted_pre(res.payload.data.Previous.Interested);
            setNotIntreseted_pre(res.payload.data.Previous.Not_Interested);
            setCallBack_pre(res.payload.data.Previous.Follow_Up);
            setTotalCall_pre(res.payload.data.Previous.Total_call);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false)); // Hide loader after API call
    }
  }

  function handleChange(e){
    // setSelectedScript(e.target.value);
    getCardData(e.target.value);
    setselected_date_range(e.target.value);

  }

  useEffect(() => {
    getCardData(selected_date_range);
  }, []);

  return (
    <div>
      {loading ? (
        // Loader element
        <div align="center" className="text-dark">
          <div className="spinner-border text-dark" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p>Loading card data...</p>
        </div>
      ) : (
        // Main content
        <div className="accordion accordion-flush" id="accordionFlushExample" align='left'>
          <div className="accordion-item bg-transparent" id="callback">
            <p
              className="accordion-header"
              id="flush-headingOne"
              style={{ cursor: "pointer" }}
            >
              <p
                className="collapsed text-dark"
                data-bs-toggle="collapse"
                data-bs-target="#collapse3"
                aria-expanded="false"
                aria-controls="collapse3"
              >
                Last call interactions <i className="bi bi-caret-down-fill"></i>
              </p>
            </p>
            <div
              id="collapse3"
              className="accordion-collapse collapse show"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            ><div align='right'>
              <div className="col-4 mb-2">
          <select
  className="form-select form-select-sm border border-light shadow-sm"
  aria-label=".form-select-sm example"
  onChange={handleChange}
  // name='selectedScript'
  value={selected_date_range}
>
<option value="today">Today</option>
<option value="past_7_days">Past 7 days</option>
<option value="past_month">Past 30 days</option>
<option value="past_6_months">Past 180 days</option>
<option value="lifetime">Lifetime</option>

</select>

          </div> </div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <p>
                    <i className="bi bi-people-fill"></i> Prospect & client
                    reaction :
                  </p>
                  <div className="row">
                    <div className="col-6 col-sm-6">
                    <div className="card p-2 ps-3 bg-dark mb-3 text-white shadow-lg rounded-3">
                  <p align="left" className="card-text text-light">
                  {calculateGrowth(intreseted_pre, intreseted).message} 
                      <h4 align="left" className="title text-white mt-1 m-0">
                      <i className="bi bi-emoji-sunglasses-fill"></i>
                      <b> {intreseted}</b>
                   </h4>Interested
                    </p> 
 
                </div>
                    </div>
                    <div className="col-6 col-sm-6">
                    <div className="card p-2 ps-3 bg-dark mb-3 text-white shadow-lg rounded-3">
                  <p align="left" className="card-text text-light">
                  {calculateGrowth(notIntreseted_pre, notIntreseted).message}
                      <h4 align="left" className="title text-white mt-1 m-0">
                      <i className="bi bi-emoji-neutral-fill"></i>
                      <b> {notIntreseted}</b>
                   </h4>Not Interested
                    </p> 
           
                </div>

                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <p>
                    <i className="bi bi-phone-vibrate-fill"></i> Call data :
                  </p>
                  <div className="row">
                    <div className="col-6 col-sm-6">
                    <div className="card p-2 ps-3 bg-dark mb-3 text-white shadow-lg rounded-3">
                  <p align="left" className="card-text text-light">
                  {calculateGrowth(callBack_pre, callBack).message}
                      <h4 align="left" className="title text-white mt-1 m-0">
                      <i className="bi bi-telephone-plus-fill"></i>
                      <b> {callBack}</b>
                   </h4>To Call
                    </p> 

                </div>                
                    </div>
                    <div className="col-6 col-sm-6">
                    <div className="card p-2 ps-3 bg-dark mb-3 text-white shadow-lg rounded-3">
                  <p align="left" className="card-text text-light">
                  {calculateGrowth(totalCall_pre, totalCall).message}
                      <h4 align="left" className="title text-white mt-1 m-0">
                      <i className="bi bi-phone-fill"></i>
                      <b> {totalCall}</b>
                   </h4>Total Calls
                    </p> 
                    
                </div>  


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CardData;
